import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import { FC, ReactNode } from "react";
import { Link } from "react-router-dom";

export type GdDataExtractor = (data: Record<string, unknown>) => ReactNode | string;

export interface GdColumn {
  key: string;
  label: string;
  dataExtractor?: GdDataExtractor;
  align?: "left" | "center" | "right" | "justify" | "inherit" | undefined;
  bold?: boolean;
}

interface GdDataGridProps {
  data: Record<string, unknown>[];
  idExtractor: (data: Record<string, unknown>) => string;
  columns: GdColumn[];
  link: string;
  hover?: boolean;
  nothingToShowLabel?: string;
}

const GdDataGrid: FC<GdDataGridProps> = ({ data, idExtractor, columns, link, hover, nothingToShowLabel }) => {
  return data.length === 0 && nothingToShowLabel ? (
    <div style={{ flex: 1, minHeight: "250px", display: "flex", alignItems: "center", justifyContent: "center" }}>
      <Typography>{nothingToShowLabel}</Typography>
    </div>
  ) : (
    <TableContainer component={Paper} variant="outlined">
      <Table>
        <TableHead>
          <TableRow>
            {columns.map((c) => (
              <TableCell align={c.align || "center"} key={`header_${c.key}`}>
                {c.label}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((d) => (
            <TableRow
              key={idExtractor(d)}
              hover={hover}
              component={Link}
              to={`${link}${d.id}`}
              className={`clickable${d.active === false ? " deactivated" : ""}`}>
              {columns.map((c) => (
                <TableCell
                  align={c.align || "center"}
                  key={`${idExtractor(d)}_${c.key}`}
                  style={c.bold ? { fontWeight: "bold" } : undefined}>
                  {c.dataExtractor ? c.dataExtractor(d) : (d[c.key] as string)}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

GdDataGrid.defaultProps = { hover: undefined, nothingToShowLabel: undefined };

export default GdDataGrid;
