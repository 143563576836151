import { FC } from "react";
import { useTranslation } from "react-i18next";
import { CartesianGrid, Legend, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";
import { EmmyData } from "../data/generated/graphql";
import Theme from "../Theme";

interface EmmyDataProps {
  emmy: EmmyData[];
}

const EmmyDataViz: FC<EmmyDataProps> = ({ emmy }) => {
  const { t } = useTranslation("settings");
  const rates = emmy.map((e) => Number.parseFloat(e.rate));
  const minRate = Math.min(...rates);
  const maxRate = Math.max(...rates);
  const volumes = emmy.map((e) => Number.parseFloat(e.volume));
  const maxVol = Math.max(...volumes);
  const volHigh = Math.ceil(maxVol / 10000) * 10000;
  return (
    <ResponsiveContainer width="100%" height={300}>
      <LineChart width={750} height={300} data={emmy}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="month" tickMargin={8} />
        <YAxis
          yAxisId="left"
          domain={[Math.floor(minRate), Math.ceil(maxRate)]}
          tickMargin={8}
          stroke={Theme.palette.primary.main}
        />
        <YAxis yAxisId="right" orientation="right" stroke="#5555cc" tickMargin={8} domain={[0, volHigh]} />
        <Tooltip />
        <Legend />
        <Line
          yAxisId="left"
          type="monotone"
          name={t("emmyRate")}
          dataKey="rate"
          stroke={Theme.palette.primary.main}
          activeDot={{ r: 8 }}
        />
        <Line yAxisId="right" type="monotone" name={t("emmyVolume")} dataKey="volume" stroke="#5555cc" />
      </LineChart>
    </ResponsiveContainer>
  );
};

export default EmmyDataViz;
