import { createContext, FC, PropsWithChildren, useState } from "react";
import {
  Claims,
  User,
  UserLightFragment,
  useUserChangeActivationMutation,
  useUserGetAllQuery,
  useUserGetDetailsQuery,
  useUserUpdateClaimMutation,
} from "../generated/graphql";

export interface UsersContextProps {
  users: UserLightFragment[];
  getUsers: () => Promise<boolean>;
  replaceUsers: (newUsers: UserLightFragment[]) => void;
  getUser: (userId: string) => Promise<User | null>;
  changeUserActivation: (userId: string, active: boolean) => Promise<boolean>;
  changeUserClaim: (userId: string, claim: Claims, granted: boolean) => Promise<boolean>;
}

interface UserCache {
  userId: string;
  lastFetch: number;
}

const initialContext: UsersContextProps = {
  users: [],
  getUsers: () => Promise.resolve(false),
  replaceUsers: () => {},
  getUser: () => Promise.resolve(null),
  changeUserActivation: () => Promise.resolve(false),
  changeUserClaim: () => Promise.resolve(false),
};

export const UsersContext = createContext<UsersContextProps>(initialContext);

export const UsersProvider: FC<PropsWithChildren> = ({ children }) => {
  const [users, setUsers] = useState<User[]>([]);
  const [userCache, setUserCache] = useState<UserCache[]>([]);
  const { refetch } = useUserGetAllQuery({ skip: true });
  const { refetch: getUserDetails } = useUserGetDetailsQuery({ skip: true });
  const [changeActivation] = useUserChangeActivationMutation();
  const [changeClaim] = useUserUpdateClaimMutation();

  const updateCache = (user: User): void => {
    let found = false;
    const newUsers = users.map((u) => {
      if (u.id === user.id) return user;
      return u;
    });
    setUsers(newUsers);
    const newUserCache = userCache.map((uc) => {
      if (uc.userId === user.id) {
        found = true;
        return { ...uc, lastFetch: new Date().getTime() };
      }
      return uc;
    });
    if (!found) newUserCache.push({ userId: user.id, lastFetch: new Date().getTime() });
    setUserCache(newUserCache);
  };

  const getUsers = async (): Promise<boolean> => {
    const result = await refetch();
    if (result && result.data) {
      setUsers(result.data.userGetAll || []);
    }
    return false;
  };

  const replaceUsers = (newUsers: UserLightFragment[]): void => {
    setUsers(newUsers);
  };

  const getUser = async (userId: string): Promise<User | null> => {
    const { lastFetch } = userCache.find((uc) => uc.userId === userId) || { lastFetch: 0 };
    if (lastFetch > new Date().getTime() - 5 * 60 * 1000) {
      return users.find((u) => u.id === userId) || null;
    }
    const result = await getUserDetails({ userId });
    if (result && result.data) {
      const userDetails = result.data.userGetById || null;
      if (userDetails) updateCache(userDetails);
      return userDetails;
    }
    return null;
  };

  const changeUserActivation = async (userId: string, active: boolean): Promise<boolean> => {
    const result = await changeActivation({ variables: { userId, active } });
    if (result && result.data) {
      const userDetails = result.data.userChangeActivation || null;
      if (userDetails) updateCache(userDetails);
      else return false;
      return true;
    }
    return false;
  };

  const changeUserClaim = async (userId: string, claim: Claims, granted: boolean): Promise<boolean> => {
    const result = await changeClaim({ variables: { userId, claim, granted } });
    if (result && result.data) {
      const userDetails = result.data.userUpdateClaim || null;
      if (userDetails) updateCache(userDetails);
      else return false;
      return true;
    }
    return false;
  };

  return (
    <UsersContext.Provider
      value={{
        users,
        getUsers,
        replaceUsers,
        getUser,
        changeUserActivation,
        changeUserClaim,
      }}>
      {children}
    </UsersContext.Provider>
  );
};
