import { FC, useState } from "react";
import { Switch, Route, useHistory, useLocation } from "react-router-dom";
import { styled } from "@mui/material";
import AppDrawer, { drawerWidth } from "./AppDrawer";
import AppBar from "./AppBar";
import UserMenu from "./UserMenu";
import Users from "../users/Users";
import Settings from "../settings/Settings";
import Profile from "../profile/Profile";
import Projects from "../projects/Projects";
import Forms from "../forms/Forms";
import Actions from "../projects/Actions";
import Project from "../projects/Project";
import Obliged from "../settings/Obliged";
import User from "../users/User";
import Dashboard from "../common/Dashboard";
import Articles from "../settings/Articles";
import { ArticleType } from "../data/generated/graphql";

import "./Navigation.css";
import { Views } from "../projects/ProjectsSummary";
import AiSettings from "../aiAssistant/AiSettings";

const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })(({ theme, about }) => ({
  flexGrow: 1,
  padding: theme.spacing(3),
  transition: theme.transitions.create("margin", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  marginLeft: `56px`,
  ...(about === "opened" && {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: `${drawerWidth}px`,
  }),
}));

interface NavigationProps {
  signOut: () => void;
}

const Navigation: FC<NavigationProps> = ({ signOut }) => {
  const [anchorEl, setAnchorEl] = useState<Element | null>(null);
  const [menuOpen, setMenuOpen] = useState(false);
  const { pathname } = useLocation();
  const history = useHistory();

  const closeMenu = (): void => {
    setAnchorEl(null);
  };
  const navIfRelevant = (path: string): void => {
    if (path !== pathname) {
      history.push(path);
    }
    setMenuOpen(false);
  };
  const closeDrawer = (): void => setMenuOpen(false);

  return (
    <div className="nav-root" style={{ background: 'url("/logo_bg.png") no-repeat 97% 97%' }}>
      <AppBar menuOpen={menuOpen} setMenuOpen={setMenuOpen} setAnchorEl={setAnchorEl} />
      <Main className="page-root" about={menuOpen ? "opened" : "closed"}>
        <Switch>
          <Route path="/obliged">
            <Obliged />
          </Route>
          <Route path="/actions">
            <Actions />
          </Route>
          <Route path="/users/:userId">
            <User />
          </Route>
          <Route path="/users">
            <Users />
          </Route>
          <Route path="/platformRate">
            <Settings />
          </Route>
          <Route path="/projects/revive">
            <Projects view={Views.REVIVE} />
          </Route>
          <Route path="/projects/gantt">
            <Projects view={Views.GANTT} />
          </Route>
          <Route path="/projects/:projectId">
            <Project />
          </Route>
          <Route path="/projects">
            <Projects view={Views.LIST} />
          </Route>
          <Route path="/profile">
            <Profile />
          </Route>
          <Route path="/forms">
            <Forms />
          </Route>
          <Route path="/faq">
            <Articles type={ArticleType.Faq} />
          </Route>
          <Route path="/tips">
            <Articles type={ArticleType.Tips} />
          </Route>
          <Route path="/aiSettings/:mode?">
            <AiSettings />
          </Route>
          <Route path="/">
            <Dashboard />
          </Route>
        </Switch>
      </Main>
      <UserMenu anchorEl={anchorEl} close={closeMenu} nav={navIfRelevant} signOut={signOut} />
      <AppDrawer open={menuOpen} closeDrawer={closeDrawer} nav={navIfRelevant} />
    </div>
  );
};

export default Navigation;
