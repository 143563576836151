import { ToggleButton, ToggleButtonGroup, Typography } from "@mui/material";
import { FC, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router-dom";
import { ProjectsContext } from "../data/contexts/ProjectsContext";
import BaseCard from "../utils/BaseCard";
import CenteredContent from "../utils/CenteredContent";
import ProjectsFilters from "./ProjectsFilters";
import ProjectsSummary, { Views } from "./ProjectsSummary";

const Projects: FC<{ view: Views }> = ({ view }) => {
  const { getProjects, projects } = useContext(ProjectsContext);
  const [filteredProjectIds, setFilteredProjectsIds] = useState(projects.map((p) => p.id));
  const [currentView, setCurrentView] = useState<Views>(view || Views.LIST);
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation("project");
  const history = useHistory();
  const { pathname } = useLocation();

  useEffect(() => {
    if (projects.length === 0) {
      setLoading(true);
      getProjects().then(() => setLoading(false));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (pathname.endsWith("revive")) setCurrentView(Views.REVIVE);
    else if (pathname.endsWith("gantt")) setCurrentView(Views.GANTT);
    else setCurrentView(Views.LIST);
  }, [pathname]);

  if (loading) return <CenteredContent loading />;

  const filteredProjects = projects.filter((p) => filteredProjectIds.includes(p.id));
  const changeView = (newView: Views): void => {
    setCurrentView(newView);
    const newSuffix = newView === Views.GANTT ? "gantt" : newView === Views.REVIVE ? "revive" : "";
    history.push(`/projects/${newSuffix}`);
  };

  return (
    <BaseCard>
      <div className="row space-between margin-bottom">
        <Typography variant="h6">{t("projects")}</Typography>
        <ToggleButtonGroup
          onChange={(_, newValue) => changeView(newValue)}
          value={currentView}
          exclusive
          color="primary">
          <ToggleButton value={Views.LIST}>{t("views.list")}</ToggleButton>
          <ToggleButton value={Views.GANTT}>{t("views.gantt")}</ToggleButton>
          <ToggleButton value={Views.REVIVE}>{t("views.revive")}</ToggleButton>
        </ToggleButtonGroup>
        <ProjectsFilters setFilteredProjectsIds={setFilteredProjectsIds} projects={projects} />
      </div>
      <ProjectsSummary projects={filteredProjects} view={currentView} />
    </BaseCard>
  );
};

export default Projects;
