import { InteractionType } from "@azure/msal-browser";
import { useMsalAuthentication } from "@azure/msal-react";
import { Button, CircularProgress } from "@mui/material";
import { useSnackbar } from "notistack";
import { FC, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { log, Lvl } from "../../utils/log";
import { MSAL_DEFAULT_SCOPES } from "../authConfig";

const Authenticate: FC = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation("global");
  const { login, error } = useMsalAuthentication(InteractionType.Redirect, MSAL_DEFAULT_SCOPES);

  useEffect(() => {
    if (error !== null) {
      log(error, Lvl.ERROR);
      enqueueSnackbar(JSON.stringify(error), { variant: "error" });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  return error ? (
    <Button variant="contained" onClick={() => login()}>
      {t("authenticateRetry")}
    </Button>
  ) : (
    <CircularProgress size={24} className="anonymous-spinner" />
  );
};

export default Authenticate;
