/* eslint-disable max-classes-per-file */

enum EventType {
  STARTED = "started",
  "SUCCESS" = "success",
  ERROR = "error",
  PING = "ping",
  DECLINED = "declined",
}

export interface YousignEventData {
  type: string;
  event: EventType;
  signer_id: string;
  signature_request_id: string;
  answers: { field_id: string; field_type: string }[];
  payload: { redirectUrl?: string };
}

class InvalidSignatureLink extends Error {
  constructor() {
    super();
    this.name = "InvalidSignatureLink";
    this.message = "The signature link is invalid.";
  }
}

class IframeContainerNotFound extends Error {
  constructor(iframeContainerId: string) {
    super();
    this.name = "IframeContainerNotFound";
    this.message = `The iFrame container with the id "${iframeContainerId}" is not found.`;
  }
}

class InvalidCallbackFunction extends Error {
  constructor(eventType: EventType) {
    super();
    this.name = "InvalidCallbackFunction";
    this.message = `Callback on ${eventType} event is not a function.`;
  }
}

class Yousign {
  childOrigin: RegExp;

  eventCallbacks: Record<string, (data: YousignEventData) => void>;

  messageHandler: (this: Window, ev: MessageEvent<YousignEventData>) => unknown;

  iframe: HTMLIFrameElement | null;

  urlParams: URLSearchParams;

  constructor({
    signatureLink,
    iframeContainerId,
    isSandbox,
  }: {
    signatureLink: string;
    iframeContainerId: string;
    isSandbox: boolean;
  }) {
    this.childOrigin = /^https:\/\/yousign.app$/;
    this.eventCallbacks = {};
    let sLink;
    try {
      sLink = new URL(signatureLink);
    } catch (_a) {
      throw new InvalidSignatureLink();
    }
    const iframeContainer = document.getElementById(iframeContainerId);
    if (!iframeContainer) {
      throw new IframeContainerNotFound(iframeContainerId);
    }
    if (isSandbox) {
      sLink.searchParams.append("disable_domain_validation", "true");
    }
    this.urlParams = new Proxy(sLink.searchParams, {
      get: (searchParams, property) => searchParams.get(property as string),
    });
    this.iframe = document.getElementById("yousign-iframe") as HTMLIFrameElement;
    if (!this.iframe) {
      this.iframe = document.createElement("iframe");
      this.iframe.id = "yousign-iframe";
      this.iframe.referrerPolicy = "strict-origin-when-cross-origin";
      this.iframe.width = "100%";
      this.iframe.height = "100%";
      iframeContainer.appendChild(this.iframe);
    }
    this.iframe.src = sLink.href;
    this.messageHandler = this.receiveMessage.bind(this);
    window.addEventListener("message", this.messageHandler, false);
  }

  receiveMessage(event: MessageEvent<YousignEventData>): void {
    const { origin, data } = event;
    if (origin.match(this.childOrigin)) {
      if (
        data.type === "yousign" &&
        this.eventCallbacks[data.event] &&
        typeof this.eventCallbacks[data.event] === "function"
      ) {
        this.eventCallbacks[data.event](data);
      }
    }
    if (data.type === "__ubble" && data.payload.redirectUrl && this.iframe) {
      this.iframe.src = `${data.payload.redirectUrl}&k=${this.urlParams.get("k")}`;
    }
  }

  onStarted(fn: (data: YousignEventData) => void): void {
    if (typeof fn !== "function") {
      throw new InvalidCallbackFunction(EventType.STARTED);
    }
    this.eventCallbacks.started = fn;
  }

  onSuccess(fn: (data: YousignEventData) => void): void {
    if (typeof fn !== "function") {
      throw new InvalidCallbackFunction(EventType.SUCCESS);
    }
    this.eventCallbacks.success = fn;
  }

  onError(fn: (data: YousignEventData) => void): void {
    if (typeof fn !== "function") {
      throw new InvalidCallbackFunction(EventType.ERROR);
    }
    this.eventCallbacks.error = fn;
  }

  onPing(fn: (data: YousignEventData) => void): void {
    if (typeof fn !== "function") {
      throw new InvalidCallbackFunction(EventType.PING);
    }
    this.eventCallbacks.ping = fn;
  }

  onDeclined(fn: (data: YousignEventData) => void): void {
    if (typeof fn !== "function") {
      throw new InvalidCallbackFunction(EventType.DECLINED);
    }
    this.eventCallbacks.declined = fn;
  }

  removeMessageListener(): void {
    window.removeEventListener("message", this.messageHandler);
  }
}

export default Yousign;
