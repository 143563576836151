import { Divider, FormControl, FormControlLabel, Radio, RadioGroup, Typography } from "@mui/material";
import { useSnackbar } from "notistack";
import { FC, useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { ProjectsContext } from "../data/contexts/ProjectsContext";
import { AttachmentType, Operation, ProcedureStatus, Project, ProjectStatus } from "../data/generated/graphql";
import CenteredContent from "../utils/CenteredContent";
import FinalOperations from "./components/FinalOperations";
import Attachments from "./documents/Attachments";
import BillingRequest from "./documents/BillingRequest";
import ConventionAsked from "./documents/ConventionAsked";
import ConventionDetails from "./documents/ConventionDetails";
import QuoteValidation from "./documents/QuoteValidation";
import SwornStatement from "./documents/SwornStatement";
import UploadFileButton from "./documents/UploadFileButton";
import { quoteIsAccepted } from "./ProjectHelper";

export interface DocumentsViewerProps {
  project: Project;
}

const DocumentsViewer: FC<DocumentsViewerProps> = ({ project }) => {
  const { t } = useTranslation("project");
  const { enqueueSnackbar } = useSnackbar();
  const [onlyOneSwornStatement, setOnlyOneSwornStatement] = useState(Boolean(project.onlyOneSwornStatement));
  const [updating, setUpdating] = useState(false);
  const { updateProject } = useContext(ProjectsContext);
  if (project.status === ProjectStatus.Simulation || !project.convention) {
    return (
      <div className="row space-between">
        <Typography variant="h6" className="margin-top margin-bottom">
          {t(project.status === ProjectStatus.Simulation ? "projectNotLaunched" : "conventionNotAsked")}
        </Typography>
        {project.obligedId ? (
          <UploadFileButton
            projectId={project.id}
            label={t("launchWithManualConvention")}
            type={AttachmentType.Convention}
            fullWidth
            preCheck={() => {
              if (new Date(project.details?.endDate || 0).getTime() < new Date().getTime()) {
                enqueueSnackbar(t("projectIsOutdated"), { variant: "error" });
                return false;
              }
              return true;
            }}
          />
        ) : (
          <Typography style={{ maxWidth: 400, textAlign: "center" }}>
            {t("launchWithManualConventionImpossible")}
          </Typography>
        )}
      </div>
    );
  }
  if (project.convention.status === ProcedureStatus.Asked) {
    return <ConventionAsked project={project} />;
  }
  const soloSs = project.swornStatements?.find((ss) => ss.id === "solo");
  const allSSValidated = project.onlyOneSwornStatement
    ? soloSs?.status === ProcedureStatus.Finished
    : Boolean(
        project.swornStatements &&
          project.swornStatements?.length === project.operations?.length &&
          project.swornStatements?.findIndex((ss) => ss.status !== ProcedureStatus.Finished) === -1,
      );

  const handleChangeOnlyOneStatement = async (event: { target: { value: string } }): Promise<void> => {
    setUpdating(true);
    const newValue = event.target.value === "true";
    setOnlyOneSwornStatement(newValue);
    await updateProject({ id: project.id, onlyOneSwornStatement: newValue });
    setUpdating(false);
  };

  return (
    <>
      <Divider variant="middle" className="big-margin-top" />
      <ConventionDetails project={project} />
      {project.quote ? (
        <>
          <Divider variant="middle" className="big-margin-top" />
          <QuoteValidation project={project} />
        </>
      ) : undefined}
      {quoteIsAccepted(project) ? (
        <>
          <Divider variant="middle" className="big-margin-top" />
          <Attachments project={project} />
          <Divider variant="middle" className="big-margin-top" />
          <FinalOperations project={project} />
          <Divider variant="middle" className="big-margin-top" />
          <div className="row">
            <Typography variant="h6" className="margin-top margin-bottom">
              {t("swornStatementsTwo")}
            </Typography>
            <FormControl className="margin-left">
              <RadioGroup
                aria-labelledby="radio-buttons-onlyOneSwornStatement"
                name="onlyOneSwornStatement-radio-group"
                value={onlyOneSwornStatement}
                onChange={handleChangeOnlyOneStatement}
                row>
                <FormControlLabel
                  value="true"
                  control={<Radio />}
                  label={t("ssOneForProject")}
                  disabled={allSSValidated}
                />
                <FormControlLabel
                  value="false"
                  control={<Radio />}
                  label={t("ssOnePerOperation")}
                  disabled={allSSValidated}
                />
              </RadioGroup>
            </FormControl>
          </div>
          {updating ? (
            <CenteredContent loading />
          ) : onlyOneSwornStatement ? (
            <SwornStatement
              project={project}
              swornStatement={
                project.swornStatements !== null && project.swornStatements !== undefined
                  ? project.swornStatements?.find((ss) => ss.id === "solo")
                  : undefined
              }
              operation={project?.operations as unknown as Operation}
              onlyOne
            />
          ) : (
            project.operations?.map((o) => (
              <SwornStatement
                key={o?.id}
                project={project}
                swornStatement={project.swornStatements?.find((ss) => ss.id === o?.id)}
                operation={o as Operation}
              />
            ))
          )}
        </>
      ) : undefined}
      {allSSValidated && project.flags?.isInvoiceSent ? (
        <>
          <Divider variant="middle" className="big-margin-top" />
          <BillingRequest project={project} />
          {project.billingRequest && (project.userRate?.length || 0) > 0 ? (
            <>
              <Divider variant="middle" className="big-margin-top" />
              <BillingRequest project={project} isUserBillingRequest />
            </>
          ) : undefined}
        </>
      ) : undefined}
    </>
  );
};

export default DocumentsViewer;
