import { FC, PropsWithChildren, createContext, useState } from "react";
import {
  AssistantFilters,
  Conversation,
  FeedbackConversationQa,
  useAssistantGetConversationsHistoryQuery,
  useAssistantQaFeedbackMutation,
} from "../generated/graphql";
import { Lvl, log } from "../../utils/log";

const defaultLimit = 10;
export interface AssistantContextProps {
  conversationPages: Array<Conversation[]>;
  getConversationHistory: (
    page: number,
    filters?: AssistantFilters,
    force?: boolean,
    limit?: number,
  ) => Promise<boolean>;
  addFeedback: (
    conversationId: string,
    qaId: string,
    feedback?: FeedbackConversationQa,
    humanAnswer?: string,
  ) => Promise<boolean>;
  pageCount: number;
  currentConversationHistoryPage: number;
  loading: boolean;
  latestReset: number;
}

const initialContext: AssistantContextProps = {
  conversationPages: [],
  getConversationHistory: () => Promise.resolve(false),
  addFeedback: () => Promise.resolve(false),
  pageCount: 0,
  currentConversationHistoryPage: 0,
  loading: false,
  latestReset: 0,
};

export const AssistantContext = createContext<AssistantContextProps>(initialContext);

export const AssistantProvider: FC<PropsWithChildren> = ({ children }) => {
  const [conversationPages, setConversationPages] = useState<Array<Conversation[]>>([]);
  const [pageCount, setPageCount] = useState<number>(0);
  const [currentConversationHistoryPage, setCurrentConversationHistoryPage] = useState<number>(0);
  const [searchedTerm, setSearchedTerm] = useState<string | undefined>();
  const [loading, setLoading] = useState(true);
  const { refetch: fetchHistory } = useAssistantGetConversationsHistoryQuery({ skip: true, errorPolicy: "all" });
  const [addFeedbackServer] = useAssistantQaFeedbackMutation();
  const [latestCheck, setLatestCheck] = useState(0);
  const [latestReset, setLatestReset] = useState(0);

  const getConversationHistory = async (
    page: number,
    filters?: AssistantFilters,
    force?: boolean,
    limit?: number,
  ): Promise<boolean> => {
    setCurrentConversationHistoryPage(page);
    const newConversationPages =
      latestCheck < new Date().getTime() - 20 * 60 * 1000 || force || filters?.searchTerm !== searchedTerm
        ? []
        : [...conversationPages];

    if (typeof newConversationPages[page] === "undefined") {
      setLoading(true);
      const result = await fetchHistory({
        limit: typeof limit === "undefined" ? defaultLimit : limit,
        currentPage: page,
        filters,
      });
      if (result && result.data) {
        const convPage = result.data.assistantGetConversationsHistory?.data
          ? ([...result.data.assistantGetConversationsHistory.data] as Conversation[])
          : [];

        newConversationPages[page] = convPage;
        setConversationPages(newConversationPages);
        setPageCount(result.data.assistantGetConversationsHistory?.pageCount || 0);
        setSearchedTerm(filters?.searchTerm || "");
        setLoading(false);
        if (page === 0) setLatestCheck(new Date().getTime());
        return true;
      }
      return false;
    }
    return false;
  };

  const resetHistory = async (): Promise<void> => {
    await getConversationHistory(0, undefined, true);
    setLatestReset(new Date().getTime());
  };

  const addFeedback = async (
    conversationId: string,
    qaId: string,
    feedback?: FeedbackConversationQa,
    humanAnswer?: string,
  ): Promise<boolean> => {
    try {
      const result = await addFeedbackServer({ variables: { conversationId, qaId, feedback, humanAnswer } });
      if (result && result.data) {
        resetHistory();
        return true;
      }
      return false;
    } catch (e) {
      log("couldn't add feedback", Lvl.ERROR, e);
      return false;
    }
  };

  return (
    <AssistantContext.Provider
      value={{
        conversationPages,
        getConversationHistory,
        addFeedback,
        pageCount,
        currentConversationHistoryPage,
        loading,
        latestReset,
      }}>
      {children}
    </AssistantContext.Provider>
  );
};
