import { Check, Close, CloudSync, Visibility } from "@mui/icons-material";
import { IconButton, List, ListItem, ListItemText, TextField, Tooltip, Typography } from "@mui/material";
import { useSnackbar } from "notistack";
import { FC, ReactNode, useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import Theme from "../../Theme";
import { ProjectsContext } from "../../data/contexts/ProjectsContext";
import { UserContext } from "../../data/contexts/UserContext";
import {
  Attachment,
  AttachmentType,
  DataFieldInput,
  Maybe,
  Operation,
  Project,
  ValidationStatus,
} from "../../data/generated/graphql";
import GdAlert from "../../utils/GdAlert";
import DocumentValidationModal from "../components/DocumentValidationModal";
import { getFormsForSector } from "../form/FormHelper";
import { Form, getAttachments } from "../form/formsTypes";
import UploadFileButton from "./UploadFileButton";

export interface AttachmentsProps {
  project: Project;
}

const Attachments: FC<AttachmentsProps> = ({ project }) => {
  const [accepting, setAccepting] = useState("");
  const [rejecting, setRejecting] = useState(false);
  const [rejectionAttId, setRejectionAttId] = useState("");
  const [rejectAttReason, setRejectAttReason] = useState("");
  const [openValidationModal, setOpenValidationModal] = useState("");
  const { t } = useTranslation(["project", "global"]);
  const { validateAttachment } = useContext(ProjectsContext);
  const { userInfo } = useContext(UserContext);
  const { enqueueSnackbar } = useSnackbar();

  const forms = getFormsForSector(project.details?.sector, project.client?.company?.zipCode || "");
  const attSent: string[] = [];
  project.attachments?.forEach((a) => {
    if (!a.validation || a.validation.status !== ValidationStatus.Rejected)
      a.concerns?.forEach((c) => (attSent.indexOf(c) === -1 ? attSent.push(c) : undefined));
  });
  const getOpName = (o?: Maybe<Operation>): string =>
    o ? `${t("operation")} ${o?.id} - ${o?.formId}${o?.machineName ? ` (${o.machineName})` : ""}` : "";
  const attNeeded = attSent.indexOf("invoice") === -1 ? [{ primary: t("invoice"), secondary: "" }] : [];
  project.operations?.forEach((o) => {
    const secondaries: string[] = [];
    if (attSent.indexOf(`${o?.id}_p`) === -1) secondaries.push(t("doneProof"));
    const form = forms.find((f) => f.id === o?.formId);
    const attachments = form ? getAttachments(form as Form, o?.data as DataFieldInput[]) : [];
    attachments.forEach((a, i) => {
      if (attSent.indexOf(`${o?.id}_${i}`) === -1) secondaries.push(a);
    });
    if (secondaries.length > 0)
      attNeeded.push({
        primary: getOpName(o),
        secondary: secondaries.join(" + "),
      });
  });
  const getConcerns = (a: Attachment): ReactNode => {
    const result: {
      primary: string;
      secondary: string;
    }[] = [];
    a.concerns?.forEach((c) => {
      if (["invoice", "other"].includes(c)) result.push({ primary: t(c), secondary: "" });
      else {
        const parts = c.split("_");
        const op = project.operations?.find((o) => o?.id === parts[0]);
        const form = forms.find((f) => f.id === op?.formId);
        const attachments = form ? getAttachments(form as Form, op?.data as DataFieldInput[]) : [];
        const attIndex = parseInt(parts[1], 10);
        let attName = "";
        if (!Number.isNaN(attIndex)) attName = attachments[attIndex];
        else if (parts[1] === "p") attName = t("doneProof");
        else if (parts[1] === "cpeProof") attName = t("cpeProof");
        else return;
        const opName = getOpName(op);
        const line = result.find((r) => r.primary === opName);
        if (line) line.secondary += ` + ${attName}`;
        else result.push({ primary: opName, secondary: attName });
      }
    });
    return result.length > 0 ? (
      <>
        {result.map((r) => (
          <div key={r.primary}>
            {r.primary}
            {r.secondary ? ` - ${r.secondary}` : ""}
          </div>
        ))}
      </>
    ) : (
      ""
    );
  };
  const rejectAttachment = async (): Promise<void> => {
    setRejecting(true);
    const result = await validateAttachment(project.id, rejectionAttId, false, userInfo?.id || "", rejectAttReason);
    if (!result) {
      enqueueSnackbar(t("global:errorWhileUpdating"), { variant: "error" });
    }
    setRejecting(false);
    setRejectionAttId("");
    setRejectAttReason("");
  };

  const attachmentValidation = async (aId: string, accept: boolean, date?: string, amount?: string): Promise<void> => {
    if (accept) {
      const isInvoice = project.attachments?.find((a) => a.id === aId)?.concerns?.includes("invoice");
      setAccepting(aId);
      const result = await validateAttachment(
        project.id,
        aId,
        true,
        userInfo?.id || "",
        undefined,
        isInvoice
          ? {
              date,
              amount,
            }
          : undefined,
      );
      if (!result) {
        enqueueSnackbar(t("global:errorWhileUpdating"), { variant: "error" });
      }
      setAccepting("");
      setOpenValidationModal("");
    } else {
      // Ask for comment about rejection
      setRejectionAttId(aId);
    }
  };

  const tryValidateAttachment = async (aId: string): Promise<void> => {
    if (project.attachments?.find((a) => a.id === aId)?.concerns?.includes("invoice")) {
      setOpenValidationModal(aId);
      return Promise.resolve();
    }
    return attachmentValidation(aId, true);
  };

  return (
    <div className="margin-top">
      {attNeeded.length > 0 ? (
        <div className="row space-between align-start">
          <div>
            <Typography variant="h6">{t("attachmentsTitle")}</Typography>
            <List>
              {attNeeded.map((an) => (
                <ListItem key={an?.primary}>
                  <ListItemText primary={an?.primary} secondary={an?.secondary} />
                </ListItem>
              ))}
            </List>
          </div>
        </div>
      ) : undefined}
      {project.attachments && project.attachments.length > 0 ? (
        <>
          <Typography variant="h6">{t("sentAttachmentsTitle")}</Typography>
          {project.attachments?.map((a) => (
            <ListItem
              key={a.id}
              secondaryAction={
                <>
                  <Tooltip title={t("seeAttachment") as string}>
                    <IconButton onClick={() => window.open(`${a.url}?t=${new Date().getTime()}`)} color="secondary">
                      <Visibility />
                    </IconButton>
                  </Tooltip>
                  {a.validation ? (
                    <Tooltip title={t("overrideAttachment") as string}>
                      <UploadFileButton
                        projectId={project.id}
                        type={AttachmentType.Other}
                        label={t("overrideDocument")}
                        overrideMode
                        color="warning"
                        operationId={a.id || undefined}
                        icon={<CloudSync />}
                      />
                    </Tooltip>
                  ) : (
                    <>
                      <Tooltip title={t("acceptAttachment") as string}>
                        <IconButton
                          onClick={() => tryValidateAttachment(a?.id || "")}
                          color="primary"
                          disabled={accepting === a.id}>
                          <Check />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title={t("rejectAttachment") as string}>
                        <IconButton onClick={() => attachmentValidation(a?.id || "", false)} color="error">
                          <Close />
                        </IconButton>
                      </Tooltip>
                    </>
                  )}
                </>
              }>
              <ListItemText
                primary={
                  <Typography>
                    {a.name} -{" "}
                    <span
                      style={{
                        color: a.validation
                          ? a.validation.status === ValidationStatus.Accepted
                            ? Theme.palette.success.main
                            : Theme.palette.error.main
                          : undefined,
                      }}>
                      {a.validation
                        ? t(
                            `sentAttachmentsStatus.${
                              a.validation.status === ValidationStatus.Accepted ? "accepted" : "refused"
                            }`,
                            { replace: { reason: a.validation.comment } },
                          )
                        : t("sentAttachmentsStatus.ongoing")}
                    </span>
                  </Typography>
                }
                secondary={getConcerns(a)}
                style={{ marginRight: 90 }}
              />
            </ListItem>
          ))}
          {attNeeded.length > 0 ? undefined : <Typography variant="h6">{t("allAttachmentsUploaded")}</Typography>}
        </>
      ) : undefined}
      <GdAlert
        open={rejectionAttId.length > 0}
        onClose={() => setRejectionAttId("")}
        title={t("refuseAttachmentReasonTitle")}
        body={
          <TextField
            label={t("reason")}
            value={rejectAttReason}
            className="margin-top"
            onChange={(e) => setRejectAttReason(e.target.value)}
          />
        }
        okButtonClick={() => rejectAttachment()}
        okButtonLoading={rejecting}
        cancelButton
      />
      <DocumentValidationModal
        open={openValidationModal.length > 0}
        onClose={() => setOpenValidationModal("")}
        documentValidation={(date, amount) => {
          setOpenValidationModal("");
          attachmentValidation(openValidationModal, true, date, amount);
        }}
        url={project.attachments?.find((a) => a.id === openValidationModal)?.url}
        type="invoice"
      />
    </div>
  );
};

export default Attachments;
