import { Form } from "../formsTypes";

const barForms: Form[] = [
  {
    name: "Chaudière collective haute performance énergétique",
    id: "BAR-TH-107",
    formUrl: "https://gdceeforms.blob.core.windows.net/v61/BAR-TH-107.pdf",
    type: "Bâtiment",
    fields: [
      {
        id: "case",
        title: "Montant unitaire en kWh cumac par appartement",
        type: "choice",
        choices: [
          {
            name: "P ≤ 400 kW",
            value: ":H:47500:H:40900:H:30500",
          },
          {
            name: "P > 400 kW",
            value: ":H:50100:H:43200:H:32100",
          },
        ],
      },
      {
        id: "appartments",
        title: "Nombre d'appartements",
        type: "number",
      },
      {
        id: "rFactor",
        title: "Coefficient R",
        type: "number",
      },
    ],
    attachments: [],
  },
];

export default barForms;

export const activeForms: string[] = barForms.filter((f) => f.fields.length > 0).map((f) => f.id);
