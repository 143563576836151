import { Card, Typography } from "@mui/material";
import { FC, useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { ProjectsContext } from "../data/contexts/ProjectsContext";
import ProjectsFilters from "../projects/ProjectsFilters";
import ProjectsSummary from "../projects/ProjectsSummary";

interface UserProjectsProps {
  userId: string;
}

const UserProjects: FC<UserProjectsProps> = ({ userId }) => {
  const { t } = useTranslation("user");
  const { projects } = useContext(ProjectsContext);
  const userProjects = projects.filter((p) => p.userId === userId);
  const [filteredProjectIds, setFilteredProjectsIds] = useState(userProjects.map((p) => p.id));

  const filteredProjects = projects.filter((p) => filteredProjectIds.includes(p.id));

  return (
    <Card variant="outlined" className="padding">
      {userProjects.length > 0 ? (
        <>
          <div className="row space-between margin-bottom">
            <Typography variant="h6">{t("projects")}</Typography>
            <ProjectsFilters setFilteredProjectsIds={setFilteredProjectsIds} projects={userProjects} />
          </div>
          <ProjectsSummary projects={filteredProjects} fromUser />
        </>
      ) : (
        <Typography>{t("noProject")}</Typography>
      )}
    </Card>
  );
};

export default UserProjects;
