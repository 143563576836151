import { Warning, InfoOutlined } from "@mui/icons-material";
import { Card, Typography, useTheme } from "@mui/material";
import { FC } from "react";
import { useTranslation } from "react-i18next";

interface WarningCardBodyData {
  titleLabelKey?: string;
  content: JSX.Element;
  noIcon?: boolean;
}

interface WarningCardProps {
  projectLabelKey?: string | string[];
  label?: string;
  warning?: boolean;
  info?: boolean;
  additionalLines?: string[];
  textInTitle?: boolean;
  className?: string;
  bodyData?: WarningCardBodyData;
}

const WarningCard: FC<WarningCardProps> = ({
  projectLabelKey,
  label,
  warning,
  info,
  additionalLines,
  textInTitle,
  className,
  bodyData,
}) => {
  const { t } = useTranslation(["project", "global"]);
  const theme = useTheme();
  let finalLabel = label || (Array.isArray(projectLabelKey) ? t(projectLabelKey[0]) : t(projectLabelKey || ""));
  if (!label && Array.isArray(projectLabelKey) && projectLabelKey.length > 1) {
    projectLabelKey.forEach((v, i) => {
      if (i > 0) finalLabel += `\n\n ${t(v)}`;
    });
  }
  let borderColor;
  if (warning) borderColor = theme.palette.warning.main;
  if (info) borderColor = theme.palette.info.main;
  return (
    <Card
      variant="outlined"
      style={{
        padding: 16,
        textAlign: "justify",
        borderColor,
        overflow: "visible",
      }}
      className={className}>
      {warning || info ? (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            textDecoration: "underline",
            color: borderColor,
          }}>
          {bodyData?.noIcon ? undefined : info ? (
            <InfoOutlined style={{ marginRight: 8 }} />
          ) : (
            <Warning style={{ marginRight: 8 }} />
          )}
          {(textInTitle && projectLabelKey) || bodyData?.titleLabelKey ? (
            <Typography variant="subtitle1">{t(bodyData?.titleLabelKey || projectLabelKey || "")}</Typography>
          ) : (
            <Typography variant="subtitle1">{t(`global:${info ? "infoTwo" : "warningTwo"}`)}</Typography>
          )}
        </div>
      ) : undefined}
      {textInTitle ? undefined : bodyData && bodyData.content ? (
        <span style={{ color: borderColor }}>{bodyData.content}</span>
      ) : (
        <>
          {warning || info ? <br /> : undefined}
          <Typography variant={info ? "body1" : "body2"} style={{ whiteSpace: "pre-line", color: borderColor }}>
            {finalLabel}
            {additionalLines && additionalLines.length > 0 ? `\n\n${additionalLines?.join("\n\n")}` : undefined}
          </Typography>
        </>
      )}
    </Card>
  );
};

WarningCard.defaultProps = {
  projectLabelKey: undefined,
  label: undefined,
  warning: true,
  info: false,
  additionalLines: [],
  textInTitle: false,
  className: undefined,
  bodyData: undefined,
};

export default WarningCard;
