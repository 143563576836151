import { Configuration, LogLevel } from "@azure/msal-browser";
import { log, Lvl } from "../utils/log";

const MSAL_CONFIG: Configuration = {
  auth: {
    clientId: process.env.REACT_APP_AZUREAD_APP_CLIENT_ID || "",
    authority: `https://login.microsoftonline.com/${process.env.REACT_APP_AZUREAD_AUTHORITY_ID}`,
    redirectUri: `${window.location.protocol}//${window.location.host}`,
  },
  cache: {
    cacheLocation: "localStorage",
    storeAuthStateInCookie: false,
  },
  system: {
    loggerOptions: {
      loggerCallback: (level, message, containsPii) => {
        if (!containsPii) {
          switch (level) {
            case LogLevel.Error:
              log(message, Lvl.ERROR);
              break;
            case LogLevel.Info:
              log(message, Lvl.INFO);
              break;
            case LogLevel.Verbose:
              log(message, Lvl.DEBUG);
              break;
            case LogLevel.Warning:
              log(message, Lvl.WARN);
              break;
            default:
              log(message);
              break;
          }
        }
      },
    },
  },
};

export default MSAL_CONFIG;

export const MSAL_DEFAULT_SCOPES = { scopes: ["User.Read"] };
