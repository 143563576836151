import { FC, useEffect } from "react";
import Yousign, { YousignEventData } from "./yousignIframeSDK";

interface SignatureIframeProps {
  signatureUrl: string;
  onSuccess: () => void;
  onDeclined: () => void;
  onError: (data: YousignEventData) => void;
  height?: number;
  width?: number;
}

const SignatureIframeId = "signature-iframe";

const SignatureIframe: FC<SignatureIframeProps> = ({
  signatureUrl,
  onSuccess,
  onDeclined,
  onError,
  height = 650,
  width = 1050,
}) => {
  useEffect(() => {
    const yousign = new Yousign({
      signatureLink: signatureUrl,
      iframeContainerId: SignatureIframeId,
      isSandbox: process.env.REACT_APP_ENV !== "production",
    });

    yousign.onSuccess(onSuccess);
    yousign.onDeclined(onDeclined);
    yousign.onError(onError);

    return () => yousign.removeMessageListener();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [signatureUrl]);
  return <div id={SignatureIframeId} style={{ width, height }} />;
};

SignatureIframe.defaultProps = { height: 650, width: 1050 };

export default SignatureIframe;
