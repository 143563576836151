import { TextField } from "@mui/material";
import { useSnackbar } from "notistack";
import { FC, ReactNode, useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { UserContext } from "../../data/contexts/UserContext";
import { AttachmentType, Maybe, Validation, ValidationStatus } from "../../data/generated/graphql";
import GdAlert from "../../utils/GdAlert";
import GdButton from "../../utils/GdButton";
import UploadFileButton from "./UploadFileButton";

interface ValidatorProps {
  validateOnServer: (validation: Validation) => Promise<boolean>;
  refuseOnServer: (validation: Validation) => Promise<boolean>;
  leftText: ReactNode;
  topButton: ReactNode;
  projectId: string;
  type: AttachmentType;
  validation?: Maybe<Validation>;
  hideValidationButtons?: boolean;
  operationId?: string;
}

const Validator: FC<ValidatorProps> = ({
  validation,
  validateOnServer,
  refuseOnServer,
  leftText,
  topButton,
  projectId,
  type,
  hideValidationButtons,
  operationId,
}) => {
  const [validating, setValidating] = useState(false);
  const [refusing, setRefusing] = useState(false);
  const [refuseOpen, setRefuseOpen] = useState(false);
  const [refuseReason, setRefuseReason] = useState("");
  const { userInfo } = useContext(UserContext);
  const { t } = useTranslation(["project", "global"]);
  const { enqueueSnackbar } = useSnackbar();

  const validate = async (): Promise<void> => {
    setValidating(true);
    const result = await validateOnServer({
      status: ValidationStatus.Accepted,
      userId: userInfo?.id || "",
      date: new Date().toISOString(),
    });
    if (!result) {
      enqueueSnackbar(t("global:errorWhileUpdating"), { variant: "error" });
    }
    setValidating(false);
  };
  const refuse = async (): Promise<void> => {
    setRefuseOpen(false);
    setRefusing(true);
    const result = await refuseOnServer({
      status: ValidationStatus.Rejected,
      comment: refuseReason,
      userId: userInfo?.id || "",
      date: new Date().toISOString(),
    });
    if (!result) {
      enqueueSnackbar(t("global:errorWhileUpdating"), { variant: "error" });
    }
    setRefusing(false);
  };

  return (
    <div className="row-top space-between margin-top">
      {leftText}
      <div className="column-right">
        {topButton}
        {!validation && !hideValidationButtons ? (
          <div className="row space-between margin-top">
            <GdButton
              label={t("refuseDocument")}
              color="inherit"
              isLoading={refusing}
              onClick={() => setRefuseOpen(true)}
            />
            <GdButton
              label={t("validateDocument")}
              className="big-margin-left"
              isLoading={validating}
              onClick={() => validate()}
            />
            <GdAlert
              open={refuseOpen}
              onClose={() => setRefuseOpen(false)}
              title={t("refuseReasonTitle")}
              body={
                <TextField
                  label={t("reason")}
                  value={refuseReason}
                  className="margin-top"
                  onChange={(e) => setRefuseReason(e.target.value)}
                />
              }
              okButtonClick={() => refuse()}
              cancelButton
            />
          </div>
        ) : validation?.status === ValidationStatus.Accepted ? (
          <UploadFileButton
            projectId={projectId}
            type={type}
            label={t("overrideDocument")}
            className="margin-top"
            overrideMode
            buttonWidth="normal"
            operationId={operationId}
          />
        ) : undefined}
      </div>
    </div>
  );
};

Validator.defaultProps = { validation: undefined, hideValidationButtons: undefined, operationId: undefined };

export default Validator;
