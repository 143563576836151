import { Typography } from "@mui/material";
import { FC } from "react";

interface GanttHeaderProps {
  range: Date[] | number[];
}

const GanttHeader: FC<GanttHeaderProps> = ({ range }) => {
  const grid = {
    display: `grid`,
    grid: `54px / repeat(${range.length}, 1fr)`,
    borderTop: `solid grey 1px`,
    borderBottom: `solid grey 1px`,
    borderRight: `solid grey 1px`,
  };
  const headerStyle = {
    borderLeft: `solid grey 1px`,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  };
  return (
    <div style={grid}>
      {range.map((r) =>
        r instanceof Date ? (
          <div key={r.getTime()} style={headerStyle}>
            <Typography style={{ textAlign: `center` }}>
              {r.getDate().toString().padStart(2, "0")} / {(r.getMonth() + 1).toString().padStart(2, "0")}
            </Typography>
          </div>
        ) : (
          <div key={r} style={headerStyle}>
            <Typography style={{ textAlign: `center` }}>S{r.toString().substring(4).padStart(2, "0")}</Typography>
          </div>
        ),
      )}
    </div>
  );
};

export default GanttHeader;
