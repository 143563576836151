import { Typography } from "@mui/material";
import { useSnackbar } from "notistack";
import { FC, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { UserContext } from "../data/contexts/UserContext";
import { UsersContext } from "../data/contexts/UsersContext";
import { User } from "../data/generated/graphql";
import BaseCard from "../utils/BaseCard";
import GdButton from "../utils/GdButton";
import GdIframeModal from "../utils/GdIframeModal";
import { log, Lvl } from "../utils/log";
import UserCard from "./UserCard";
import UserProjects from "./UserProjects";

const UserFile: FC = () => {
  const { userId } = useParams() as { userId: string };
  const { users, changeUserActivation } = useContext(UsersContext);
  const { userInfo } = useContext(UserContext);
  const [user, setUser] = useState<User | undefined>(users.find((u) => u.id === userId));
  const [charterOpen, setCharterOpen] = useState(false);
  const { t } = useTranslation(["user", "global"]);
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    setUser(users.find((u) => u.id === userId));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId]);

  const deOrReactivateUser = async (): Promise<void> => {
    try {
      const newActive = !user?.active;
      const res = await changeUserActivation(userId, newActive);
      if (res) {
        enqueueSnackbar(t("global:updateSuccess"), { variant: "success" });
        setUser(user ? { ...user, active: newActive } : undefined);
      } else enqueueSnackbar(t("global:errorWhileUpdating"), { variant: "error" });
    } catch (err) {
      log("Error while changing user activation", Lvl.ERROR, err);
      enqueueSnackbar(t("global:errorWhileUpdating"), { variant: "error" });
    }
  };

  const seeCharter = (): void => {
    setCharterOpen(true);
  };

  if (!user)
    return (
      <BaseCard>
        <Typography>{t("notFound")}</Typography>
      </BaseCard>
    );

  return (
    <BaseCard>
      <div className="row-top space-evenly">
        <div>
          <UserCard user={user} setUser={setUser} />
          {user.userCharter ? (
            <GdButton className="margin-top" label={t("viewCharter")} color="secondary" onClick={seeCharter} />
          ) : undefined}
          {userId !== userInfo?.id ? (
            <GdButton
              className="margin-top"
              label={t(user.active ? "deactivateUser" : "reactivateUser")}
              onClick={deOrReactivateUser}
            />
          ) : undefined}
        </div>
        <div className="big-margin-left" style={{ flex: 1 }}>
          <UserProjects userId={userId} />
        </div>
      </div>
      <GdIframeModal
        open={charterOpen}
        onClose={() => setCharterOpen(false)}
        iframeUrl={user.userCharter?.details?.completedFilesUrl ? user.userCharter?.details?.completedFilesUrl[0] : ""}
      />
    </BaseCard>
  );
};

export default UserFile;
