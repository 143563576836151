import { SkipNext, SkipPrevious } from "@mui/icons-material";
import { FormControlLabel, FormGroup, IconButton, Switch, Tooltip } from "@mui/material";
import { FC, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ArticlesContext } from "../data/contexts/ArticlesContext";
import { CustomProject } from "../data/contexts/ProjectsContext";
import {
  dateConvertDateToWeekNumber,
  dateConvertStringToWeekNumber,
  dateConvertToNumber,
  dateConvertToString,
} from "../data/dataConvertors";
import { ProjectLightFragment } from "../data/generated/graphql";
import GdButton from "../utils/GdButton";
import GanttHeader from "./components/GanttHeader";
import GanttProject from "./components/GanttProject";

// Magic number : nb day before & after central date
const STEP = 7;

interface ProjectsWithIndexesProps {
  project: CustomProject;
  indexStart?: number | null;
  indexEnd?: number | null;
  projectComment?: string | null;
  projectCommentDate?: string | null;
}

const GanttView: FC<{ projects: ProjectLightFragment[] }> = ({ projects }) => {
  const { t } = useTranslation("project");
  const today = new Date();
  today.setHours(0, 0, 0, 0);
  const [ganttDate, setGanttDate] = useState<Date>(today);
  const [ganttHeight, setGanttHeight] = useState<number>(window.innerHeight - 350);
  const [currentProjects, setCurrentProjects] = useState<ProjectsWithIndexesProps[]>([]);
  const [isDayView, setIsDayView] = useState<boolean>(false);
  const { comments } = useContext(ArticlesContext);

  let ganttDateMin = new Date();
  let ganttDateMax = new Date();
  const dateDayRange: Date[] = [];
  const dateWeekRange: number[] = [];
  const dateNumRange: number[] = [];

  if (isDayView) {
    ganttDateMin = new Date(ganttDate.getFullYear(), ganttDate.getMonth(), ganttDate.getDate() - STEP, 0, 0, 0);
    ganttDateMax = new Date(ganttDate.getFullYear(), ganttDate.getMonth(), ganttDate.getDate() + STEP, 23, 59, 59);
    for (let i = 0; i <= STEP * 2; i += 1) {
      const tmpDate = new Date(
        new Date(ganttDate.getFullYear(), ganttDate.getMonth(), ganttDate.getDate() - STEP + i, 0, 0, 0),
      );
      dateDayRange.push(tmpDate);
      dateNumRange.push(dateConvertToNumber(tmpDate));
    }
  } else {
    const firstWeekDay = new Date(ganttDate);
    const first = firstWeekDay.getDate() - (firstWeekDay.getDay() - 1);
    firstWeekDay.setDate(first);
    ganttDateMin = new Date(firstWeekDay.getFullYear(), firstWeekDay.getMonth(), firstWeekDay.getDate() - 7 * STEP);
    ganttDateMax = new Date(firstWeekDay.getFullYear(), firstWeekDay.getMonth(), firstWeekDay.getDate() + 7 * STEP);

    for (let i = 0; i < STEP * 2; i += 1) {
      const tmpDate = new Date(ganttDateMin.getFullYear(), ganttDateMin.getMonth(), ganttDateMin.getDate() + 7 * i);
      dateWeekRange.push(dateConvertDateToWeekNumber(tmpDate));
    }
  }

  useEffect(() => {
    if (ganttDate && ganttDateMin && ganttDateMax) {
      const tempProjects: ProjectsWithIndexesProps[] = [];
      projects.forEach((p: CustomProject) => {
        if (p.details?.startDateNum && p.details?.endDateNum) {
          const { startDateNum, endDateNum, startDate, endDate } = p.details;
          let indexStart = null;
          let indexEnd = null;
          if (isDayView) {
            indexStart = startDateNum > dateNumRange[dateNumRange.length - 1] ? -2 : dateNumRange.indexOf(startDateNum);
            indexEnd = endDateNum < dateNumRange[0] ? -2 : dateNumRange.indexOf(endDateNum);
          } else {
            const startDateWeekNumber = dateConvertStringToWeekNumber(startDate);
            const endDateWeekNumber = dateConvertStringToWeekNumber(endDate);
            indexStart =
              startDateWeekNumber > dateWeekRange[dateWeekRange.length - 1]
                ? -2
                : dateWeekRange.indexOf(startDateWeekNumber);
            indexEnd = endDateWeekNumber < dateWeekRange[0] ? -2 : dateWeekRange.indexOf(endDateWeekNumber);
          }
          const projectComments = comments.filter((c) => c.projectId === (p as ProjectLightFragment).id);
          let projectComment = null;
          let projectCommentDate = null;
          if (projectComments[0]?.body) {
            projectComment = projectComments[0].body;
            projectCommentDate = t("gantt.lastCommentOn", {
              replace: { date: dateConvertToString(projectComments[0].creation as string) },
            });
          }
          tempProjects.push({ project: p, indexStart, indexEnd, projectComment, projectCommentDate });
        }
      });
      tempProjects.sort((p1, p2) => {
        if ((p1.project.details?.startDateNum || 0) > (p2.project.details?.startDateNum || 0)) return 1;
        return -1;
      });
      setCurrentProjects(tempProjects);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ganttDate, projects, isDayView]);

  useEffect(() => {
    const handleResize = (): void => setGanttHeight(window.innerHeight - 350);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const ganttIsToday = ganttDate.getTime() === today.getTime();

  return (
    <>
      <FormGroup>
        <FormControlLabel
          control={<Switch checked={!isDayView} onChange={() => setIsDayView(!isDayView)} />}
          label={t("gantt.weekViewSwitch")}
          color="primary"
        />
      </FormGroup>
      <div style={{ display: "flex", justifyContent: "space-between", marginBottom: "8px" }}>
        <Tooltip title={t("gantt.backButton") || ""} arrow>
          <IconButton onClick={() => setGanttDate(ganttDateMin)} color="primary">
            <SkipPrevious />
          </IconButton>
        </Tooltip>
        <GdButton label={t("gantt.todayButton")} onClick={() => setGanttDate(today)} disabled={ganttIsToday} />
        <Tooltip title={t("gantt.nextButton") || ""} arrow>
          <IconButton onClick={() => setGanttDate(ganttDateMax)} color="primary">
            <SkipNext />
          </IconButton>
        </Tooltip>
      </div>
      <div>
        <GanttHeader range={isDayView ? dateDayRange : dateWeekRange} />
        <div style={{ maxHeight: `${ganttHeight}px`, overflowY: "auto" }}>
          {currentProjects.map((p) => (
            <GanttProject
              project={p}
              rangeLength={isDayView ? dateDayRange.length : dateWeekRange.length}
              key={`${p.project.id}_1`}
            />
          ))}
        </div>
      </div>
    </>
  );
};

export default GanttView;
