import { Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import { FC, ReactNode } from "react";
import { useTranslation } from "react-i18next";
import GdButton from "./GdButton";

interface GdAlertProps {
  open: boolean;
  onClose: () => void;
  title: string;
  body: ReactNode;
  okButtonClick?: () => void;
  cancelButton?: boolean;
  okButton?: boolean;
  okButtonLoading?: boolean;
  okButtonDisabled?: boolean;
  yesNo?: boolean;
}

const GdAlert: FC<GdAlertProps> = ({
  open,
  onClose,
  title,
  body,
  okButtonClick,
  cancelButton,
  okButtonLoading,
  okButton,
  okButtonDisabled,
  yesNo,
}) => {
  const { t } = useTranslation("global");

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>{body}</DialogContent>
      <DialogActions>
        {cancelButton ? (
          <GdButton label={t(yesNo ? "yesOrNo.no" : "cancel")} color="secondary" onClick={onClose} />
        ) : undefined}
        {okButton ? (
          <GdButton
            label={t(yesNo ? "yesOrNo.yes" : "ok")}
            onClick={okButtonClick || onClose}
            isLoading={okButtonLoading}
            disabled={okButtonDisabled}
          />
        ) : undefined}
      </DialogActions>
    </Dialog>
  );
};

GdAlert.defaultProps = {
  cancelButton: false,
  okButtonClick: undefined,
  okButtonLoading: false,
  okButton: true,
  okButtonDisabled: undefined,
  yesNo: false,
};

export default GdAlert;
