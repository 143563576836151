import { Chat, ExpandMore, Handyman } from "@mui/icons-material";
import { Accordion, AccordionDetails, AccordionSummary, Card, TextField, Tooltip, Typography } from "@mui/material";
import { FC, useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { ArticlesContext } from "../data/contexts/ArticlesContext";
import { UsersContext } from "../data/contexts/UsersContext";
import { dateConvertToString, nbDaysElapsed } from "../data/dataConvertors";
import { Comment, ProjectLightFragment, ProjectStatus, UserLightFragment } from "../data/generated/graphql";
import { checkIfProjectIsEmpty, checkIfProjectIsTest } from "../data/lists/ProjectStatuses";
import StatusLabel from "../utils/StatusLabel";

interface CommentedProject extends ProjectLightFragment {
  latestComment?: Comment;
  group: number;
}

interface UserProjects {
  user: UserLightFragment;
  projectsToTransform: CommentedProject[];
  transformedProjects: CommentedProject[];
}

const ProjectToRevive: FC<{
  project: CommentedProject;
  lastContactPrefix: string;
  showEnd: boolean;
  endPrefix: string;
}> = ({ project, lastContactPrefix, showEnd, endPrefix }) => (
  <div style={{ width: "300px" }}>
    <Link to={`/projects/${project.id}`}>
      <Card className="padding margin-bottom">
        <Typography className="bold">{project.name}</Typography>
        <StatusLabel status={project.detailedStatus} />
        {showEnd ? (
          <Typography className="vcenter">
            <Handyman fontSize="small" className="small-margin-right" />
            {endPrefix} {dateConvertToString(project.details?.endDate || "")}
          </Typography>
        ) : undefined}
        {project.latestComment ? (
          <Tooltip
            title={
              <Typography>
                {/* eslint-disable-next-line react/no-danger */}
                <span dangerouslySetInnerHTML={{ __html: project.latestComment.body }} />
              </Typography>
            }
            arrow>
            <Typography className="vcenter">
              <Chat fontSize="small" className="small-margin-right" />
              {lastContactPrefix} {dateConvertToString(project.latestComment.creation)}
            </Typography>
          </Tooltip>
        ) : undefined}
      </Card>
    </Link>
  </div>
);

const ProjectsToReviveColumn: FC<{
  projects: CommentedProject[];
  title: string;
  lastContactPrefix: string;
  showEnd?: boolean;
  endPrefix: string;
}> = ({ projects, title, lastContactPrefix, showEnd, endPrefix }) => (
  <div className="column-center" style={{ width: "320px" }}>
    <Typography className="bold">{title}</Typography>
    {projects.map((p) => (
      <ProjectToRevive
        project={p}
        lastContactPrefix={lastContactPrefix}
        showEnd={showEnd || false}
        endPrefix={endPrefix}
      />
    ))}
  </div>
);

ProjectsToReviveColumn.defaultProps = { showEnd: false };

const ProjectsToRevive: FC<{ up: UserProjects }> = ({ up }) => {
  const { t } = useTranslation("project");
  const toTransGroup1 = up.projectsToTransform.filter((p) => p.group === 0);
  const toTransGroup2 = up.projectsToTransform.filter((p) => p.group === 1);
  const toTransGroup3 = up.projectsToTransform.filter((p) => p.group === 2);
  const transGroup1 = up.transformedProjects.filter((p) => p.group === 0);
  const transGroup2 = up.transformedProjects.filter((p) => p.group === 1);
  const transGroup3 = up.transformedProjects.filter((p) => p.group === 2);
  return (
    <AccordionDetails>
      {up.projectsToTransform.length > 0 ? (
        <div>
          <div className="row small-margin-bottom">
            <Typography variant="h6">{t("toTransformFilter")}</Typography>
            <Typography className="small-margin-left">{t("revive.latestContact")}</Typography>
          </div>
          <div className="row-top space-evenly">
            <ProjectsToReviveColumn
              projects={toTransGroup1}
              title={t("revive.more60")}
              lastContactPrefix={t("lastContactOn")}
              endPrefix={t("endOfWork")}
            />
            <ProjectsToReviveColumn
              projects={toTransGroup2}
              title={t("revive.between30and60")}
              lastContactPrefix={t("lastContactOn")}
              endPrefix={t("endOfWork")}
            />
            <ProjectsToReviveColumn
              projects={toTransGroup3}
              title={t("revive.less30")}
              lastContactPrefix={t("lastContactOn")}
              endPrefix={t("endOfWork")}
            />
          </div>
        </div>
      ) : undefined}
      {up.projectsToTransform.length > 0 && up.transformedProjects.length > 0 ? (
        <div style={{ height: "16px" }} />
      ) : undefined}
      {up.transformedProjects.length > 0 ? (
        <div>
          <div className="row small-margin-bottom">
            <Typography variant="h6">{t("transformedFilter")}</Typography>
            <Typography className="small-margin-left">{t("revive.endOfWork")}</Typography>
          </div>
          <div className="row-top space-evenly">
            <ProjectsToReviveColumn
              projects={transGroup1}
              title={t("revive.less60")}
              lastContactPrefix={t("lastContactOn")}
              showEnd
              endPrefix={t("endOfWork")}
            />
            <ProjectsToReviveColumn
              projects={transGroup2}
              title={t("revive.less30")}
              lastContactPrefix={t("lastContactOn")}
              showEnd
              endPrefix={t("endOfWork")}
            />
            <ProjectsToReviveColumn
              projects={transGroup3}
              title={t("revive.afterEndWork")}
              lastContactPrefix={t("lastContactOn")}
              showEnd
              endPrefix={t("endOfWork")}
            />
          </div>
        </div>
      ) : undefined}
    </AccordionDetails>
  );
};

const isOlderThan = (dateString: string, nbDays: number): boolean => {
  const d = new Date(dateString);
  const now = new Date();
  now.setDate(now.getDate() - nbDays);
  return d.getTime() < now.getTime();
};

const ProjectsRevive: FC<{ projects: ProjectLightFragment[] }> = ({ projects }) => {
  const [userFilter, setUserFilter] = useState("");
  const { comments } = useContext(ArticlesContext);
  const { users } = useContext(UsersContext);
  const { t } = useTranslation("project");

  const finalProjects: UserProjects[] = [];
  const addProjectToUser = (project: ProjectLightFragment, transformed: boolean, latestComment?: Comment): void => {
    const user = users.find((u) => u.id === project.userId);
    if (userFilter.length > 0 && !user?.name?.toLowerCase().includes(userFilter.toLowerCase())) return;
    let userFound = false;
    let group = 0;
    if (transformed) {
      const nbDays = nbDaysElapsed(project.details?.endDate);
      if (nbDays > 0) group = 2;
      else if (nbDays > -30) group = 1;
    } else if (latestComment) {
      const nbDays = nbDaysElapsed(latestComment?.creation);
      if (nbDays < 30) group = 2;
      else if (nbDays < 60) group = 1;
    }
    const commentedProject = { ...project, latestComment, group };
    finalProjects.forEach((up) => {
      if (up.user.id === project.userId) {
        if (transformed) up.transformedProjects.push(commentedProject);
        else up.projectsToTransform.push(commentedProject);
        userFound = true;
      }
    });
    if (!userFound) {
      if (user) {
        const pArray = [commentedProject];
        finalProjects.push({
          user,
          transformedProjects: transformed ? pArray : [],
          projectsToTransform: transformed ? [] : pArray,
        });
      }
    }
  };

  projects.forEach((p) => {
    if (checkIfProjectIsEmpty(p) || checkIfProjectIsTest(p)) return;
    const projectComments = comments.filter((c) => c.projectId === p.id);
    const latestComment = projectComments.length > 0 ? projectComments[0] : undefined;
    if (
      (p.status === ProjectStatus.Launched || p.status === ProjectStatus.Simulation) &&
      (latestComment ? isOlderThan(latestComment.creation, 14) : isOlderThan(p.creation || "", 14))
    ) {
      addProjectToUser(p, false, latestComment);
    } else if (p.status === ProjectStatus.PreworkCompleted && isOlderThan(p.details?.endDate || "", -60)) {
      addProjectToUser(p, true, latestComment);
    }
  });

  finalProjects.sort((p1, p2) =>
    (p1.user?.name?.toLowerCase() || "") > (p2.user?.name?.toLowerCase() || "") ? 1 : -1,
  );

  return (
    <div>
      <TextField
        value={userFilter}
        onChange={(e) => setUserFilter(e.target.value)}
        variant="outlined"
        label={t("revive.filterUser")}
        className="margin-bottom"
        fullWidth
      />
      {finalProjects.map((up) => (
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMore />}>
            <Typography>
              <span className="bold">{up.user.name}</span>
              {up.projectsToTransform.length > 0
                ? ` / ${t("revive.toTransform", { count: up.projectsToTransform.length })}`
                : ""}
              {up.transformedProjects.length > 0
                ? ` / ${t("revive.transformed", { count: up.transformedProjects.length })}`
                : ""}
            </Typography>
          </AccordionSummary>
          <ProjectsToRevive up={up} />
        </Accordion>
      ))}
    </div>
  );
};

export default ProjectsRevive;
