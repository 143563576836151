/* eslint-disable no-console */
export enum Lvl {
  DEBUG,
  INFO,
  WARN,
  ERROR,
  NONE,
}

export const log = (msg: unknown, lvl: Lvl = Lvl.NONE, ...args: unknown[]): void => {
  let logMethod = console.log;
  switch (lvl) {
    case Lvl.DEBUG:
      logMethod = console.debug;
      break;
    case Lvl.INFO:
      logMethod = console.info;
      break;
    case Lvl.WARN:
      logMethod = console.warn;
      break;
    case Lvl.ERROR:
      logMethod = console.error;
      break;
    default:
      logMethod = console.log;
      break;
  }
  if (args.length > 0) logMethod(msg, args);
  else logMethod(msg);
};
