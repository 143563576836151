import axios from "axios";
import { createContext, FC, PropsWithChildren, useContext, useState } from "react";
import { log, Lvl } from "../../utils/log";
import {
  Article,
  ArticleInput,
  AttachmentType,
  Comment,
  CommentInput,
  useArticleRemoveAttachmentMutation,
  useArticleUpsertMutation,
  useCommentRemoveAttachmentMutation,
  useCommentUpsertMutation,
} from "../generated/graphql";
import { TokenContext } from "./TokenContext";

export interface ArticlesContextProps {
  setArticles: (articles: Article[]) => void;
  updateArticle: (article: ArticleInput) => Promise<boolean>;
  articles: Article[];
  uploadAttachment: (articleId: string, file: File, type?: AttachmentType) => Promise<boolean>;
  removeAttachment: (articleId: string, attachmentId: string, type?: AttachmentType) => Promise<boolean>;
  comments: Comment[];
  setComments: (comments: Comment[]) => void;
  updateComment: (comment: CommentInput) => Promise<boolean>;
}

const initialContext: ArticlesContextProps = {
  setArticles: () => {},
  updateArticle: () => Promise.resolve(false),
  articles: [],
  uploadAttachment: () => Promise.resolve(false),
  removeAttachment: () => Promise.resolve(false),
  comments: [],
  setComments: () => {},
  updateComment: () => Promise.resolve(false),
};

export const ArticlesContext = createContext<ArticlesContextProps>(initialContext);

export const ArticlesProvider: FC<PropsWithChildren> = ({ children }) => {
  const [articles, setArticles] = useState<Article[]>([]);
  const [comments, setComments] = useState<Comment[]>([]);
  const [upsertArticle] = useArticleUpsertMutation();
  const [articleRemoveAttachment] = useArticleRemoveAttachmentMutation();
  const [upsertComment] = useCommentUpsertMutation();
  const [commentRemoveAttachment] = useCommentRemoveAttachmentMutation();
  const { token } = useContext(TokenContext);

  const updateArticle = async (article: ArticleInput): Promise<boolean> => {
    const result = await upsertArticle({ variables: { article } });
    if (result && result.data && result.data.articleUpsert) {
      const newArticle = result.data.articleUpsert;
      let found = false;
      const newArticles = articles.map((a) => {
        if (a.id === newArticle.id) {
          found = true;
          return newArticle;
        }
        return a;
      });
      if (!found) newArticles.push(newArticle);
      setArticles(newArticles);
      return true;
    }
    return false;
  };

  const updateComment = async (comment: CommentInput): Promise<boolean> => {
    const result = await upsertComment({ variables: { comment } });
    if (result && result.data && result.data.commentUpsert) {
      const newComment = result.data.commentUpsert;
      let found = false;
      let newComments = comments.map((c) => {
        if (c.id === newComment.id) {
          found = true;
          return newComment;
        }
        return c;
      });
      if (!found) newComments = [newComment, ...newComments];
      setComments(newComments);
      return true;
    }
    return false;
  };

  const uploadAttachment = async (articleId: string, file: File, type?: AttachmentType): Promise<boolean> => {
    try {
      const data = new FormData();
      data.append("file", file);
      const result = await axios({
        url: `${process.env.REACT_APP_UPLOAD_API_URL || ""}${
          process.env.REACT_APP_UPLOAD_API_URL?.indexOf("?") === -1 ? "?" : "&"
        }articleId=${articleId}&type=${type || AttachmentType.ArticleMedia}&name=${file.name}`,
        method: "POST",
        data,
        headers: {
          authorization: `Bearer ${token}`,
          "content-type": "multipart/form-data",
        },
      });
      if (result.status !== 200) {
        log("Error while uploading", Lvl.ERROR, result);
        return false;
      }
      if (type === AttachmentType.CommentAttachment) {
        const newComment = result.data as Comment;
        setComments(comments.map((c) => (c.id === newComment.id ? newComment : c)));
      } else {
        const newArticle = result.data as Article;
        setArticles(articles.map((a) => (a.id === newArticle.id ? newArticle : a)));
      }
      return true;
    } catch (err) {
      log("Error while uploading", Lvl.ERROR, err);
      return false;
    }
  };

  const removeAttachment = async (articleId: string, attachmentId: string, type?: AttachmentType): Promise<boolean> => {
    if (type === AttachmentType.CommentAttachment) {
      const result = await commentRemoveAttachment({ variables: { commentId: articleId, attachmentId } });
      if (result && result.data && result.data.commentRemoveAttachment) {
        const newComment = result.data.commentRemoveAttachment;
        const newComments = comments.map((c) => (c.id === newComment.id ? newComment : c));
        setComments(newComments);
        return true;
      }
    } else {
      const result = await articleRemoveAttachment({ variables: { articleId, attachmentId } });
      if (result && result.data && result.data.articleRemoveAttachment) {
        const newArticle = result.data.articleRemoveAttachment;
        const newArticles = articles.map((a) => (a.id === newArticle.id ? newArticle : a));
        setArticles(newArticles);
        return true;
      }
    }
    return false;
  };

  return (
    <ArticlesContext.Provider
      value={{
        setArticles,
        updateArticle,
        articles,
        uploadAttachment,
        removeAttachment,
        comments,
        setComments,
        updateComment,
      }}>
      {children}
    </ArticlesContext.Provider>
  );
};
