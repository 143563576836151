import { Modal, Paper, Typography } from "@mui/material";
import { useSnackbar } from "notistack";
import { FC, useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { ProjectsContext } from "../../data/contexts/ProjectsContext";
import { dateConvertToString, documentToDocumentInput } from "../../data/dataConvertors";
import {
  AttachmentType,
  Company,
  CompanyInput,
  DocumentType,
  Maybe,
  ProcedureStatus,
  Project,
  Signer,
  SignerInput,
  User,
  Validation,
  ValidationStatus,
} from "../../data/generated/graphql";
import GdAlert from "../../utils/GdAlert";
import GdButton from "../../utils/GdButton";
import SignerDetails from "../components/SignerDetails";
import SignerDetailsReadOnly from "../components/SignerDetailsReadOnly";
import { quoteIsAccepted } from "../ProjectHelper";
import UploadFileButton from "./UploadFileButton";
import Validator from "./Validator";

interface ManualConventionDetailsProps {
  project: Project;
  detailsText: string;
}

const companyToCompanyInput = (company: Company): CompanyInput => {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  const { __typename, ...result } = company;
  return result;
};
const emptySigner = { company: { siret: "" } };
const signerToSignerInput = (signer?: Maybe<Signer> | User): SignerInput => {
  if (!signer) return emptySigner;
  const company = signer.company ? companyToCompanyInput(signer.company) : { siret: "" };
  // eslint-disable-next-line @typescript-eslint/naming-convention
  const { __typename, id, userCharter, ...result } = signer as User;
  return { ...result, company };
};

const ManualConventionDetails: FC<ManualConventionDetailsProps> = ({ project, detailsText }) => {
  const [conventionIsReseting, setConventionIsReseting] = useState(false);
  const [isSignersOpen, setIsSignersOpen] = useState(false);
  const [signerOpen, setSignerOpen] = useState(false);
  const [clientOpen, setClientOpen] = useState(false);
  const [noRefOpen, setNoRefOpen] = useState(false);
  const [refVal, setRefVal] = useState<Validation | null>(null);
  const [isValidating, setIsValidating] = useState(false);
  const { validateManualDocument, updateProject } = useContext(ProjectsContext);
  const { t } = useTranslation("project");
  const { enqueueSnackbar } = useSnackbar();

  const openConvention = (): void => {
    if (!project.convention?.details?.completedFilesUrl) return;
    const url = project.convention?.details?.completedFilesUrl[0];
    if (url) window.open(`${url}?t=${new Date().getTime()}`);
  };
  const validateTheConvention = (validation: Validation): Promise<boolean> =>
    validateManualDocument(project.id, AttachmentType.Convention, true, validation.userId);
  const refuseConvention = (validation: Validation): Promise<boolean> =>
    validateManualDocument(
      project.id,
      AttachmentType.Convention,
      false,
      validation.userId,
      validation.comment as string,
    );
  const conventionValidated = project.convention?.validation?.status === ValidationStatus.Accepted;
  const tryValidate = (validation: Validation): Promise<boolean> => {
    if (!project.reference || project.reference.length === 0) setNoRefOpen(true);
    else setRefVal(validation);
    return Promise.resolve(true);
  };
  const resetConvention = async (): Promise<void> => {
    setConventionIsReseting(true);
    const result = await updateProject({
      id: project.id,
      convention: documentToDocumentInput({
        ...project.convention,
        status: ProcedureStatus.Asked,
      }),
    });
    if (!result) {
      enqueueSnackbar(t("global:errorWhileUpdating"), { variant: "error" });
    } else {
      enqueueSnackbar(t("global:updateSuccess"), { variant: "success" });
    }
    setConventionIsReseting(false);
  };

  return (
    <>
      <Validator
        validation={project.convention?.validation}
        validateOnServer={tryValidate}
        refuseOnServer={refuseConvention}
        leftText={
          <div>
            <div style={{ display: "flex", gap: "8px" }}>
              <Typography variant="h6" className="margin-bottom">
                {t("conventionManualTwo", {
                  replace: { type: (project.convention?.type || DocumentType.Tripartite).toLowerCase() },
                })}
              </Typography>
              {!quoteIsAccepted(project) ? (
                <GdButton
                  label={t("resetConvention")}
                  className="margin-bottom"
                  color="warning"
                  onClick={() => resetConvention()}
                  isLoading={conventionIsReseting}
                />
              ) : undefined}
            </div>
            <Typography className="margin-bottom">{detailsText}</Typography>
            <Typography>
              {!project.convention?.validation
                ? t("checkManualConvention")
                : t(
                    project.convention.validation.status === ValidationStatus.Accepted
                      ? "manualConventionValidated"
                      : "manualConventionRefused",
                    {
                      replace: {
                        date: dateConvertToString(project.convention.validation.date),
                        reason: project.convention.validation.comment,
                      },
                    },
                  )}
            </Typography>
          </div>
        }
        topButton={
          <>
            <GdButton
              label={t("seeSigners")}
              className="margin-bottom"
              color="secondary"
              onClick={() => setIsSignersOpen(true)}
            />
            {conventionValidated ? undefined : (
              <UploadFileButton
                projectId={project.id}
                label={t("uploadNewManualConvention")}
                type={AttachmentType.Convention}
                className="margin-bottom text-center"
                buttonWidth="normal"
              />
            )}
            <GdButton
              label={t(conventionValidated ? "seeManualConvention" : "seeConventionUploaded")}
              color="secondary"
              onClick={openConvention}
            />
          </>
        }
        projectId={project.id}
        type={AttachmentType.Convention}
      />
      <Modal open={isSignersOpen} onClose={() => setIsSignersOpen(false)} className="project-modal-root">
        <Paper className="project-modal-paper" style={{ minHeight: "inherit" }}>
          <Typography variant="h5" className="margin-bottom text-center">
            {t("signers")}
          </Typography>
          <div className="row space-between">
            <SignerDetailsReadOnly
              signer={project.userSigner}
              title={t("userTwo")}
              modifySigner={() => setSignerOpen(true)}
            />
            <div style={{ width: 32 }} />
            <SignerDetailsReadOnly
              signer={project.clientSigner || project.client}
              title={t("clientTwo")}
              modifySigner={() => setClientOpen(true)}
            />
          </div>
        </Paper>
      </Modal>
      <Modal open={signerOpen} onClose={() => setSignerOpen(false)} className="project-modal-root">
        <Paper className="project-modal-paper signer-details">
          <SignerDetails
            projectId={project.id}
            signer={signerToSignerInput(project.userSigner)}
            onClose={() => setSignerOpen(false)}
            mySigner
          />
        </Paper>
      </Modal>
      <Modal open={clientOpen} onClose={() => setClientOpen(false)} className="project-modal-root">
        <Paper className="project-modal-paper signer-details">
          <SignerDetails
            projectId={project.id}
            signer={signerToSignerInput(project.clientSigner || project.client)}
            onClose={() => setClientOpen(false)}
          />
        </Paper>
      </Modal>
      <GdAlert
        open={noRefOpen}
        onClose={() => setNoRefOpen(false)}
        title={t("refAlertTitle")}
        body={t("noRefAlertBody")}
      />
      <GdAlert
        open={Boolean(refVal)}
        onClose={() => setRefVal(null)}
        title={t("refAlertTitle")}
        body={t("verifRefAlertBody", { replace: { reference: project.reference } })}
        cancelButton
        okButton
        okButtonClick={async () => {
          if (refVal) {
            setIsValidating(true);
            await validateTheConvention(refVal);
            setRefVal(null);
            setIsValidating(false);
          }
        }}
        okButtonLoading={isValidating}
        yesNo
      />
    </>
  );
};

export default ManualConventionDetails;
