import { Check, Close, HourglassBottom } from "@mui/icons-material";
import { Modal, Paper, Typography } from "@mui/material";
import { useSnackbar } from "notistack";
import { FC, useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { ProjectsContext } from "../../data/contexts/ProjectsContext";
import { dateConvertToString, documentToDocumentInput } from "../../data/dataConvertors";
import {
  AttachmentType,
  DocumentType,
  MemberSignature,
  MemberStatus,
  MemberType,
  ProcedureStatus,
  Project,
} from "../../data/generated/graphql";
import GdButton from "../../utils/GdButton";
import GdList, { GdListItem } from "../../utils/GdList";
import GdModal from "../../utils/GdModal";
import { log, Lvl } from "../../utils/log";
import SignatureIframe from "../../utils/SignatureIframe";
import { YousignEventData } from "../../utils/yousignIframeSDK";
import SignerDetailsReadOnly from "../components/SignerDetailsReadOnly";
import ManualConventionDetails from "./ManualConventionDetails";
import UploadFileButton from "./UploadFileButton";
import { quoteIsAccepted } from "../ProjectHelper";

export interface ConventionDetailsProps {
  project: Project;
}

const ConventionDetails: FC<ConventionDetailsProps> = ({ project }) => {
  const [isSignatureOpen, setIsSignatureOpen] = useState(false);
  const [checking, setChecking] = useState(false);
  const [isSignersOpen, setIsSignersOpen] = useState(false);
  const [conventionIsReseting, setConventionIsReseting] = useState(false);
  const { optimisticConventionSignature, checkConvention, updateProject } = useContext(ProjectsContext);
  const { t } = useTranslation(["project", "global"]);
  const { enqueueSnackbar } = useSnackbar();
  const [conventionValidityIsExtending, setConventionValidityIsExtending] = useState(false);
  const [newExpirationDate, setNewExpirationDate] = useState<number>(Date.now());
  // 14 days more
  const validityExtension = 14 * 24 * 3600 * 1000;

  const showSignature = (): void => setIsSignatureOpen(true);
  const hideSignature = (): void => setIsSignatureOpen(false);
  const detailsText = t(project.convention?.paper ? "createdExpiringManual" : "createdExpiring", {
    replace: {
      creation: project.convention?.details?.creationDate
        ? dateConvertToString(project.convention?.details?.creationDate)
        : "?",
      expiration: project.convention?.details?.expirationDate
        ? dateConvertToString(project.convention?.details?.expirationDate)
        : "?",
    },
  });

  const userRefused = (): void => {
    enqueueSnackbar(t("adminRefused"), { variant: "warning" });
    optimisticConventionSignature(project, true);
    hideSignature();
  };

  const userSigned = (): void => {
    optimisticConventionSignature(project);
    hideSignature();
  };

  const onYousignError = (data: YousignEventData): void => {
    log("Yousign sent an error", Lvl.ERROR, data);
    enqueueSnackbar(t("global:errorWhileUpdating"), { variant: "error" });
  };

  if (project.convention?.paper) return <ManualConventionDetails project={project} detailsText={detailsText} />;

  const checkSignatures = async (): Promise<void> => {
    setChecking(true);
    const result = await checkConvention(project.id);
    if (!result) {
      enqueueSnackbar(t("global:errorWhileUpdating"), { variant: "error" });
    }
    setChecking(false);
  };
  const isConventionFinished = project.convention?.status === ProcedureStatus.Finished;
  const userSignature = project.convention?.details?.members?.find((m) => m.type === MemberType.User);
  const clientSignature = project.convention?.details?.members?.find((m) => m.type === MemberType.Client);
  const adminSignature = project.convention?.details?.members?.find((m) => m.type === MemberType.Admin);
  const isConventionSignedByOthers =
    project.convention?.status !== ProcedureStatus.Refused &&
    project.convention?.status !== ProcedureStatus.Finished &&
    userSignature?.status === MemberStatus.Done &&
    clientSignature?.status === MemberStatus.Done &&
    adminSignature?.status === MemberStatus.Pending;
  const isConventionRefused = project.convention?.status === ProcedureStatus.Refused;
  const isConventionSignedByAdmin = adminSignature?.status === MemberStatus.Done;
  const isConventionExpired = project.convention?.status === ProcedureStatus.Expired;
  const getStatusLabel = (): string => {
    if (isConventionFinished) return t("projectConventionFinished");
    if (isConventionExpired) return t("projectConventionExpired");
    if (isConventionSignedByOthers) return t("projectConventionSignedByOthers");
    if (isConventionRefused) return t("projectConventionRefused");
    if (isConventionSignedByAdmin) return t("projectConventionSignedByAdmin");
    return t("projectConventionOngoing");
  };
  const openConvention = (): void => {
    const url = isConventionFinished
      ? project.convention?.details?.completedFilesUrl?.[0] ||
        project.convention?.modelUrl?.replace("Convention_", "signed_Convention_")
      : project.convention?.modelUrl;
    if (url) window.open(`${url}?t=${new Date().getTime()}`);
  };
  const getSignatureItem = (signature: MemberSignature | undefined, resultKey: string): GdListItem => {
    let contentKey = "onGoing";
    let Icon = HourglassBottom;
    if (signature?.status === MemberStatus.Refused) {
      contentKey = "refused";
      Icon = Close;
    } else if (signature?.status === MemberStatus.Done) {
      contentKey = "signed";
      Icon = Check;
    }
    return {
      content: t(resultKey, { replace: { status: t(contentKey) } }),
      icon: <Icon />,
    };
  };
  const resetConvention = async (): Promise<void> => {
    setConventionIsReseting(true);
    const result = await updateProject({
      id: project.id,
      convention: documentToDocumentInput({
        ...project.convention,
        status: ProcedureStatus.Asked,
      }),
    });
    if (!result) {
      enqueueSnackbar(t("global:errorWhileUpdating"), { variant: "error" });
    } else {
      enqueueSnackbar(t("global:updateSuccess"), { variant: "success" });
    }
    setConventionIsReseting(false);
  };

  const extendConventionValidity = async (): Promise<void> => {
    setConventionValidityIsExtending(true);
    const newDate = newExpirationDate + validityExtension;
    setNewExpirationDate(newDate);
    const tempExpirationDate = new Date(newDate);
    try {
      const result = await updateProject({
        id: project.id,
        convention: documentToDocumentInput({
          ...project.convention,
          status:
            isConventionSignedByOthers || isConventionSignedByAdmin ? ProcedureStatus.Ongoing : ProcedureStatus.Created,
          details: {
            ...project.convention?.details,
            expirationDate: tempExpirationDate.toISOString(),
          },
        }),
      });
      if (!result) {
        enqueueSnackbar(t("global:errorWhileUpdating"), { variant: "error" });
      } else {
        enqueueSnackbar(t("global:updateSuccess"), { variant: "success" });
      }
    } catch (e) {
      enqueueSnackbar((e as Error).message, { variant: "error" });
    }
    setConventionValidityIsExtending(false);
  };

  const isBipartite = project.convention?.type === DocumentType.Bipartite;
  return (
    <div className="row-top space-between margin-top">
      <div>
        <div style={{ display: "flex", gap: "8px" }}>
          <Typography variant="h6" className="margin-bottom">
            {t("conventionDigitalTwo", {
              replace: { type: (project.convention?.type || DocumentType.Tripartite).toLowerCase() },
            })}
          </Typography>
          {!quoteIsAccepted(project) ? (
            <GdButton
              label={t("resetConvention")}
              className="margin-bottom"
              color="warning"
              onClick={() => resetConvention()}
              isLoading={conventionIsReseting}
            />
          ) : undefined}
        </div>
        <Typography>{detailsText}</Typography>
        {isConventionExpired ? undefined : (
          <GdList
            dense
            items={
              isBipartite
                ? [
                    getSignatureItem(clientSignature, "clientSignature"),
                    getSignatureItem(adminSignature, "adminSignature"),
                  ]
                : [
                    getSignatureItem(userSignature, "userSignature"),
                    getSignatureItem(clientSignature, "clientSignature"),
                    getSignatureItem(adminSignature, "adminSignature"),
                  ]
            }
          />
        )}
        <Typography className="margin-top">{getStatusLabel()}</Typography>
      </div>
      <div className="column-right">
        {project.convention?.status === ProcedureStatus.Expired ? (
          <GdButton
            label={t("extendConventionValidity")}
            className="margin-bottom"
            color="warning"
            onClick={extendConventionValidity}
            isLoading={conventionValidityIsExtending}
          />
        ) : undefined}
        <GdButton
          label={t("seeSigners")}
          className="margin-bottom"
          color="secondary"
          onClick={() => setIsSignersOpen(true)}
        />

        <GdButton
          label={t(isConventionFinished ? "seeSignedConvention" : "seeConvention")}
          color="secondary"
          onClick={openConvention}
        />
        {/* eslint-disable-next-line no-nested-ternary */}
        {!isConventionSignedByAdmin && !isConventionExpired ? (
          <GdButton label={t("signConvention")} onClick={showSignature} className="margin-top" />
        ) : undefined}
        {isConventionFinished || isConventionExpired ? undefined : (
          <GdButton
            label={t("checkSignatures")}
            color="inherit"
            onClick={checkSignatures}
            isLoading={checking}
            className="margin-top"
          />
        )}
        <UploadFileButton
          projectId={project.id}
          label={t("uploadManualConvention")}
          type={AttachmentType.Convention}
          className="margin-top"
          color="secondary"
          fullWidth
        />
      </div>
      <Modal open={isSignersOpen} onClose={() => setIsSignersOpen(false)} className="project-modal-root">
        <Paper className="project-modal-paper" style={{ minHeight: "inherit" }}>
          <Typography variant="h5" className="margin-bottom text-center">
            {t("signers")}
          </Typography>
          <div className="row space-between">
            <SignerDetailsReadOnly signer={project.userSigner} title={t("userTwo")} />
            <div style={{ width: 32 }} />
            <SignerDetailsReadOnly signer={project.clientSigner || project.client} title={t("clientTwo")} />
          </div>
        </Paper>
      </Modal>
      <GdModal open={isSignatureOpen} onClose={hideSignature}>
        <SignatureIframe
          signatureUrl={project.convention?.details?.members?.find((m) => m.type === MemberType.Admin)?.url || ""}
          onSuccess={userSigned}
          onDeclined={userRefused}
          onError={onYousignError}
        />
      </GdModal>
    </div>
  );
};

export default ConventionDetails;
