import { TextField, Typography } from "@mui/material";
import { FC, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { UsersContext } from "../data/contexts/UsersContext";
import BaseCard from "../utils/BaseCard";
import CenteredContent from "../utils/CenteredContent";
import UsersSummary from "./UsersSummary";

const Users: FC = () => {
  const { getUsers, users } = useContext(UsersContext);
  const [loading, setLoading] = useState(false);
  const [filter, setFilter] = useState("");
  const { t } = useTranslation("user");

  useEffect(() => {
    if (users.length === 0) {
      setLoading(true);
      getUsers().then(() => setLoading(false));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (loading) return <CenteredContent loading />;

  const filteredUsers = users.filter(
    (u) =>
      (u.name && u.name?.toLowerCase().indexOf(filter.toLowerCase()) !== -1) ||
      (u.email && u.email?.toLowerCase().indexOf(filter.toLowerCase()) !== -1) ||
      (u.role && u.role?.toLowerCase().indexOf(filter.toLowerCase()) !== -1),
  );

  return (
    <BaseCard>
      <div className="row space-between margin-bottom">
        <Typography variant="h6">{t("users")}</Typography>
        <TextField
          value={filter}
          onChange={(e) => setFilter(e.target.value)}
          variant="outlined"
          label={t("filterUsers")}
          style={{ width: 300 }}
        />
      </div>
      {filteredUsers.length === 0 ? (
        <Typography className="text-center margin-top margin-bottom">{t("noUserForFilter")}</Typography>
      ) : (
        <UsersSummary users={filteredUsers} />
      )}
    </BaseCard>
  );
};

export default Users;
