import { Menu, MenuItem } from "@mui/material";
import { FC } from "react";
import { useTranslation } from "react-i18next";

interface UserMenuProps {
  anchorEl: Element | null;
  signOut: () => void;
  close: () => void;
  nav: (to: string) => void;
}

const UserMenu: FC<UserMenuProps> = ({ anchorEl, signOut, close, nav }) => {
  const { t } = useTranslation("global");
  return (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      id="auth-menu"
      keepMounted
      transformOrigin={{ vertical: "bottom", horizontal: "right" }}
      open={Boolean(anchorEl)}
      onClose={close}>
      <MenuItem
        key="myProfile"
        onClick={() => {
          nav("/profile");
          close();
        }}>
        {t("titles./profile")}
      </MenuItem>
      <MenuItem
        key="logOut"
        onClick={() => {
          nav("/");
          close();
          signOut();
        }}>
        {t("logOut")}
      </MenuItem>
    </Menu>
  );
};

export default UserMenu;
