import { Delete, InsertDriveFile } from "@mui/icons-material";
import {
  Avatar,
  CircularProgress,
  IconButton,
  Modal,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import { SnackbarKey, useSnackbar } from "notistack";
import { FC, useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import RichTextEditor from "../../common/RichTextEditor";
import { ArticlesContext } from "../../data/contexts/ArticlesContext";
import { UserContext } from "../../data/contexts/UserContext";
import { UsersContext } from "../../data/contexts/UsersContext";
import { dateConvertToString, timeConvertToString } from "../../data/dataConvertors";
import { AttachmentType, Comment, Project } from "../../data/generated/graphql";
import CenteredContent from "../../utils/CenteredContent";
import GdButton from "../../utils/GdButton";
import UploadFileButton from "../documents/UploadFileButton";

interface ProjectCommentsProps {
  project: Project;
}

const ProjectComments: FC<ProjectCommentsProps> = ({ project }) => {
  const [body, setBody] = useState("");
  const [open, setOpen] = useState(false);
  const [updating, setUpdating] = useState(false);
  const [removing, setRemoving] = useState<string | null>(null);
  const [commentId, setCommentId] = useState("");
  const { comments, updateComment, uploadAttachment, removeAttachment } = useContext(ArticlesContext);
  const { users } = useContext(UsersContext);
  const { userInfo } = useContext(UserContext);
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation(["project", "global"]);
  const projectComments = comments.filter((c) => c.projectId === project.id);
  const currentUserId = userInfo?.id;

  const openCommentModal = (comment?: Comment): void => {
    setBody(comment?.body || "");
    setCommentId(comment?.id || "");
    setOpen(true);
  };
  const closeCommentModal = (): void => {
    setBody("");
    setCommentId("");
    setOpen(false);
  };

  const showError = (): SnackbarKey => enqueueSnackbar(t("global:errorWhileUpdating"), { variant: "error" });
  const updateOnServer = async (): Promise<void> => {
    setUpdating(true);
    try {
      const result = await updateComment({ id: commentId, body, projectId: project.id, authorId: userInfo?.id || "" });
      if (!result) showError();
      else {
        enqueueSnackbar(t("global:updateSuccess"), { variant: "success" });
        closeCommentModal();
      }
    } catch (err) {
      showError();
    }
    setUpdating(false);
  };

  const deleteAttachment = async (cId: string, aId: string): Promise<void> => {
    setRemoving(aId);
    await removeAttachment(cId, aId, AttachmentType.CommentAttachment);
    setRemoving(null);
  };

  return (
    <div style={{ flex: 1 }}>
      <div className="row space-between">
        <Typography variant="h6" className="margin-bottom margin-top">
          {t("comments.two")}
        </Typography>
        <GdButton label={t("comments.add")} onClick={() => openCommentModal()} />
      </div>
      {projectComments.length === 0 ? (
        <CenteredContent infoText={t("comments.noneYet")} />
      ) : (
        <Table component={Paper}>
          <TableBody>
            {projectComments.map((c) => (
              <TableRow key={c.id}>
                <TableCell width="300px">
                  <Typography variant="subtitle2">
                    {t("comments.fromOn", {
                      replace: {
                        author: users.find((u) => u.id === c.authorId)?.name || "",
                        date: dateConvertToString(c.creation),
                        time: timeConvertToString(c.creation),
                      },
                    })}
                  </Typography>
                  {c.creation !== c.latestModification && c.latestModification ? (
                    <Typography variant="body2">
                      {t("comments.latest", {
                        replace: {
                          date: dateConvertToString(c.latestModification),
                          time: timeConvertToString(c.latestModification),
                        },
                      })}
                    </Typography>
                  ) : undefined}
                  {c.authorId === currentUserId ? (
                    <GdButton label={t("comments.edit")} className="margin-top" onClick={() => openCommentModal(c)} />
                  ) : undefined}
                </TableCell>
                <TableCell>
                  <Typography className="ql-editor" style={{ maxWidth: "600px" }}>
                    {/* eslint-disable-next-line react/no-danger */}
                    <span dangerouslySetInnerHTML={{ __html: c.body }} />
                  </Typography>
                </TableCell>
                {c.authorId === currentUserId ? (
                  <TableCell align="right">
                    <UploadFileButton
                      label={t("comments.addAttachment")}
                      color="secondary"
                      uploadProcess={(file: File) => uploadAttachment(c.id, file, AttachmentType.CommentAttachment)}
                      buttonId={`add-attachment-${c.id}`}
                      buttonWidth="normal"
                      className={c.attachments?.length ? "small-margin-bottom" : undefined}
                    />
                    {c.attachments?.map((a) => (
                      <div className="row">
                        <Tooltip title={a.name || ""}>
                          <IconButton
                            onClick={() => {
                              if (a.url) window.open(`${a.url}?t=${new Date().getTime()}`);
                            }}>
                            <Avatar>
                              <InsertDriveFile />
                            </Avatar>
                          </IconButton>
                        </Tooltip>
                        <span style={{ width: 32 }} />
                        {removing === a.id ? (
                          <CircularProgress size={24} />
                        ) : (
                          <Tooltip title={t("comments.removeAttachment") || ""}>
                            <IconButton
                              edge="end"
                              onClick={() => deleteAttachment(c.id, a.id)}
                              disabled={Boolean(removing)}>
                              <Delete />
                            </IconButton>
                          </Tooltip>
                        )}
                      </div>
                    ))}
                  </TableCell>
                ) : undefined}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      )}
      <Modal open={open} onClose={() => closeCommentModal()} className="project-modal-root">
        <Paper className="project-modal-paper">
          <div className="margin-bottom">
            <RichTextEditor placeholder={t("comments.body")} body={body} setBody={setBody} />
          </div>
          <GdButton
            label={t(commentId ? "comments.editThisComment" : "comments.addThisComment")}
            onClick={updateOnServer}
            isLoading={updating}
            disabled={body.length === 0}
          />
        </Paper>
      </Modal>
    </div>
  );
};

export default ProjectComments;
