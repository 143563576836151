import { TextField, Typography } from "@mui/material";
import { useSnackbar } from "notistack";
import { FC, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { SettingsContext } from "../data/contexts/SettingsContext";
import {
  formatThreshold,
  globalRateToGlobalRateInput,
  rateThresholdsToRateThresholdsInput,
} from "../data/dataConvertors";
import { GlobalRate } from "../data/generated/graphql";
import GdAlert from "../utils/GdAlert";
import GdButton from "../utils/GdButton";

interface EditRateProps {
  rate: GlobalRate | null;
  onSuccess: () => void;
}

const EditRate: FC<EditRateProps> = ({ rate, onSuccess }) => {
  const { updateRate, settings } = useContext(SettingsContext);
  const [newRateValue, setNewRateValue] = useState(rateThresholdsToRateThresholdsInput(rate?.thresholds || []));
  const [loading, setLoading] = useState(false);
  const [warningRateOpen, setWarningRateOpen] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation(["settings", "global"]);

  useEffect(() => {
    setNewRateValue(rateThresholdsToRateThresholdsInput(rate?.thresholds || []));
  }, [rate]);

  const textFields = newRateValue.map((rt, rtIndex) => (
    <TextField
      value={rt.rate}
      onChange={(e) =>
        setNewRateValue(
          newRateValue.map((nrt, nrtIndex) => ({ ...nrt, rate: nrtIndex === rtIndex ? e.target.value : nrt.rate })),
        )
      }
      variant="outlined"
      fullWidth
      label={t("newRateForThreshold", {
        replace: {
          threshold: formatThreshold(rt),
        },
      })}
      className="margin-bottom margin-top"
    />
  ));

  const minRates: number[] = [];
  const rates: number[] = [];
  const ratesString = settings.obliged
    .filter((o) => Boolean(o.rate))
    .map((o): string => {
      const emmyData = settings.emmy.slice(0, Number.parseInt(o.rate?.nbMonth || "0", 10));
      let emmyValuedVolume = 0;
      let emmyVolume = 0;
      // eslint-disable-next-line no-restricted-syntax
      for (const ed of emmyData) {
        emmyValuedVolume += Number.parseFloat(ed.rate) * Number.parseFloat(ed.volume);
        emmyVolume += Number.parseFloat(ed.volume);
      }
      const emmyRate = emmyValuedVolume / emmyVolume;
      const obligedRate = emmyRate * Number.parseFloat(o.rate?.multiplicator || "0");
      const minRate = obligedRate - Number.parseFloat(o.rate?.maxMargin || "0");
      minRates.push(minRate);
      rates.push(obligedRate);
      return `${o.shortName} : ${obligedRate.toFixed(3)} (Emmy: ${emmyRate.toFixed(3)}, Min. : ${minRate.toFixed(3)})`;
    });

  const update = async (): Promise<void> => {
    setWarningRateOpen(false);
    setLoading(true);
    const result = await updateRate(globalRateToGlobalRateInput({ ...rate, thresholds: newRateValue }));
    setLoading(false);
    if (!result) enqueueSnackbar(t("global:errorWhileUpdating"), { variant: "error" });
    else onSuccess();
  };

  const validate = async (): Promise<void> => {
    const ratesToSave = newRateValue.map((rv) => Number.parseFloat(rv.rate || "0"));
    const minObliged = Math.min(...minRates);
    const maxObliged = Math.max(...rates);
    const minNewRates = Math.min(...ratesToSave);
    const maxNewRates = Math.max(...ratesToSave);
    if (minNewRates < minObliged || maxNewRates > maxObliged) setWarningRateOpen(true);
    else update();
  };

  return (
    <div style={{ width: 350 }}>
      <Typography>{t("obligedRates")}</Typography>
      <ul style={{ marginTop: 0 }}>
        {ratesString.map((r) => (
          <li key={r}>{r}</li>
        ))}
      </ul>
      {textFields}
      <GdButton label={t("validateThisRate")} onClick={validate} isLoading={loading} fullWidth />
      <GdAlert
        title={t("global:warning")}
        body={<Typography>{t("rateIsOutsideValues")}</Typography>}
        open={Boolean(warningRateOpen)}
        onClose={() => setWarningRateOpen(false)}
        okButtonClick={update}
        cancelButton
      />
    </div>
  );
};

export default EditRate;
