import { Typography } from "@mui/material";
import { FC, useContext } from "react";
import { useTranslation } from "react-i18next";
import { ProjectsContext } from "../../data/contexts/ProjectsContext";
import { dateConvertToString } from "../../data/dataConvertors";
import {
  AttachmentType,
  Document,
  Operation,
  Project,
  Validation,
  ValidationStatus,
} from "../../data/generated/graphql";
import GdButton from "../../utils/GdButton";
import UploadFileButton from "./UploadFileButton";
import Validator from "./Validator";

interface SwornStatementProps {
  project: Project;
  operation: Operation;
  swornStatement?: Document;
  onlyOne?: boolean;
}

const SwornStatement: FC<SwornStatementProps> = ({ project, swornStatement, operation, onlyOne }) => {
  const { validateManualDocument } = useContext(ProjectsContext);
  const { t } = useTranslation(["project", "global"]);
  const ssId = onlyOne ? "solo" : operation.id;

  if (!swornStatement) {
    return (
      <div className="row space-between margin-top">
        <div>
          {!onlyOne ? (
            <Typography variant="h6" className="margin-bottom">
              {t("swornStatementTwo")} {`${operation.id} (${operation.formId})`}
            </Typography>
          ) : undefined}
          <Typography>{t("noSwornStatement")}</Typography>
        </div>
        <div className="column-right">
          <UploadFileButton
            projectId={project.id}
            label={t("initSwornStatement")}
            type={AttachmentType.SwornStatementModel}
            className="margin-top"
            buttonWidth="normal"
            buttonId={`upload-ssm-${ssId}`}
            operationId={ssId}
          />
        </div>
      </div>
    );
  }

  const swornStatementUrl = swornStatement.details?.completedFilesUrl?.[0];

  const openSwornStatement = (): void => {
    if (swornStatementUrl) window.open(`${swornStatementUrl}?t=${new Date().getTime()}`);
    else window.open(`${swornStatement?.modelUrl}?t=${new Date().getTime()}`);
  };
  const validateTheSwornStatement = (validation: Validation): Promise<boolean> =>
    validateManualDocument(project.id, AttachmentType.SwornStatement, true, validation.userId, undefined, ssId);

  const refuseSwornStatement = (validation: Validation): Promise<boolean> =>
    validateManualDocument(
      project.id,
      AttachmentType.SwornStatement,
      false,
      validation.userId,
      validation.comment as string,
      ssId,
    );

  return (
    <Validator
      validation={swornStatement?.validation}
      validateOnServer={validateTheSwornStatement}
      refuseOnServer={refuseSwornStatement}
      leftText={
        <div>
          {!onlyOne ? (
            <Typography variant="h6" className="margin-bottom">
              {t("swornStatementTwo")} {operation.id} ({operation.formId})
            </Typography>
          ) : undefined}
          <Typography>
            {!swornStatement?.details?.completedFilesUrl
              ? t("swornStatementNotUploaded")
              : !swornStatement?.validation
              ? t("checkSwornStatement")
              : t(
                  swornStatement.validation.status === ValidationStatus.Accepted
                    ? "swornStatementValidated"
                    : "swornStatementRefused",
                  {
                    replace: {
                      date: dateConvertToString(swornStatement.validation.date),
                      reason: swornStatement.validation.comment,
                    },
                  },
                )}
          </Typography>
        </div>
      }
      topButton={
        <GdButton
          label={t(swornStatementUrl ? "seeSwornStatement" : "seeSwornStatementModel")}
          color="secondary"
          onClick={openSwornStatement}
        />
      }
      projectId={project.id}
      type={AttachmentType.SwornStatement}
      hideValidationButtons={!swornStatementUrl}
      operationId={operation.id}
    />
  );
};

SwornStatement.defaultProps = { swornStatement: undefined, onlyOne: false };

export default SwornStatement;
