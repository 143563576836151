/* eslint-disable react/jsx-props-no-spreading */
import {
  CheckCircleOutline,
  Comment,
  CommentsDisabled,
  HighlightOff,
  Search,
  ThumbDown,
  ThumbUp,
} from "@mui/icons-material";
import {
  Card,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Modal,
  Paper,
  Select,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
  Typography,
} from "@mui/material";
import { FC, useContext, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import Pagination from "../../common/Pagination";
import { AssistantContext } from "../../data/contexts/AssistantContext";
import { UsersContext } from "../../data/contexts/UsersContext";
import { AssistantFilters, Claims, FeedbackConversationQa } from "../../data/generated/graphql";
import CenteredContent from "../../utils/CenteredContent";
import AiHistoryItem from "../components/AiHistoryItem";
import AiHistoryQA from "../components/AiHistoryQa";
import GdButton from "../../utils/GdButton";

const initialFilters = {
  searchTerm: "",
  deleted: false,
  userFeedback: undefined,
  userId: undefined,
  humanAnswer: undefined,
};

const AssistantLogs: FC = () => {
  const { t } = useTranslation("aiAssistant");
  const {
    conversationPages,
    getConversationHistory,
    currentConversationHistoryPage,
    pageCount,
    loading,
    latestReset,
    addFeedback,
  } = useContext(AssistantContext);
  const { users } = useContext(UsersContext);
  const [conversationToDisplay, setConversationToDisplay] = useState("");
  const [qaToAnswer, setQaToAnswer] = useState("");
  const [comment, setComment] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedUser, setSelectedUser] = useState<string>("");
  const availableUsers = users
    .filter(
      (u) =>
        Array.isArray(u.claims) &&
        u.claims?.filter((c) => c.name === Claims.AiAssistantChat).findIndex((c) => c.granted === true) !== -1,
    )
    .sort((u1, u2) => ((u1.name || "") > (u2.name || "") ? 1 : -1));
  const [filters, setFilters] = useState<AssistantFilters>(initialFilters);
  const divRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    setConversationToDisplay("");
    setFilters(initialFilters);
  }, [latestReset]);

  useEffect(() => {
    (async () => {
      await getConversationHistory(0, initialFilters);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setComment(
      conversationPages[currentConversationHistoryPage]
        ?.find((c) => c.id === conversationToDisplay)
        ?.qas?.find((q) => q.id === qaToAnswer)?.humanAnswer || "",
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [qaToAnswer]);

  const onQasChange = (id: string): void => {
    if (conversationToDisplay === id) setConversationToDisplay("");
    else {
      setConversationToDisplay(id);
    }
    if (divRef.current !== null) divRef.current.scrollTo({ top: 0, behavior: "auto" });
  };

  const getNewPage = async (newPage: number): Promise<void> => {
    setConversationToDisplay("");
    await getConversationHistory(newPage, filters);
  };

  const search = async (): Promise<void> => {
    setFilters({ ...filters, searchTerm });
    await getConversationHistory(0, { ...filters, searchTerm }, true);
    setConversationToDisplay("");
  };

  const searchDeletedConversations = async (value: string): Promise<void> => {
    const newValue = value === null ? undefined : value === "true";
    setFilters({ ...filters, deleted: newValue });
    await getConversationHistory(0, { ...filters, deleted: newValue }, true);
    setConversationToDisplay("");
  };

  const applyUserFeedbackFilter = async (userFeedback: FeedbackConversationQa | null): Promise<void> => {
    const newUserFeedback = userFeedback === null ? undefined : userFeedback;
    setFilters({ ...filters, userFeedback: newUserFeedback });
    await getConversationHistory(0, { ...filters, userFeedback: newUserFeedback }, true);
    setConversationToDisplay("");
  };

  const applyUserFilter = async (userId: string): Promise<void> => {
    setSelectedUser(userId);
    await getConversationHistory(0, { ...filters, userId }, true);
    setFilters({ ...filters, userId });
    setConversationToDisplay("");
  };

  const handleChangeFeedback = async (): Promise<void> => {
    setIsLoading(true);
    await addFeedback(conversationToDisplay, qaToAnswer, undefined, comment || "");
    setQaToAnswer("");
    setComment("");
    setIsLoading(false);
  };

  const applyHumanResponseFilter = async (value?: boolean): Promise<void> => {
    setFilters({ ...filters, humanAnswer: value });
    await getConversationHistory(0, { ...filters, humanAnswer: value }, true);
    setConversationToDisplay("");
  };

  const listMaxHeight = window.innerHeight - (64 + 2 * 24 + 2 * 24 + 56);

  return (
    <div style={{ display: "flex", flexDirection: "column", alignItems: "center", width: "1050px" }}>
      <div className="row" style={{ gap: "16px" }}>
        <TextField
          label={t("filters.search")}
          variant="outlined"
          sx={{ width: "320px" }}
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          onKeyDown={(e) => {
            if (e.key.toLowerCase() === "enter") {
              search();
            }
          }}
          InputProps={{
            endAdornment: (
              <IconButton className="circle" onClick={search} color="primary">
                <Search />
              </IconButton>
            ),
          }}
        />
        <ToggleButtonGroup
          onChange={(_, newFilter) => searchDeletedConversations(newFilter)}
          value={typeof filters.deleted === "boolean" ? (filters.deleted ? "true" : "false") : undefined}
          exclusive>
          <Tooltip arrow title={t("filters.notDeleted")}>
            <ToggleButton value="false">
              <CheckCircleOutline color={filters.deleted === false ? "success" : "inherit"} />
            </ToggleButton>
          </Tooltip>
          <Tooltip arrow title={t("filters.deleted")}>
            <ToggleButton value="true">
              <HighlightOff color={filters.deleted ? "error" : "inherit"} />
            </ToggleButton>
          </Tooltip>
        </ToggleButtonGroup>
        <ToggleButtonGroup
          onChange={(_, newFilter) => applyUserFeedbackFilter(newFilter)}
          value={filters.userFeedback}
          exclusive>
          <Tooltip arrow title={t("filters.goodFeedback")}>
            <ToggleButton value={FeedbackConversationQa.Good}>
              <ThumbUp color={filters.userFeedback === FeedbackConversationQa.Good ? "primary" : "inherit"} />
            </ToggleButton>
          </Tooltip>
          <Tooltip arrow title={t("filters.badFeedback")}>
            <ToggleButton value={FeedbackConversationQa.Bad}>
              <ThumbDown color={filters.userFeedback === FeedbackConversationQa.Bad ? "error" : "inherit"} />
            </ToggleButton>
          </Tooltip>
        </ToggleButtonGroup>
        <ToggleButtonGroup
          onChange={(_, newFilter) => applyHumanResponseFilter(newFilter)}
          value={filters.humanAnswer}
          exclusive>
          <Tooltip arrow title={t("filters.humanAnswer")}>
            <ToggleButton value>
              <Comment color={filters.humanAnswer === true ? "primary" : "inherit"} />
            </ToggleButton>
          </Tooltip>
          <Tooltip arrow title={t("filters.noHumanAnswer")}>
            <ToggleButton value={false}>
              <CommentsDisabled color={filters.humanAnswer === false ? "error" : "inherit"} />
            </ToggleButton>
          </Tooltip>
        </ToggleButtonGroup>
        <FormControl sx={{ width: "300px" }}>
          <InputLabel id="user-label">{t("filters.selectUser")}</InputLabel>
          <Select
            labelId="user-label"
            value={selectedUser}
            label={t("filters.selectUser")}
            onChange={(e) => applyUserFilter(e.target.value)}>
            <MenuItem value="">{t("filters.allUsers")}</MenuItem>
            {availableUsers.map((u) => (
              <MenuItem key={u.id} value={u.id}>
                {u.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
      {loading ? (
        <div style={{ width: "1050px", height: listMaxHeight, display: "flex", alignItems: "center" }}>
          <CenteredContent loading />
        </div>
      ) : conversationPages[currentConversationHistoryPage].length > 0 ? (
        <div style={{ width: "1050px", display: "flex" }}>
          <div
            style={{
              width: "320px",
              height: listMaxHeight,
              overflowY: "auto",
              margin: "4px",
              padding: "4px",
            }}>
            {typeof conversationPages[currentConversationHistoryPage] !== "undefined" ? (
              conversationPages[currentConversationHistoryPage].map((c) =>
                typeof c.qas !== "undefined" ? (
                  <Card
                    key={c.id}
                    sx={{
                      padding: "8px",
                      margin: "4px 0",
                      border: c.id === conversationToDisplay ? "solid black 1px" : "",
                      cursor: "pointer",
                      opacity: c.id === conversationToDisplay || conversationToDisplay.length === 0 ? "1" : ".7",
                    }}
                    onClick={() => onQasChange(c.id)}>
                    <AiHistoryItem {...c} userId={c.userId} />
                  </Card>
                ) : undefined,
              )
            ) : (
              <div style={{ margin: "auto" }}>
                <Typography variant="h6">{t("filters.searchNoResult")}</Typography>
              </div>
            )}
            <Pagination pageCount={pageCount} currentPage={currentConversationHistoryPage} getNewPage={getNewPage} />
          </div>

          {conversationToDisplay.length > 0 ? (
            <div ref={divRef} style={{ maxHeight: listMaxHeight, overflowY: "auto", width: "730px" }}>
              {conversationPages[currentConversationHistoryPage]
                .filter((ch) => ch.id === conversationToDisplay)[0]
                ?.qas?.map((qa) => (
                  <AiHistoryQA
                    {...qa}
                    key={qa.id}
                    conversationId={conversationToDisplay}
                    modifyHumanAnswer={() => setQaToAnswer(qa.id)}
                  />
                ))}
            </div>
          ) : undefined}
        </div>
      ) : (
        <>
          <Typography className="text-center margin-top">{t("assistantHistory.noConversation")}</Typography>
        </>
      )}
      <Modal open={qaToAnswer.length > 0} onClose={() => setQaToAnswer("")} className="project-modal-root">
        <Paper
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            gap: "16px",
            padding: "32px",
            width: "800px",
          }}>
          <Typography variant="h5">{t("feedback.addCommentTitle")}</Typography>
          <TextField
            id="outlined-multiline-static"
            multiline
            rows={10}
            fullWidth
            value={comment || ""}
            onChange={(e) => setComment(e.target.value)}
            className="big-margin-bottom"
          />
          <div style={{ display: "flex", justifyContent: "center", gap: "16px", width: "100%" }}>
            <GdButton label={t("cancel")} color="secondary" onClick={() => setQaToAnswer("")} />
            <GdButton label={t("global:ok")} onClick={() => handleChangeFeedback()} isLoading={isLoading} />
          </div>
        </Paper>
      </Modal>
    </div>
  );
};

export default AssistantLogs;
