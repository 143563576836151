import { useMsal } from "@azure/msal-react";
import { Button, Card, CircularProgress, Typography } from "@mui/material";
import { useSnackbar } from "notistack";
import { FC, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { UserContext } from "../../data/contexts/UserContext";
import Authenticate from "../Authenticate/Authenticate";

import "./Unknown.css";

interface UnknownProps {
  anonymous: boolean;
  forceRetryLogin: () => void;
  unauthorized?: boolean;
  userName?: string;
  error?: boolean;
}

const UnknownDefaultProps = {
  userName: "",
  unauthorized: false,
  error: false,
};

const Unknown: FC<UnknownProps> = ({ anonymous, forceRetryLogin, unauthorized, userName, error }) => {
  const [authLoading, setAuthLoading] = useState(false);
  const [isNotAdmin, setIsNotAdmin] = useState(false);
  const { createUser, mutationError } = useContext(UserContext);
  const { t } = useTranslation("global");
  const { enqueueSnackbar } = useSnackbar();
  const { instance } = useMsal();

  useEffect(() => {
    if (mutationError) {
      enqueueSnackbar(t("userCreationFailed"), { variant: "error" });
      setAuthLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mutationError]);

  const label = anonymous ? t("authenticateLabel") : t("unknownLabel", { userName: userName || "" });
  const authButton = authLoading ? (
    <Authenticate />
  ) : (
    <Button variant="contained" onClick={() => setAuthLoading(true)}>
      {t("authenticateButton")}
    </Button>
  );
  const unauthorizedButton = authLoading ? (
    <CircularProgress />
  ) : (
    <Button
      variant="contained"
      onClick={() => {
        setAuthLoading(true);
        const create = async (): Promise<void> => {
          const admin = await createUser();
          setIsNotAdmin(!admin);
          setAuthLoading(false);
        };
        create();
      }}>
      {t("unknownButton")}
    </Button>
  );

  return (
    <div className="anonymous-root">
      <Card elevation={3} className="anonymous-card">
        <img src="/logo.png" alt="Greendeed logo" className="anonymous-logo" />
        {unauthorized || isNotAdmin ? (
          <>
            <Typography variant="h5">{t("unauthorized")}</Typography>
            <Button
              variant="contained"
              onClick={() => {
                instance.logout();
              }}>
              {t("logOut")}
            </Button>
          </>
        ) : (
          <>
            <Typography variant="h5">{error ? t("unkownErrorForce") : label}</Typography>
            {error ? (
              <Button variant="contained" onClick={forceRetryLogin}>
                {t("forceRetryLogin")}
              </Button>
            ) : (
              <div style={{ position: "relative", height: 40 }}>{anonymous ? authButton : unauthorizedButton}</div>
            )}
          </>
        )}
      </Card>
    </div>
  );
};

Unknown.defaultProps = UnknownDefaultProps;

export default Unknown;
