import { Form } from "../formsTypes";

const indForms: Form[] = [
  {
    name: "Dé-stratificateur ou brasseur d'air",
    id: "IND-BA-110",
    type: "Bâtiment",
    formUrl: "https://gdceeforms.blob.core.windows.net/v61/IND-BA-110.pdf",
    fields: [
      {
        id: "hourMode",
        title: "Mode de fonctionnement du site",
        type: "choice",
        choices: [
          {
            name: "1 x 8h",
            value: "1",
          },
          {
            name: "2 x 8h",
            value: "2",
          },
          {
            name: "3 x 8h avec arrêt le week-end",
            value: "3",
          },
          {
            name: "3 x 8h sans arrêt le week-end",
            value: "4",
          },
        ],
      },
      {
        id: "type",
        title: "Type du système de chauffage du local",
        type: "choice",
        choices: [
          {
            name: "Convectif",
            value: "1",
          },
          {
            name: "Radiatif",
            value: "2",
          },
        ],
      },
      {
        id: "hourMode|type",
        title: "Montant de certificats en kWh cumac par kW selon le système de chauffage du local",
        type: "multichoice",
        choices: [
          {
            name: "1|1",
            value: "1300",
          },
          {
            name: "1|2",
            value: "470",
          },
          {
            name: "2|1",
            value: "2700",
          },
          {
            name: "2|2",
            value: "940",
          },
          {
            name: "3|1",
            value: "2900",
          },
          {
            name: "3|2",
            value: "1000",
          },
          {
            name: "4|1",
            value: "4000",
          },
          {
            name: "4|2",
            value: "1400",
          },
        ],
      },
      {
        id: "roomHeight",
        title: "Hauteur du local en mètre",
        type: "choice",
        choices: [
          {
            name: "Supérieure ou égale à 5 et inférieure à 10",
            value: ":H:1.0:H:1.1:H:1.4",
          },
          {
            name: "Supérieure ou égale à 10",
            value: ":H:2.7:H:3.1:H:3.7",
          },
        ],
      },
      {
        id: "power",
        title: "Puissance nominale totale du système de chauffage en kW",
        type: "number",
      },
    ],
    attachments: ["Note de dimensionnement"],
  },
  {
    name: "Système de récupération de chaleur sur une tour aéroréfrigérante",
    id: "IND-BA-112",
    type: "Bâtiment",
    formUrl: "https://gdceeforms.blob.core.windows.net/v61/IND-BA-112.pdf",
    fields: [
      {
        id: "hourMode",
        title: "Mode de fonctionnement du système de récupération",
        type: "choice",
        choices: [
          {
            name: "1 x 8h",
            value: "12100",
          },
          {
            name: "2 x 8h",
            value: "26700",
          },
          {
            name: "3 x 8h avec arrêt le week-end",
            value: "36400",
          },
          {
            name: "3 x 8h sans arrêt le week-end",
            value: "51000",
          },
        ],
      },
      {
        id: "power",
        title: "Puissance thermique récupérée en kW",
        type: "number",
      },
    ],
    attachments: ["Note de calcul", "Étude thermique"],
  },
  {
    name: "Lanterneaux d'éclairage zénithal (France Métropolitaine)",
    metDomTom: 1,
    id: "IND-BA-113",
    type: "Bâtiment",
    formUrl: "https://gdceeforms.blob.core.windows.net/v61/IND-BA-113.pdf",
    fields: [
      {
        id: "factor",
        title: "Montant en kWh cumac par m²",
        type: "value",
        value: ":H:3400:H:4000:H:6400",
      },
      {
        id: "area",
        title: "Aire de la projection horizontale de la surface éclairante en m²",
        type: "number",
      },
    ],
    attachments: ["Étude de dimensionnement préalable"],
  },
  {
    name: "Conduits de lumière naturelle",
    id: "IND-BA-114",
    type: "Bâtiment",
    formUrl: "https://gdceeforms.blob.core.windows.net/v61/IND-BA-114.pdf",
    fields: [
      {
        id: "ratio",
        title: "Coefficient Métropole / DOM-TOM",
        type: "value",
        value: ":MDT:1:MDT:1.5",
      },
      {
        id: "factor",
        title: "Montant en kWh cumac par m²",
        type: "value",
        value: "17100",
      },
      {
        id: "area",
        title: "Section totale S en m²",
        type: "number",
      },
    ],
    attachments: [],
  },
  {
    name: "Luminaires à modules LED",
    id: "IND-BA-116",
    type: "Bâtiment",
    formUrl: "https://gdceeforms.blob.core.windows.net/v61/IND-BA-116.pdf",
    fields: [
      {
        id: "hourMode",
        title: "Mode de fonctionnement du site",
        type: "choice",
        choices: [
          {
            name: "1 x 8h",
            value: "22",
          },
          {
            name: "2 x 8h",
            value: "36",
          },
          {
            name: "3 x 8h avec arrêt le week-end",
            value: "38",
          },
          {
            name: "3 x 8h sans arrêt le week-end",
            value: "40",
          },
        ],
      },
      {
        id: "power",
        title: "Puissance totale des luminaires à modules LED installés en W",
        type: "number",
      },
    ],
    attachments: ["Étude de dimensionnement préalable", "Qualification du professionnel ayant effectué cette étude"],
  },
  {
    name: "Chauffage décentralisé performant",
    id: "IND-BA-117",
    type: "Bâtiment",
    formUrl: "https://gdceeforms.blob.core.windows.net/v61/IND-BA-117.pdf",
    fields: [
      {
        id: "hourMode",
        title: "Mode d'occupation des locaux",
        type: "choice",
        choices: [
          {
            name: "1 x 8h",
            value: "1",
          },
          {
            name: "2 x 8h",
            value: "2.2",
          },
          {
            name: "3 x 8h avec arrêt le week-end",
            value: "3",
          },
          {
            name: "3 x 8h sans arrêt le week-end",
            value: "4.2",
          },
        ],
      },
      {
        id: "type",
        title: "Type d'appareils",
        type: "choice",
        choices: [
          {
            name: "Panneaux et tubes radiants",
            value: ":H:650:H:740:H:790",
          },
          {
            name: "Aérothermes à condensation non modulants - Générateurs d'air chaud à condensation non modulants",
            value: ":H:1300:H:1100:H:590",
          },
          {
            name: "Aérothermes à condensation modulants - Générateurs d'air chaud à condensation modulants",
            value: ":H:2100:H:1700:H:1000",
          },
        ],
      },
      {
        id: "power",
        title: "Puissance utile totale installée en kW",
        type: "number",
      },
    ],
    attachments: ["Étude de dimensionnement préalable"],
  },
  {
    name: "Isolation des murs (France d'outre-mer)",
    metDomTom: 2,
    id: "IND-EN-101",
    type: "Bâtiment",
    formUrl: "https://gdceeforms.blob.core.windows.net/v61/IND-EN-101.pdf",
    fields: [
      {
        id: "type",
        title: "Type de bâtiment",
        type: "choice",
        choices: [
          {
            name: "Bâtiment existant",
            value: "270",
          },
          {
            name: "Bâtiment neuf",
            value: "240",
          },
        ],
      },
      {
        id: "area",
        title: "Surface d'isolant posé en m²",
        type: "number",
      },
    ],
    attachments: [],
  },
  {
    name: "Isolation de combles ou de toitures (France d'outre-mer)",
    metDomTom: 2,
    id: "IND-EN-102",
    type: "Bâtiment",
    formUrl: "https://gdceeforms.blob.core.windows.net/v61/IND-EN-102.pdf",
    fields: [
      {
        id: "type",
        title: "Type de bâtiment",
        type: "choice",
        choices: [
          {
            name: "Bâtiment existant",
            value: "1600",
          },
          {
            name: "Bâtiment neuf",
            value: "1400",
          },
        ],
      },
      {
        id: "area",
        title: "Surface d'isolant posé en m²",
        type: "number",
      },
    ],
    attachments: [],
  },
  {
    name: "Système de variation électronique de vitesse sur un moteur asynchrone",
    id: "IND-UT-102",
    type: "Moteur",
    formUrl: "https://gdceeforms.blob.core.windows.net/v61/IND-UT-102.pdf",
    fields: [
      {
        id: "application",
        title: "Application",
        type: "choice",
        choices: [
          {
            name: "Pompage",
            value: "12400",
          },
          {
            name: "Ventilation",
            value: "12200",
          },
          {
            name: "Compresseur d'air",
            value: "11900",
          },
          {
            name: "Compresseur frigorifique",
            value: "7100",
          },
          {
            name: "Autres applications",
            value: "5500",
          },
        ],
      },
      {
        id: "power",
        title: "Puissance nominale du moteur en kW",
        type: "number",
      },
    ],
    attachments: [],
  },
  {
    name: "Système de récupération de chaleur sur un compresseur d'air",
    id: "IND-UT-103",
    type: "Air comprimé",
    formUrl: "https://gdceeforms.blob.core.windows.net/v61/IND-UT-103.pdf",
    fields: [
      {
        id: "hourMode",
        title: "Mode de fonctionnement du site",
        type: "choice",
        choices: [
          {
            name: "1 x 8h",
            value: "1",
          },
          {
            name: "2 x 8h",
            value: "2",
          },
          {
            name: "3 x 8h avec arrêt le week-end",
            value: "3",
          },
          {
            name: "3 x 8h sans arrêt le week-end",
            value: "4",
          },
        ],
      },
      {
        id: "usage",
        title: "Usage de la chaleur",
        type: "choice",
        choices: [
          {
            name: "Chauffage de locaux ou eau chaude sanitaire",
            value: "1",
          },
          {
            name: "Procédé industriel",
            value: "2",
          },
        ],
      },
      {
        id: "hourMode|usage",
        title: "Montant de certificats en kWh cumac par kW selon la zone climatique",
        type: "multichoice",
        choices: [
          {
            name: "1|1",
            value: ":H:6400:H:6000:H:5000",
          },
          {
            name: "1|2",
            value: "10300",
          },
          {
            name: "2|1",
            value: ":H:15900:H:15000:H:12600",
          },
          {
            name: "2|2",
            value: "25600",
          },
          {
            name: "3|1",
            value: ":H:19700:H:18600:H:15600",
          },
          {
            name: "3|2",
            value: "31800",
          },
          {
            name: "4|1",
            value: ":H:26700:H:25200:H:21100",
          },
          {
            name: "4|2",
            value: "43100",
          },
        ],
      },
      {
        id: "power",
        title:
          "Puissance de l'échangeur en kW (thermique) ou Puissance du compresseur en kW (électrique) en l'absence d'échangeur",
        type: "number",
      },
    ],
    attachments: [],
  },
  {
    name: "Économiseur sur les effluents gazeux d'une chaudière de production de vapeur",
    id: "IND-UT-104",
    type: "Thermique",
    formUrl: "https://gdceeforms.blob.core.windows.net/v61/IND-UT-104.pdf",
    fields: [
      {
        id: "hourMode",
        title: "Mode de fonctionnement du site",
        type: "choice",
        choices: [
          {
            name: "1 x 8h",
            value: "330",
          },
          {
            name: "2 x 8h",
            value: "720",
          },
          {
            name: "3 x 8h avec arrêt le week-end",
            value: "990",
          },
          {
            name: "3 x 8h sans arrêt le week-end",
            value: "1400",
          },
        ],
      },
      {
        id: "power",
        title: "Puissance utile nominale de la chaudière en kW",
        type: "number",
      },
    ],
    attachments: [],
  },
  {
    name: "Brûleur micromodulant sur chaudière industrielle",
    id: "IND-UT-105",
    type: "Thermique",
    formUrl: "https://gdceeforms.blob.core.windows.net/v61/IND-UT-105.pdf",
    fields: [
      {
        id: "hourMode",
        title: "Mode de fonctionnement du site",
        type: "choice",
        choices: [
          {
            name: "1 x 8h",
            value: "550",
          },
          {
            name: "2 x 8h",
            value: "1200",
          },
          {
            name: "3 x 8h avec arrêt le week-end",
            value: "1600",
          },
          {
            name: "3 x 8h sans arrêt le week-end",
            value: "2300",
          },
        ],
      },
      {
        id: "power",
        title: "Puissance utile nominale de la chaudière en kW",
        type: "number",
      },
    ],
    attachments: [],
  },
  /* // IND-UT-112 abrogée par l'arrêté du 17/12/2021
  {
    name: "Moteur haut rendement de classe IE2",
    id: "IND-UT-112",
    type: "Moteur",
    formUrl: "https://gdceeforms.blob.core.windows.net/v61/IND-UT-112.pdf",
    fields: [
      {
        id: "power",
        title: "Puissance nominale du moteur en kW",
        type: "number",
        skipValue: true,
        minValue: 0.12,
        maxValue: 1000,
      },
      {
        id: "formula",
        type: "formula",
        title: "Calcul",
        value: "INDUT112",
      },
    ],
    attachments: [],
  }, */
  {
    name: "Système de condensation frigorifique à haute efficacité",
    id: "IND-UT-113",
    type: "Froid",
    formUrl: "https://gdceeforms.blob.core.windows.net/v61/IND-UT-113.pdf",
    fields: [
      {
        id: "hourMode",
        title: "Mode de fonctionnement du site",
        type: "choice",
        choices: [
          {
            name: "1 x 8h",
            value: "1",
          },
          {
            name: "2 x 8h",
            value: "2.2",
          },
          {
            name: "3 x 8h avec arrêt le week-end",
            value: "3",
          },
          {
            name: "3 x 8h sans arrêt le week-end",
            value: "4.2",
          },
        ],
      },
      {
        id: "type",
        title: "Type du système de condensation frigorifique",
        type: "choice",
        choices: [
          {
            name: "Condenseur à eau seul (sur nappe, cours d'eau ou autre)",
            value: "1",
          },
          {
            name: "Condenseur à air sec (adiabatique ou non)",
            value: "2_1",
          },
          {
            name: "Aéroréfrigérant à air sec (adiabatique ou non) et condenseur à eau",
            value: "2_2",
          },
          {
            name: "Condenseur évaporatif (hybride ou non)",
            value: "3_1",
          },
          {
            name: "Tour aéroréfrigérante ouverte (hybride ou non) et condenseur à eau",
            value: "3_2",
          },
          {
            name: "Tour aéroréfrigérante fermée (hybride ou non) et condenseur à eau",
            value: "3_3",
          },
        ],
      },
      {
        id: "deltaTemp",
        title: "∆T en °C",
        type: "range",
        choiceRanges: {
          fieldId: "type",
          valuesRanges: [
            {
              fieldValue: "1",
              range: "8>6",
            },
            {
              fieldValue: "2",
              range: "12>0",
            },
            {
              fieldValue: "3",
              range: "22>10",
            },
          ],
        },
      },
      {
        id: "type|deltaTemp",
        title: "Montant de certificats en kWh cumac par kW selon la zone climatique",
        type: "multichoice",
        choices: [
          {
            name: "1|8",
            value: "680",
          },
          {
            name: "1|7",
            value: "1000",
          },
          {
            name: "1|6",
            value: "1400",
          },
          {
            name: "2|12",
            value: "790",
          },
          {
            name: "2|11",
            value: "1100",
          },
          {
            name: "2|10",
            value: "1400",
          },
          {
            name: "2|9",
            value: "1700",
          },
          {
            name: "2|8",
            value: "2000",
          },
          {
            name: "2|7",
            value: "2400",
          },
          {
            name: "2|6",
            value: "2800",
          },
          {
            name: "2|5",
            value: "3100",
          },
          {
            name: "2|4",
            value: "3600",
          },
          {
            name: "2|3",
            value: "4000",
          },
          {
            name: "2|2",
            value: "4400",
          },
          {
            name: "2|1",
            value: "4900",
          },
          {
            name: "2|0",
            value: "5400",
          },
          {
            name: "3|22",
            value: "790",
          },
          {
            name: "3|21",
            value: "1100",
          },
          {
            name: "3|20",
            value: "1400",
          },
          {
            name: "3|19",
            value: "1700",
          },
          {
            name: "3|18",
            value: "2000",
          },
          {
            name: "3|17",
            value: "2400",
          },
          {
            name: "3|16",
            value: "2800",
          },
          {
            name: "3|15",
            value: "3100",
          },
          {
            name: "3|14",
            value: "3600",
          },
          {
            name: "3|13",
            value: "4000",
          },
          {
            name: "3|12",
            value: "4400",
          },
          {
            name: "3|11",
            value: "4900",
          },
          {
            name: "3|10",
            value: "5400",
          },
        ],
      },
      {
        id: "power2",
        title: "Puissance électrique nominale de l’installation frigorifique en kW",
        type: "number",
      },
    ],
    attachments: [],
    startDate: "",
    history: [
      {
        name: "Système de condensation frigorifique à haute efficacité",
        id: "IND-UT-113",
        type: "Froid",
        formUrl: "https://gdceeforms.blob.core.windows.net/v61/IND-UT-113.pdf",
        fields: [
          {
            id: "hourMode",
            title: "Mode de fonctionnement du site",
            type: "choice",
            choices: [
              {
                name: "1 x 8h",
                value: "1",
              },
              {
                name: "2 x 8h",
                value: "2.2",
              },
              {
                name: "3 x 8h avec arrêt le week-end",
                value: "3",
              },
              {
                name: "3 x 8h sans arrêt le week-end",
                value: "4.2",
              },
            ],
          },
          {
            id: "type",
            title: "Type du système de condensation frigorifique",
            type: "choice",
            choices: [
              {
                name: "Condenseur à eau seul (sur nappe, cours d'eau ou autre)",
                value: "1",
              },
              {
                name: "Condenseur à air sec (adiabatique ou non)",
                value: "2_1",
              },
              {
                name: "Aéroréfrigérant à air sec (adiabatique ou non) et condenseur à eau",
                value: "2_2",
              },
              {
                name: "Condenseur évaporatif (hybride ou non)",
                value: "3_1",
              },
              {
                name: "Tour aéroréfrigérante ouverte (hybride ou non) et condenseur à eau",
                value: "3_2",
              },
              {
                name: "Tour aéroréfrigérante fermée (hybride ou non) et condenseur à eau",
                value: "3_3",
              },
            ],
          },
          {
            id: "deltaTemp",
            title: "∆T en °C",
            type: "range",
            choiceRanges: {
              fieldId: "type",
              valuesRanges: [
                {
                  fieldValue: "1",
                  range: "8>6",
                },
                {
                  fieldValue: "2",
                  range: "12>0",
                },
                {
                  fieldValue: "3",
                  range: "22>10",
                },
              ],
            },
          },
          {
            id: "type|deltaTemp",
            title: "Montant de certificats en kWh cumac par kW selon la zone climatique",
            type: "multichoice",
            choices: [
              {
                name: "1|8",
                value: "680",
              },
              {
                name: "1|7",
                value: "1000",
              },
              {
                name: "1|6",
                value: "1400",
              },
              {
                name: "2|12",
                value: "790",
              },
              {
                name: "2|11",
                value: "1100",
              },
              {
                name: "2|10",
                value: "1400",
              },
              {
                name: "2|9",
                value: "1700",
              },
              {
                name: "2|8",
                value: "2000",
              },
              {
                name: "2|7",
                value: "2400",
              },
              {
                name: "2|6",
                value: "2800",
              },
              {
                name: "2|5",
                value: "3100",
              },
              {
                name: "2|4",
                value: "3600",
              },
              {
                name: "2|3",
                value: "4000",
              },
              {
                name: "2|2",
                value: "4400",
              },
              {
                name: "2|1",
                value: "4900",
              },
              {
                name: "2|0",
                value: "5400",
              },
              {
                name: "3|22",
                value: "790",
              },
              {
                name: "3|21",
                value: "1100",
              },
              {
                name: "3|20",
                value: "1400",
              },
              {
                name: "3|19",
                value: "1700",
              },
              {
                name: "3|18",
                value: "2000",
              },
              {
                name: "3|17",
                value: "2400",
              },
              {
                name: "3|16",
                value: "2800",
              },
              {
                name: "3|15",
                value: "3100",
              },
              {
                name: "3|14",
                value: "3600",
              },
              {
                name: "3|13",
                value: "4000",
              },
              {
                name: "3|12",
                value: "4400",
              },
              {
                name: "3|11",
                value: "4900",
              },
              {
                name: "3|10",
                value: "5400",
              },
            ],
          },
          {
            id: "power",
            title: "Puissance électrique nominale de l'installation frigorifique en kW",
            type: "number",
          },
        ],
        attachments: [],
      },
    ],
  },
  {
    name: "Moto-variateur synchrone à aimants permanents ou à reluctance",
    id: "IND-UT-114",
    type: "Moteur",
    formUrl: "https://gdceeforms.blob.core.windows.net/v61/IND-UT-114.pdf",
    fields: [
      {
        id: "application",
        title: "Application",
        type: "choice",
        choices: [
          {
            name: "Pompage",
            value: "17800",
          },
          {
            name: "Ventilation",
            value: "17600",
          },
          {
            name: "Compresseur d'air",
            value: "9200",
          },
          {
            name: "Compresseur frigorifique",
            value: "14500",
          },
          {
            name: "Autre application",
            value: "11400",
          },
        ],
      },
      {
        id: "power",
        title: "Puissance nominale du moto-variateur en kW",
        type: "number",
      },
    ],
    attachments: [],
  },
  {
    name: "Système de régulation sur un groupe de production de froid permettant d'avoir une basse pression flottante",
    id: "IND-UT-115",
    type: "Froid",
    formUrl: "https://gdceeforms.blob.core.windows.net/v61/IND-UT-115.pdf",
    fields: [
      {
        id: "power2",
        title: "Puissance électrique nominale du groupe de production de froid en kW",
        type: "number",
      },
      {
        id: "ratio",
        title: "Montant en kWh cumac par kW",
        type: "value",
        value: "1500",
      },
    ],
    attachments: [],
    startDate: "2024-08-22T01:00:00.000Z",
    history: [
      {
        name: "Système de régulation sur un groupe de production de froid permettant d'avoir une basse pression flottante",
        id: "IND-UT-115",
        type: "Froid",
        formUrl: "https://www.ecologie.gouv.fr/sites/default/files/IND-UT-115.pdf",
        fields: [
          {
            id: "power",
            title: "Puissance électrique nominale du ou des compresseur(s) en kW",
            type: "number",
          },
          {
            id: "ratio",
            title: "Montant en kWh cumac par kW",
            type: "value",
            value: "1500",
          },
        ],
        attachments: [],
      },
    ],
  },
  {
    name: "Système de régulation sur un groupe de production de froid permettant d'avoir une haute pression flottante",
    id: "IND-UT-116",
    type: "Froid",
    formUrl: "https://gdceeforms.blob.core.windows.net/v61/IND-UT-116.pdf",
    fields: [
      {
        id: "type",
        title: "Type de condensation",
        type: "choice",
        choices: [
          {
            name: "Condensation par rapport à l'atmosphère",
            value: ":H:14300:H:14300:H:13500",
          },
          {
            name: "Condensation à eau seule",
            value: ":H:11700:H:11700:H:10400",
          },
        ],
      },
      {
        id: "power2",
        title: "Puissance électrique nominale du groupe de production de froid en kW",
        type: "number",
      },
    ],
    attachments: [],
    startDate: "2024-08-22T01:00:00.000Z",
    history: [
      {
        name: "Système de régulation sur un groupe de production de froid permettant d'avoir une haute pression flottante",
        id: "IND-UT-116",
        type: "Froid",
        formUrl: "https://gdceeforms.blob.core.windows.net/v61/IND-UT-116.pdf",
        fields: [
          {
            id: "type",
            title: "Type de condensation",
            type: "choice",
            choices: [
              {
                name: "Condensation par rapport à l'atmosphère",
                value: ":H:14300:H:14300:H:13500",
              },
              {
                name: "Condensation à eau seule",
                value: ":H:11700:H:11700:H:10400",
              },
            ],
          },
          {
            id: "power",
            title: "Puissance électrique nominale du ou des compresseur(s) en kW",
            type: "number",
          },
        ],
        attachments: [],
      },
    ],
  },
  {
    name: "Système de récupération de chaleur sur un groupe de production de froid",
    id: "IND-UT-117",
    type: "Froid",
    formUrl: "https://gdceeforms.blob.core.windows.net/v61/IND-UT-117.pdf",
    fields: [
      {
        id: "useDuration",
        title: "Durée annuelle d'utilisation de la chaleur récupérée (D), en heures",
        type: "number",
      },
      {
        id: "factor",
        title: "Facteur multiplicatif",
        type: "value",
        value: "9.9",
      },
      {
        id: "thermalPower",
        title: "Puissance thermique récupérée en kW",
        type: "number",
      },
    ],
    attachments: ["Étude de dimensionnement préalable"],
  },
  {
    name: "Brûleur avec dispositif de récupération de chaleur sur un four industriel",
    id: "IND-UT-118",
    type: "Thermique",
    formUrl: "https://gdceeforms.blob.core.windows.net/v61/IND-UT-118.pdf",
    fields: [
      {
        id: "type",
        type: "choice",
        title: "Type d'opération",
        skipValue: true,
        choices: [
          {
            name: "Mise en place d'un ou plusieurs brûleurs auto-récupérateurs",
            value: "1",
          },
          {
            name: "Mise en place d'un ou plusieurs brûleurs régénératifs (autorégénératif ou paire de brûleurs régénératifs)",
            value: "2",
          },
          {
            name: "Transformation d'un ou plusieurs brûleurs existants par l'installation d'un récupérateur de chaleur sur les fumées du four pour préchauffer l'air comburant",
            value: "3",
          },
        ],
      },
      {
        id: "temperature",
        type: "choice",
        title: "Température des fumées T à la sortie du four en °C",
        showIf: {
          fieldId: "type",
          fieldValues: ["1", "2"],
        },
        choices: [
          {
            name: "600 ≤ T ≤ 750",
            value: "1",
          },
          {
            name: "750 < T ≤ 1000",
            value: "2",
          },
          {
            name: "1000 < T ≤ 1250",
            value: "3",
          },
          {
            name: "1250 < T",
            value: "4",
          },
        ],
      },
      {
        id: "type|temperature",
        title: "Montant en kWh cumac par kW",
        type: "multichoice",
        showIf: {
          fieldId: "type",
          fieldValues: ["1", "2"],
        },
        choices: [
          {
            name: "1|1",
            value: "1600",
          },
          {
            name: "1|2",
            value: "2500",
          },
          {
            name: "1|3",
            value: "4100",
          },
          {
            name: "1|4",
            value: "5800",
          },
          {
            name: "2|1",
            value: "2300",
          },
          {
            name: "2|2",
            value: "3500",
          },
          {
            name: "2|3",
            value: "5600",
          },
          {
            name: "2|4",
            value: "7800",
          },
        ],
      },
      {
        id: "hourMode12",
        title: "Mode de fonctionnement du site",
        type: "choice",
        showIf: {
          fieldId: "type",
          fieldValues: ["1", "2"],
        },
        choices: [
          {
            name: "1 x 8h",
            value: "1",
          },
          {
            name: "2 x 8h",
            value: "2.2",
          },
          {
            name: "3 x 8h avec arrêt le week-end",
            value: "3",
          },
          {
            name: "3 x 8h sans arrêt le week-end",
            value: "4.2",
          },
        ],
      },
      {
        id: "hourMode3",
        title: "Mode de fonctionnement du site",
        type: "choice",
        showIf: {
          fieldId: "type",
          fieldValues: ["3"],
        },
        choices: [
          {
            name: "1 x 8h",
            value: "1000",
          },
          {
            name: "2 x 8h",
            value: "2300",
          },
          {
            name: "3 x 8h avec arrêt le week-end",
            value: "3100",
          },
          {
            name: "3 x 8h sans arrêt le week-end",
            value: "4300",
          },
        ],
      },
      {
        id: "thermalPower",
        title: "Somme des puissances thermiques nominales des brûleurs en kW",
        type: "number",
      },
    ],
    attachments: [],
  },
  {
    name: "Compresseur d'air basse pression à vis ou centrifuge",
    id: "IND-UT-120",
    type: "Air comprimé",
    formUrl: "https://gdceeforms.blob.core.windows.net/v61/IND-UT-120.pdf",
    fields: [
      {
        id: "power",
        title: "Puissance électrique nominale du compresseur d'air en kW",
        type: "number",
      },
      {
        id: "ratio",
        title: "Montant en kWh cumac par kW",
        type: "value",
        value: "19300",
      },
    ],
    attachments: [],
  },
  {
    name: "Isolation de points singuliers d'un réseau",
    id: "IND-UT-121",
    type: "Thermique",
    formUrl: "https://gdceeforms.blob.core.windows.net/v61/IND-UT-121.pdf",
    fields: [
      {
        id: "hourMode",
        title: "Mode de fonctionnement du site",
        type: "choice",
        choices: [
          {
            name: "1 x 8h",
            value: "1",
          },
          {
            name: "2 x 8h",
            value: "2",
          },
          {
            name: "3 x 8h avec arrêt le week-end",
            value: "3",
          },
          {
            name: "3 x 8h sans arrêt le week-end",
            value: "4",
          },
        ],
      },
      {
        id: "nature",
        title: "Nature du réseau",
        type: "choice",
        choices: [
          {
            name: "Vapeur",
            value: "1",
          },
          {
            name: "Eau chaude ou retour de condensats en circuit fermé",
            value: "2",
          },
          {
            name: "Eau surchauffée",
            value: "3",
          },
          {
            name: "Fluide organique",
            value: "4",
          },
        ],
      },
      {
        id: "hourMode|nature",
        title: "Montant en kWh cumac par matelas d'isolation mis en place et selon la nature du réseau",
        type: "multichoice",
        choices: [
          { name: "1|1", value: "13100" },
          { name: "1|2", value: "2600" },
          { name: "1|3", value: "5100" },
          { name: "1|4", value: "14000" },
          { name: "2|1", value: "28800" },
          { name: "2|2", value: "5700" },
          { name: "2|3", value: "11200" },
          { name: "2|4", value: "30900" },
          { name: "3|1", value: "39300" },
          { name: "3|2", value: "7800" },
          { name: "3|3", value: "15200" },
          { name: "3|4", value: "42100" },
          { name: "4|1", value: "55000" },
          { name: "4|2", value: "10900" },
          { name: "4|3", value: "21300" },
          { name: "4|4", value: "59000" },
        ],
      },
      {
        id: "numberUnits",
        title: "Nombre de matelas d'isolation mis en place",
        type: "number",
      },
    ],
    attachments: ["État récapitulatif", "Justification de l'accréditation de l'organisme d'inspection"],
  },
  {
    name: "Sécheur d'air comprimé à adsorption utilisant un apport calorifique pour sa régénération",
    id: "IND-UT-122",
    type: "Air comprimé",
    formUrl: "https://gdceeforms.blob.core.windows.net/v61/IND-UT-122.pdf",
    fields: [
      {
        id: "hourMode",
        title: "Mode de fonctionnement du site",
        type: "choice",
        choices: [
          {
            name: "1 x 8h",
            value: "2300",
          },
          {
            name: "2 x 8h",
            value: "5000",
          },
          {
            name: "3 x 8h avec arrêt le week-end",
            value: "6800",
          },
          {
            name: "3 x 8h sans arrêt le week-end",
            value: "9500",
          },
        ],
      },
      {
        id: "power",
        title: "Puissance électrique nominale des compresseurs en kW",
        type: "number",
      },
    ],
    attachments: [],
  },
  /* // IND-UT-123 abrogée par l'arrêté du 25/07/2022
  {
    name: "Moteur premium de classe IE3",
    id: "IND-UT-123",
    type: "Moteur",
    formUrl: "https://gdceeforms.blob.core.windows.net/v61/IND-UT-123.pdf",
    fields: [
      {
        id: "power",
        title: "Puissance nominale du moteur en kW",
        type: "number",
        skipValue: true,
        minValue: 0.12,
        maxValue: 1000,
      },
      {
        id: "formula",
        type: "formula",
        title: "Calcul",
        value: "INDUT123",
      },
    ],
    attachments: [],
  }, */
  {
    name: "Séquenceur électronique pour le pilotage d'une centrale de production d'air comprimé",
    id: "IND-UT-124",
    type: "Air comprimé",
    formUrl: "https://gdceeforms.blob.core.windows.net/v61/IND-UT-124.pdf",
    fields: [
      {
        id: "numberOfCompressors",
        title: "Nombre de compresseurs pilotés",
        type: "choice",
        choices: [
          {
            name: "2",
            value: "2",
          },
          {
            name: "3",
            value: "3",
          },
          {
            name: "4",
            value: "4",
          },
          {
            name: "5",
            value: "5",
          },
          {
            name: "6",
            value: "6",
          },
          {
            name: "7",
            value: "7",
          },
          {
            name: "8",
            value: "8",
          },
        ],
      },
      {
        id: "type",
        title: "Type de séquenceur",
        type: "choice",
        choices: [
          {
            name: "Sans optimisation d'énergie",
            value: "1",
          },
          {
            name: "Avec optimisation d'énergie",
            value: "2",
          },
        ],
      },
      {
        id: "numberOfCompressors|type",
        title: "Montant en kWh cumac par kW",
        type: "multichoice",
        choices: [
          {
            name: "2|1",
            value: "560",
          },
          {
            name: "2|2",
            value: "2400",
          },
          {
            name: "3|1",
            value: "1100",
          },
          {
            name: "3|2",
            value: "3000",
          },
          {
            name: "4|1",
            value: "1700",
          },
          {
            name: "4|2",
            value: "3600",
          },
          {
            name: "5|1",
            value: "2200",
          },
          {
            name: "5|2",
            value: "4100",
          },
          {
            name: "6|1",
            value: "2700",
          },
          {
            name: "6|2",
            value: "4600",
          },
          {
            name: "7|1",
            value: "3200",
          },
          {
            name: "7|2",
            value: "5100",
          },
          {
            name: "8|1",
            value: "3700",
          },
          {
            name: "8|2",
            value: "5600",
          },
        ],
      },
      {
        id: "power",
        title: "Puissance électrique nominale totale des compresseurs pilotés en kW",
        type: "number",
      },
    ],
    attachments: [],
  },
  {
    name: "Traitement d'eau performant sur chaudière de production de vapeur",
    id: "IND-UT-125",
    type: "Thermique",
    formUrl: "https://gdceeforms.blob.core.windows.net/v61/IND-UT-125.pdf",
    fields: [
      {
        id: "hourMode",
        title: "Mode de fonctionnement du site",
        type: "choice",
        choices: [
          {
            name: "1 x 8h",
            value: ":Z:70:Z:230:Z:460:Z:650",
          },
          {
            name: "2 x 8h",
            value: ":Z:160:Z:520:Z:1000:Z:1400",
          },
          {
            name: "3 x 8h avec arrêt le week-end",
            value: ":Z:220:Z:700:Z:1400:Z:1900",
          },
          {
            name: "3 x 8h sans arrêt le week-end",
            value: ":Z:300:Z:990:Z:2000:Z:2700",
          },
        ],
      },
      {
        id: "power",
        title: "Puissance des chaudières en kW",
        type: "number",
      },
    ],
    attachments: [],
  },
  {
    name: "Système de transmission performant",
    id: "IND-UT-127",
    type: "Moteur",
    formUrl: "https://gdceeforms.blob.core.windows.net/v61/IND-UT-127.pdf",
    fields: [
      {
        id: "transmission",
        title: "Type de transmission",
        type: "choice",
        choices: [
          { name: "Par poulie et courroie synchrone", value: "820" },
          { name: "Directe", value: "1900" },
        ],
      },
      {
        id: "power",
        title: "Puissance électrique nominale du moteur associé en kW",
        type: "number",
      },
    ],
    attachments: [],
  },
  {
    name: "Presse à injecter tout électrique ou hybride",
    id: "IND-UT-129",
    type: "Autres",
    formUrl: "https://gdceeforms.blob.core.windows.net/v61/IND-UT-129.pdf",
    fields: [
      {
        id: "hourMode",
        title: "Mode de fonctionnement du site",
        type: "choice",
        choices: [
          {
            name: "1 x 8h",
            value: "1",
          },
          {
            name: "2 x 8h",
            value: "2",
          },
          {
            name: "3 x 8h avec arrêt le week-end",
            value: "3",
          },
          {
            name: "3 x 8h sans arrêt le week-end",
            value: "4",
          },
        ],
      },
      {
        id: "type",
        title: "Type d'opération",
        type: "choice",
        choices: [
          {
            name: "Mise en place d'une presse à injecter tout électrique",
            value: "1",
          },
          {
            name: "Mise en place d'une presse à injecter hybride 2",
            value: "2",
          },
          {
            name: "Mise en place d'une presse à injecter hybride 1",
            value: "3",
          },
          {
            name: "Transformation d'une presse à injecter hydraulique existante en presse à injecter hybride 2 par l'installation d'un kit d'hybridation",
            value: "4",
          },
          {
            name: "Transformation d'une presse à injecter hydraulique existante en presse à injecter hybride 1 par l'installation d'un kit d'hybridation",
            value: "5",
          },
        ],
      },
      {
        id: "hourMode|type",
        title: "Montant en kWh cumac par matelas d'isolation mis en place et selon la nature du réseau",
        type: "multichoice",
        choices: [
          {
            name: "1|1",
            value: "12000",
          },
          {
            name: "1|2",
            value: "7700",
          },
          {
            name: "1|3",
            value: "4500",
          },
          {
            name: "1|4",
            value: "4200",
          },
          {
            name: "1|5",
            value: "2800",
          },
          {
            name: "2|1",
            value: "26300",
          },
          {
            name: "2|2",
            value: "17000",
          },
          {
            name: "2|3",
            value: "9900",
          },
          {
            name: "2|4",
            value: "9200",
          },
          {
            name: "2|5",
            value: "6200",
          },
          {
            name: "3|1",
            value: "36000",
          },
          {
            name: "3|2",
            value: "23100",
          },
          {
            name: "3|3",
            value: "13500",
          },
          {
            name: "3|4",
            value: "12600",
          },
          {
            name: "3|5",
            value: "8400",
          },
          {
            name: "4|1",
            value: "50300",
          },
          {
            name: "4|2",
            value: "32400",
          },
          {
            name: "4|3",
            value: "18900",
          },
          {
            name: "4|4",
            value: "17600",
          },
          {
            name: "4|5",
            value: "11700",
          },
        ],
      },
      {
        id: "power",
        title: "Puissance électrique nominale de la presse en kW",
        type: "number",
      },
    ],
    attachments: [],
  },
  {
    name: "Condenseur sur les effluents gazeux d'une chaudière de production de vapeur",
    id: "IND-UT-130",
    type: "Thermique",
    formUrl: "https://gdceeforms.blob.core.windows.net/v61/IND-UT-130.pdf",
    fields: [
      {
        id: "hourMode",
        title: "Mode de fonctionnement du site",
        type: "choice",
        choices: [
          {
            name: "1 x 8h",
            value: "340",
          },
          {
            name: "2 x 8h",
            value: "740",
          },
          {
            name: "3 x 8h avec arrêt le week-end",
            value: "1000",
          },
          {
            name: "3 x 8h sans arrêt le week-end",
            value: "1400",
          },
        ],
      },
      {
        id: "power",
        title: "Puissance utile nominale de la chaudière en kW",
        type: "number",
      },
    ],
    attachments: [],
  },
  {
    name: "Isolation thermique des parois planes ou cylindriques sur des installations industrielles (France métropolitaine)",
    metDomTom: 1,
    id: "IND-UT-131",
    type: "Autres",
    formUrl: "https://gdceeforms.blob.core.windows.net/v61/IND-UT-131.pdf",
    fields: [
      {
        id: "hourMode",
        title: "Mode de fonctionnement du site",
        type: "choice",
        choices: [
          {
            name: "1 x 8h",
            value: "1",
          },
          {
            name: "2 x 8h",
            value: "2.2",
          },
          {
            name: "3 x 8h avec arrêt le week-end",
            value: "3",
          },
          {
            name: "3 x 8h sans arrêt le week-end",
            value: "4.2",
          },
        ],
      },
      {
        id: "temperature",
        title: "Température du fluide (°C)",
        type: "choice",
        choices: [
          {
            name: "-80°C < T ≤ -10°C",
            value: "1",
          },
          {
            name: "-10°C < T ≤ 10°C",
            value: "2",
          },
          {
            name: "40°C < T ≤ 100°C",
            value: "3",
          },
          {
            name: "100°C < T ≤ 300°C",
            value: "4",
          },
          {
            name: "T > 300°C",
            value: "5",
          },
        ],
      },
      {
        id: "type",
        title: "Type d'isolation",
        type: "choice",
        choices: [
          {
            name: "Isolation thermique sur une tuyauterie ou un équipement cylindrique de diamètre inférieur à 508 mm",
            value: "1",
          },
          {
            name: "Isolation thermique sur une surface plane, une tuyauterie ou un équipement cylindrique de diamètre supérieur ou égal à 508 mm",
            value: "2",
          },
        ],
      },
      {
        id: "temperature|type",
        title: "Montant de certificats en kWh cumac par kW selon le type d'isolation thermique",
        type: "multichoice",
        choices: [
          {
            name: "1|1",
            value: "300",
          },
          {
            name: "1|2",
            value: "450",
          },
          {
            name: "2|1",
            value: "180",
          },
          {
            name: "2|2",
            value: "400",
          },
          {
            name: "3|1",
            value: "1050",
          },
          {
            name: "3|2",
            value: "1300",
          },
          {
            name: "4|1",
            value: "1900",
          },
          {
            name: "4|2",
            value: "2050",
          },
          {
            name: "5|1",
            value: "1850",
          },
          {
            name: "5|2",
            value: "1850",
          },
        ],
      },
      {
        id: "length",
        type: "number",
        titles: [
          {
            fieldId: "type",
            fieldValue: "1",
            title: "Longueur isolée en m",
          },
          {
            fieldId: "type",
            fieldValue: "2",
            title: "Surface d'isolant posé en m²",
          },
        ],
        showIf: { fieldId: "type", fieldValues: ["1", "2"] },
      },
    ],
    attachments: ["Document issu du fabricant", "État récapitulatif"],
  },
  {
    name: "Moteur asynchrone de classe IE4",
    id: "IND-UT-132",
    type: "Moteur",
    formUrl: "https://gdceeforms.blob.core.windows.net/v61/IND-UT-132.pdf",
    fields: [
      {
        id: "power",
        title: "Puissance utile du moteur en kW",
        type: "number",
        skipValue: true,
        minValue: 0.12,
        maxValue: 1000,
      },
      {
        id: "formula",
        title: "Montants en kWh cumac par kW",
        type: "formula",
        value: "INDUT132",
      },
    ],
    attachments: [],
  },
  {
    name: "Système électronique de pilotage d'un moteur électrique avec récupération d'énergie",
    id: "IND-UT-133",
    type: "Moteur",
    formUrl: "https://gdceeforms.blob.core.windows.net/v61/IND-UT-133.pdf",
    fields: [
      {
        id: "duration",
        title: "Durée de fonctionnement annuelle (en heures) du moteur piloté par le système",
        type: "number",
      },
      {
        id: "brakeRatio",
        title: "Pourcentage du temps de fonctionnement où le moteur est en phase de freinage (ex : 0.05 pour 5 %)",
        type: "number",
      },
      {
        id: "power",
        title: "Puissance utile du moteur (en kW)",
        type: "number",
      },
      {
        id: "factor",
        title: "Coefficient multiplicateur",
        type: "value",
        value: "9.25",
      },
    ],
    attachments: ["Étude de dimensionnement préalable"],
  },
  {
    name: "Système de mesurage d'indicateurs de performance énergétique",
    id: "IND-UT-134",
    type: "Autres",
    formUrl: "https://gdceeforms.blob.core.windows.net/v61/IND-UT-134.pdf",
    fields: [
      {
        id: "hourMode",
        title: "Mode de fonctionnement du site",
        type: "choice",
        choices: [
          {
            name: "1 x 8h",
            value: "1",
          },
          {
            name: "2 x 8h",
            value: "2.2",
          },
          {
            name: "3 x 8h avec arrêt le week-end",
            value: "3",
          },
          {
            name: "3 x 8h sans arrêt le week-end",
            value: "4.2",
          },
        ],
      },
      {
        id: "duration",
        title: "Durée du contrat de location du logiciel de gestion énergétique",
        type: "choice",
        choices: [
          {
            name: "1 an",
            value: "1",
          },
          {
            name: "2 ans",
            value: "1.96",
          },
          {
            name: "3 ans",
            value: "2.89",
          },
          {
            name: "4 ans",
            value: "3.78",
          },
          {
            name: "5 ans",
            value: "4.63",
          },
          {
            name: "6 ans ou plus ou achat du logiciel",
            value: "5.45",
          },
        ],
      },
      {
        id: "power",
        title: "Puissance nominale en kW",
        type: "number",
      },
      {
        id: "ratio",
        title: "Montant en kWh cumac par kW",
        type: "value",
        value: "29.4",
      },
    ],
    attachments: [
      "Rapport de l'étude préalable",
      "Descriptif du logiciel de gestion énergétique",
      "Contrat dans le cas d'une location ou d'un abonnement",
      "Descriptif des instruments de mesure",
      "Rapport de suivi des IPE",
      "Tableau récapitulatif",
    ],
  },
  {
    name: "Freecooling par eau de refroidissement en substitution d'un groupe froid",
    id: "IND-UT-135",
    type: "Froid",
    formUrl: "https://gdceeforms.blob.core.windows.net/v61/IND-UT-135.pdf",
    fields: [
      {
        id: "hourMode",
        title: "Durée de fonctionnement de l'usage du froid",
        type: "choice",
        choices: [
          {
            name: "1 x 8h",
            value: "1",
          },
          {
            name: "2 x 8h (5j/7 ou 6j/7)",
            value: "2.2",
          },
          {
            name: "3 x 8h avec arrêt le week-end",
            value: "3",
          },
          {
            name: "3 x 8h sans arrêt le week-end",
            value: "4.2",
          },
        ],
      },
      {
        id: "temperature",
        title: "Température de consigne du réseau hydraulique",
        type: "choice",
        choices: [
          {
            name: ">= 12°C / < 15°C",
            value: ":H:7400:H:4900:H:3300",
          },
          {
            name: ">= 15°C / < 18°C",
            value: ":H:9900:H:8200:H:5800",
          },
          {
            name: ">= 18°C / <= 21°C",
            value: ":H:12300:H:11500:H:9000",
          },
        ],
      },
      {
        id: "power2",
        title: "Puissance électrique nominale du groupe de production de froid (en kW)",
        type: "number",
      },
    ],
    attachments: ["Étude de dimensionnement préalable"],
    startDate: "2024-08-22T01:00:00.000Z",
    history: [
      {
        name: "Freecooling par eau de refroidissement en substitution d'un groupe froid",
        id: "IND-UT-135",
        type: "Froid",
        formUrl: "https://gdceeforms.blob.core.windows.net/v61/IND-UT-135.pdf",
        fields: [
          {
            id: "hourMode",
            title: "Durée de fonctionnement de l'usage du froid",
            type: "choice",
            choices: [
              {
                name: "1 x 8h",
                value: "1",
              },
              {
                name: "2 x 8h (5j/7 ou 6j/7)",
                value: "2.2",
              },
              {
                name: "3 x 8h avec arrêt le week-end",
                value: "3",
              },
              {
                name: "3 x 8h sans arrêt le week-end",
                value: "4.2",
              },
            ],
          },
          {
            id: "temperature",
            title: "Température de consigne du réseau hydraulique",
            type: "choice",
            choices: [
              {
                name: ">= 12°C / < 15°C",
                value: ":H:7400:H:4900:H:3300",
              },
              {
                name: ">= 15°C / < 18°C",
                value: ":H:9900:H:8200:H:5800",
              },
              {
                name: ">= 18°C / <= 21°C",
                value: ":H:12300:H:11500:H:9000",
              },
            ],
          },
          {
            id: "power",
            title: "Puissance électrique nominale du groupe de production de froid en kW",
            type: "number",
          },
        ],
        attachments: ["Étude de dimensionnement préalable"],
      },
    ],
  },
  {
    name: "Systèmes moto-régulés",
    id: "IND-UT-136",
    type: "Moteur",
    formUrl: "https://gdceeforms.blob.core.windows.net/v61/IND-UT-136.pdf",
    fields: [
      {
        id: "hourMode",
        title: "Mode de fonctionnement du site",
        type: "choice",
        choices: [
          {
            name: "1 x 8h",
            value: "1",
          },
          {
            name: "2 x 8h et 5j/7 ou 6j/7",
            value: "2",
          },
          {
            name: "3 x 8h avec arrêt le week-end",
            value: "3",
          },
          {
            name: "3 x 8h sans arrêt le week-end",
            value: "4",
          },
        ],
      },
      {
        id: "type",
        title: "Type du système moto-régulé",
        type: "choice",
        choices: [
          {
            name: "Pompage ou ventilation",
            value: "1",
          },
          {
            name: "Production de froid ou d'air comprimé",
            value: "2",
          },
        ],
      },
      {
        id: "hourMode|type",
        title: "Montant de certificats en kWh cumac par kW selon le type de système moto-régulé",
        type: "multichoice",
        choices: [
          {
            name: "1|1",
            value: "7800",
          },
          {
            name: "1|2",
            value: "4400",
          },
          {
            name: "2|1",
            value: "17100",
          },
          {
            name: "2|2",
            value: "9800",
          },
          {
            name: "3|1",
            value: "23300",
          },
          {
            name: "3|2",
            value: "13300",
          },
          {
            name: "4|1",
            value: "32600",
          },
          {
            name: "4|2",
            value: "18600",
          },
        ],
      },
      {
        id: "power",
        title: "Puissance électrique nominale du moteur entraînant le système moto-régulé en kW",
        type: "number",
      },
    ],
    attachments: ["Étude préalable"],
  },
  {
    name: "Mise en place d’un système de pompe(s) à chaleur en rehausse de température de chaleur fatale récupérée",
    id: "IND-UT-137",
    type: "Thermique",
    formUrl: "https://gdceeforms.blob.core.windows.net/v61/IND-UT-137.pdf",
    fields: [
      {
        id: "thermalEnergy",
        title: "Energie thermique annuelle fournie sous forme de chaleur en sortie du système (en kWh/an)",
        type: "number",
        skipValue: true,
      },
      {
        id: "electricEnergy",
        title: "Energie électrique annuelle absorbée par le système (compresseur(s) et auxiliaires) (en kWh/an)",
        type: "number",
        skipValue: true,
      },
      { id: "formula", title: "Calcul", type: "formula", value: "INDUT137" },
    ],
    attachments: [],
    startDate: "2025-01-01T02:00:00.000Z",
  },
  {
    name: "Conversion de chaleur fatale en électricité ou en air comprimé",
    id: "IND-UT-138",
    type: "Thermique",
    formUrl: "https://gdceeforms.blob.core.windows.net/v61/IND-UT-138.pdf",
    fields: [
      { id: "hours", title: "Durée annuelle de fonctionnement (en heures)", type: "number", skipValue: true },
      {
        id: "thermalPower",
        title: "Puissance thermique apportée par le fluide caloporteur à la machine thermodynamique (en kW thermique)",
        type: "number",
        skipValue: true,
      },
      {
        id: "efficiency",
        title: "Rendement brut estimé de la machine thermodynamique (en %) ",
        type: "number",
        skipValue: true,
      },
      {
        id: "electricPower",
        title: "Puissance électrique absorbée par les auxiliaires (en kW électrique)",
        type: "number",
        skipValue: true,
      },
      { id: "formula", title: "Calcul", type: "formula", value: "INDUT138" },
    ],
    attachments: [],
    startDate: "2025-01-01T02:00:00.000Z",
  },
  {
    name: "Système de stockage de chaleur fatale",
    id: "IND-UT-139",
    type: "Thermique",
    formUrl: "https://gdceeforms.blob.core.windows.net/v61/IND-UT-139.pdf",
    fields: [
      {
        id: "efficiency",
        title: "Rendement du système de stockage (en %) ",
        type: "number",
      },
      {
        id: "capacity",
        title: "Capacité maximale de stockage de chaleur du système (en kWh)",
        type: "number",
      },
      {
        id: "cycles",
        title: "Nombre annuel de cycles équivalents à 100 % de la capacité maximale du système de stockage",
        type: "number",
      },
      {
        id: "factor",
        title: "multicplicator",
        type: "value",
        value: "14.134",
      },
      {
        id: "handlingPercentage",
        title: "Transformation du champ efficiency en pourcentage",
        type: "value",
        value: "0.01",
      },
    ],
    attachments: [],
    startDate: "2025-01-01T02:00:00.000Z",
  },
];
export default indForms;

export const activeForms: string[] = indForms.filter((f) => f.fields.length > 0).map((f) => f.id);
