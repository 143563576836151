import { Modal, Paper, TextField, Typography } from "@mui/material";
import { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import GdButton from "../../utils/GdButton";
import GdDatePicker from "../../utils/GdDatePicker";
import { TextMaskNumber } from "../../utils/TextMasks";

interface DocumentValidationModalProps {
  open: boolean;
  onClose: () => void;
  documentValidation: (date: string, amount: string) => void;
  type: string;
  url?: string;
  isLoading?: boolean;
}

const DocumentValidationModal: FC<DocumentValidationModalProps> = ({
  open,
  onClose,
  documentValidation,
  url,
  type,
  isLoading,
}): JSX.Element => {
  const { t } = useTranslation("project");
  const [date, setDate] = useState<Date | undefined>(undefined);
  const [amount, setAmount] = useState<string>("");

  return (
    <Modal open={open} onClose={onClose} className="project-modal-root">
      <Paper
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          gap: "16px",
          padding: "32px",
        }}>
        <Typography variant="h4">{t(`${type}`)}</Typography>
        <div style={{ display: "flex" }}>
          {typeof url === "undefined" ? undefined : <iframe src={url} width="800px" height="900px" title="Document" />}
          <div
            style={{
              margin: "16px",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}>
            <GdDatePicker
              label={t(`${type}Date`)}
              onChange={(e) => setDate(e || undefined)}
              value={date || null}
              disabled={false}
              className="big-margin-bottom"
            />
            <TextField
              id="outlined-number"
              label={t(`${type}Amount`)}
              fullWidth
              value={amount}
              onChange={(e) => setAmount(e.target.value)}
              className="big-margin-bottom"
              InputProps={{
                inputComponent: TextMaskNumber as any,
              }}
            />
            <div style={{ display: "flex", justifyContent: "center", gap: "16px", width: "100%" }}>
              <GdButton label={t("cancel")} color="secondary" onClick={onClose} />
              <GdButton
                label={t("global:ok")}
                onClick={() => documentValidation(date?.toISOString() || "", amount)}
                isLoading={isLoading}
              />
            </div>
          </div>
        </div>
      </Paper>
    </Modal>
  );
};

export default DocumentValidationModal;

DocumentValidationModal.defaultProps = {
  url: undefined,
  isLoading: false,
};
