import { NavigateNext } from "@mui/icons-material";
import { List, ListItem, ListItemIcon, ListItemText, Typography } from "@mui/material";
import { FC, ReactNode } from "react";

export interface GdListItem {
  icon?: ReactNode;
  content: ReactNode;
}

interface GdListProps {
  items: GdListItem[];
  dense?: boolean;
  fontSize?: "normal" | "big";
}

const GdList: FC<GdListProps> = ({ items, dense, fontSize }) => (
  <List dense={dense}>
    {items.map((i) => (
      <ListItem key={JSON.stringify(i.content)}>
        <ListItemIcon>{i.icon ? i.icon : i?.content === "" ? undefined : <NavigateNext />}</ListItemIcon>
        <ListItemText primary={fontSize === "big" ? <Typography variant="h6">{i.content}</Typography> : i.content} />
      </ListItem>
    ))}
  </List>
);

GdList.defaultProps = { dense: false, fontSize: "normal" };

export default GdList;
