import { Modal, Paper } from "@mui/material";
import { FC, PropsWithChildren } from "react";

interface GdModalProps extends PropsWithChildren {
  open: boolean;
  onClose: () => void;
}

const GdModal: FC<GdModalProps> = ({ open, onClose, children }) => (
  <Modal open={open} onClose={onClose} className="project-modal-root">
    <Paper className="project-modal-paper charter-modal">{children}</Paper>
  </Modal>
);

export default GdModal;
