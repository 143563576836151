import { Typography } from "@mui/material";
import { FC, useContext } from "react";
import { useTranslation } from "react-i18next";
import { CartesianGrid, Legend, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";
import { SettingsContext } from "../data/contexts/SettingsContext";
import { Rate, RateInput } from "../data/generated/graphql";
import { computePeriods } from "../data/dataConvertors";

const monthFromPeriod = (period: string): string => {
  switch (period.substring(5, 7)) {
    case "01":
      return "janv.";
    case "02":
      return "févr.";
    case "03":
      return "mars";
    case "04":
      return "avril";
    case "05":
      return "mai";
    case "06":
      return "juin";
    case "07":
      return "juil.";
    case "08":
      return "août";
    case "09":
      return "sept.";
    case "10":
      return "oct.";
    case "11":
      return "nov.";
    case "12":
      return "déc.";
    default:
      return "";
  }
};

const rateToRateMonth = (rate: Rate): RateMonth => ({
  period: rate.period,
  value: rate.value,
  month: monthFromPeriod(rate.period || ""),
});

interface RateMonth extends RateInput {
  month: string;
}

const sortRateHistory = (rateHistory: Rate[]): RateMonth[] => {
  const elevenMonthAgo = new Date(new Date().getTime() - 11 * 30 * 24 * 3600 * 1000);
  const firstPeriod = elevenMonthAgo.toISOString().substring(0, 7);
  const rateHistoryRates = rateHistory.filter((rh) => (rh.period || "") >= firstPeriod);
  return computePeriods(rateHistoryRates, false).map((r) => rateToRateMonth(r));
};

const RateHistoryDataViz: FC = () => {
  const { t } = useTranslation("settings");
  const { settings } = useContext(SettingsContext);
  const obliged = settings.obliged.filter((o) => o.active);
  const series = obliged.map((o) => ({
    name: o.shortName,
    data: o.rateHistory !== undefined && o.rateHistory !== null ? sortRateHistory(o.rateHistory) : [],
    color: o.color,
  }));

  return (
    <ResponsiveContainer width="100%" height={356}>
      <div style={{ display: "flex", flexDirection: "column", alignItems: "center", margin: "16px" }}>
        <Typography>{t("monthlyRateEvolution")}</Typography>
        <LineChart width={750} height={300}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="month" allowDuplicatedCategory={false} />
          <YAxis dataKey="value" domain={[4, 10]} />
          <Tooltip />
          <Legend />
          {series.map((s) => (
            <Line
              dataKey="value"
              data={s.data}
              name={s.name}
              key={s.name}
              stroke={s.color || ""}
              type="natural"
              activeDot={{ r: 8 }}
            />
          ))}
        </LineChart>
      </div>
    </ResponsiveContainer>
  );
};

export default RateHistoryDataViz;
