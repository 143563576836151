import { Card, Paper, Table, TableBody, TableCell, TableRow, Typography } from "@mui/material";
import { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { GlobalRate } from "../data/generated/graphql";
import GdAlert from "../utils/GdAlert";
import GdButton from "../utils/GdButton";
import EditRate from "./EditRate";
import { formatNumber, formatThreshold } from "../data/dataConvertors";

interface RatesProps {
  rates: GlobalRate[];
}

interface RateProps {
  rate: GlobalRate;
  isCurrent: boolean;
}

const Rate: FC<RateProps> = ({ rate, isCurrent }) => {
  const { t } = useTranslation("settings");
  const [edit, setEdit] = useState(false);

  return (
    <Card variant="outlined" className={`rate-card${isCurrent ? "-current" : ""} margin-bottom`}>
      <div className="row space-between margin-bottom">
        <Typography variant={isCurrent ? "h6" : "body2"}>
          {t("periodTwo")} {rate.period}
        </Typography>
        <Typography variant={isCurrent ? "h6" : "body2"}>{t(`rateStatus.${rate.status}`)}</Typography>
      </div>
      <Table component={Paper} variant="outlined">
        <TableBody>
          {rate.thresholds?.map((rt) => (
            <TableRow key={rt.minimumCapacity}>
              <TableCell>
                <Typography variant={isCurrent ? "h6" : "body2"}>{formatThreshold(rt)}</Typography>
              </TableCell>
              <TableCell>
                <Typography variant={isCurrent ? "h6" : "body2"}>{`${formatNumber(
                  rt.rate || undefined,
                  2,
                  true,
                )} € / MWhca`}</Typography>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      {isCurrent ? (
        <>
          <GdButton className="edit-rate-button" label={t("editRate")} onClick={() => setEdit(true)} />
          <GdAlert
            open={edit}
            onClose={() => setEdit(false)}
            title={t("editRate")}
            body={<EditRate rate={rate} onSuccess={() => setEdit(false)} />}
            okButton={false}
          />
        </>
      ) : undefined}
    </Card>
  );
};

const Rates: FC<RatesProps> = ({ rates }) => {
  const currentPeriod = new Date().toISOString().substring(0, 7);

  return (
    <div className="rates-root">
      {rates.map((r) => (
        <Rate key={r.period} rate={r} isCurrent={r.period === currentPeriod} />
      ))}
    </div>
  );
};

export default Rates;
