import { Card, CircularProgress, Divider, Switch, Tooltip, Typography } from "@mui/material";
import { FC, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { UsersContext } from "../data/contexts/UsersContext";
import { Claims, User } from "../data/generated/graphql";

interface UserCardProps {
  user: User;
  setUser: (newUser: User | undefined) => void;
  linkToUser?: boolean;
}

const UserCard: FC<UserCardProps> = ({ user, setUser, linkToUser }) => {
  const { t } = useTranslation("user");
  const [loading, setLoading] = useState(false);
  const [claimLoading, setClaimLoading] = useState("");
  const { users, getUser, changeUserClaim } = useContext(UsersContext);
  const handleClaimChange = async (userId: string, claim: Claims, granted: boolean): Promise<void> => {
    setClaimLoading(claim as string);
    await changeUserClaim(userId, claim, granted);
    setClaimLoading("");
  };

  useEffect(() => {
    (async () => {
      setUser(users.find((u) => u.id === user.id));
      setLoading(true);
      try {
        const res = await getUser(user.id);
        if (res) setUser(res);
        else {
          setUser(undefined);
        }
      } catch (err) {
        setUser(undefined);
      } finally {
        setLoading(false);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user.id]);

  const userName = (
    <Typography variant="h6" noWrap>
      {user.name}
    </Typography>
  );

  return (
    <Card variant="outlined" className="padding" style={{ width: "250px" }}>
      {linkToUser ? <Link to={`/users/${user.id}`}>{userName}</Link> : userName}
      <Tooltip title={user.email || ""} arrow>
        <Typography noWrap>{user.email}</Typography>
      </Tooltip>
      {loading ? (
        <div className="text-center">
          <CircularProgress size={20} />{" "}
        </div>
      ) : (
        <Typography>{user.phone || t("noPhone")}</Typography>
      )}
      <Divider className="margin-top margin-bottom" />
      <Typography noWrap>{user.role}</Typography>
      {user.company?.name ? <Typography noWrap>{user.company?.name}</Typography> : undefined}
      {user.company?.address ? <Typography noWrap>{user.company?.address}</Typography> : undefined}
      {user.company?.zipCode || user.company?.city ? (
        <Typography noWrap>
          {user.company?.zipCode || ""} {user.company?.city || ""}
        </Typography>
      ) : undefined}
      <Divider className="margin-top margin-bottom" />
      <Typography>{t(`userStatus.${user.active}`)}</Typography>
      {(Object.keys(Claims) as unknown as (keyof typeof Claims)[]).map((c) => (
        <>
          <Divider className="margin-top margin-bottom" />
          <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", paddingRight: "24px" }}>
            <Typography>{t(`claims.${c}`)}</Typography>
            {claimLoading === Claims[c] ? (
              <CircularProgress size={24} />
            ) : (
              <Switch
                color="primary"
                checked={
                  users.filter((u) => u.id === user.id)[0].claims?.find((claim) => claim.name === Claims[c])
                    ?.granted === true
                }
                onChange={(e) => handleClaimChange(user.id, Claims[c], e.target.checked)}
                inputProps={{ "aria-label": "controlled" }}
              />
            )}
          </div>
        </>
      ))}
    </Card>
  );
};

UserCard.defaultProps = { linkToUser: false };

export default UserCard;
