import { TextField } from "@mui/material";
import { FC, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import GdDatePicker from "../../utils/GdDatePicker";
import GdAlert from "../../utils/GdAlert";
import { Project } from "../../data/generated/graphql";
import { ProjectsContext } from "../../data/contexts/ProjectsContext";
import { TextMaskNumber } from "../../utils/TextMasks";

interface AmountsAndDatesModalProps {
  open: boolean;
  onClose: () => void;
  project: Project;
}

const AmountsAndDatesModal: FC<AmountsAndDatesModalProps> = ({ open, onClose, project }) => {
  const [invoiceDate, setInvoiceDate] = useState<string>("");
  const [invoiceAmount, setInvoiceAmount] = useState<string>("");
  const [quoteDate, setQuoteDate] = useState<string>("");
  const [quoteAmount, setQuoteAmount] = useState<string>("");
  const [userRate, setUserRate] = useState<string>("");
  const [customObligedRate, setCustomObligedRate] = useState<string>("");
  const [billingDate, setBillingDate] = useState<string>("");
  const [billingAmount, setBillingAmount] = useState<string>("");
  const [datesIsLoading, setDatesIsLoading] = useState(false);
  const [flagsHistory, setFlagsHistory] = useState<Record<string, string>>({});
  const { updateDatesHistory } = useContext(ProjectsContext);
  const { t } = useTranslation("project");
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    setUserRate(project?.userRate || "");
    setQuoteAmount(project?.quote?.amount || "");
    setQuoteDate(project?.quote?.date || "");
    setInvoiceAmount(project?.invoiceInfo?.amount || "");
    setInvoiceDate(project?.invoiceInfo?.date || "");
    setBillingAmount(project?.billing?.amount || "");
    setBillingDate(project?.billing?.date || "");
    const flagsName = Object.keys(project?.flags || {}).filter(
      (f) => (project?.flags as Record<string, boolean>)?.[f] === true,
    );

    let newHistory: Record<string, string> = {};
    flagsName.forEach((f) => {
      newHistory = { ...newHistory, [f]: (project?.history as Record<string, string>)?.[f] };
    });

    setFlagsHistory(newHistory);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [project]);

  const invoiceAttachment = project?.attachments?.find((a) => a.concerns?.includes("invoice"));

  const changeAdmin = async (): Promise<void> => {
    setDatesIsLoading(true);
    const result = await updateDatesHistory(
      project.id,
      flagsHistory,
      {
        date: invoiceDate,
        amount: invoiceAmount,
      },
      quoteAmount,
      quoteDate,
      userRate,
      customObligedRate,
      billingAmount,
      billingDate,
    );
    if (!result) {
      enqueueSnackbar(t("global:errorWhileUpdating"), { variant: "error" });
    }
    setDatesIsLoading(false);
    onClose();
  };

  return (
    <GdAlert
      open={open}
      onClose={onClose}
      title={t("adminDatesTitle")}
      body={
        <div style={{ display: "flex", flexDirection: "column", marginTop: "16px", gap: "24px" }}>
          <TextField
            id="outlined-number"
            label={t("userRate")}
            value={userRate}
            onChange={(e) => setUserRate(e.target.value)}
            InputProps={{
              inputComponent: TextMaskNumber as any,
            }}
          />
          <TextField
            id="outlined-number"
            label={t("customObligedRate")}
            value={customObligedRate}
            onChange={(e) => setCustomObligedRate(e.target.value)}
            InputProps={{
              inputComponent: TextMaskNumber as any,
            }}
          />
          {project.quote ? (
            <>
              <GdDatePicker
                label={t("quoteDate")}
                onChange={(e) => {
                  setQuoteDate(e?.toISOString() || "");
                }}
                value={quoteDate.length === 0 ? null : new Date(quoteDate)}
                disabled={false}
              />
              <TextField
                id="outlined-number"
                label={t("quoteAmount")}
                value={quoteAmount}
                onChange={(e) => setQuoteAmount(e.target.value)}
                InputProps={{
                  inputComponent: TextMaskNumber as any,
                }}
              />
            </>
          ) : undefined}
          {invoiceAttachment ? (
            <>
              <GdDatePicker
                label={t("invoiceDate")}
                onChange={(e) => {
                  setInvoiceDate(e?.toISOString() || "");
                }}
                value={invoiceDate.length === 0 ? null : new Date(invoiceDate)}
                disabled={false}
              />
              <TextField
                id="outlined-number"
                label={t("invoiceAmount")}
                value={invoiceAmount}
                onChange={(e) => setInvoiceAmount(e.target.value)}
                InputProps={{
                  inputComponent: TextMaskNumber as any,
                }}
              />
            </>
          ) : undefined}
          {project.billing ? (
            <>
              <GdDatePicker
                label={t("billingDate")}
                onChange={(e) => {
                  setBillingDate(e?.toISOString() || "");
                }}
                value={billingDate.length === 0 ? null : new Date(billingDate)}
                disabled={false}
              />
              <TextField
                id="outlined-number"
                label={t("billingAmount")}
                value={billingAmount}
                onChange={(e) => setBillingAmount(e.target.value)}
                InputProps={{
                  inputComponent: TextMaskNumber as any,
                }}
              />
            </>
          ) : undefined}
          {Object.keys(flagsHistory).map((f) => (
            <GdDatePicker
              key={f}
              label={t(`adminFlags.${f}`)}
              onChange={(e) => {
                setFlagsHistory({ ...flagsHistory, [f]: e?.toISOString() || "" });
              }}
              value={(flagsHistory[f]?.length || 0) === 0 ? null : new Date(flagsHistory[f])}
              disabled={false}
            />
          ))}
        </div>
      }
      okButtonClick={() => changeAdmin()}
      okButtonLoading={datesIsLoading}
      cancelButton
    />
  );
};

export default AmountsAndDatesModal;
