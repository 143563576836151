import { FC } from "react";

const getColor = (status: string): string => {
  switch (status) {
    case "Simulation":
    case "En cours":
    case "Convention à demander":
    case "Convention demandée":
    case "Convention créée":
    case "Convention signée":
      return "#3E74D1";
    case "Vide / Non valorisable":
    case "Stand-by":
    case "Convention refusée":
    case "Convention expirée":
      return "#808080";
    case "Devis déposé":
    case "Devis validé":
    case "Devis refusé":
    case "Affaire ouverte":
    case "Dépôt des PJ en cours":
    case "Validation des PJ en cours":
    case "AH à initialiser":
    case "AH à déposer":
    case "AH déposée":
    case "AH refusée":
    case "Dossier complet":
      return "#45B53C";
    case "Dossier envoyé à l'obligé":
    case "Dossier validé par l'obligé":
    case "Factures envoyées à l'obligé":
    case "Prime versée par l'obligé":
    case "Facture prime déposée":
    case "Facture prime refusée":
    case "Facture prime validée":
    case "Facture commission installateur déposée":
    case "Facture commission installateur refusée":
    case "Facture commission installateur validée":
      return "#26938A";
    default:
      return "black";
  }
};

const StatusLabel: FC<{ status: string }> = ({ status }) => <span style={{ color: getColor(status) }}>{status}</span>;

export default StatusLabel;
