import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import frGlobal from "./fr/global.json";
import frUser from "./fr/user.json";
import frSettings from "./fr/settings.json";
import frProject from "./fr/project.json";
import frAiAssistant from "./fr/aiAssistant.json";

i18n.use(initReactI18next).init({
  resources: {
    fr: {
      global: frGlobal,
      user: frUser,
      settings: frSettings,
      project: frProject,
      aiAssistant: frAiAssistant,
    },
  },
  lng: "fr",
  fallbackLng: "fr",
  interpolation: {
    escapeValue: false,
  },
});
