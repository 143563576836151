import { NavigateNext } from "@mui/icons-material";
import { ListItem, ListItemButton, ListItemIcon, ListItemText, Typography } from "@mui/material";
import { FC, useContext } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { ProjectsContext } from "../data/contexts/ProjectsContext";
import { UsersContext } from "../data/contexts/UsersContext";
import { dateConvertToString } from "../data/dataConvertors";
import { AdminAction } from "../data/generated/graphql";

interface ActionItemProps {
  action: AdminAction;
}

const ActionItem: FC<ActionItemProps> = ({ action }) => {
  const { projects } = useContext(ProjectsContext);
  const { users } = useContext(UsersContext);
  const { t } = useTranslation("project");
  const history = useHistory();

  const project = projects.find((p) => p.id === action.projectId);
  const user = users.find((u) => u.id === project?.userId);
  const navToProject = (): void => history.push(`/projects/${action.projectId}`);

  return (
    <ListItem key={action.projectId}>
      <ListItemButton onClick={navToProject}>
        <ListItemIcon>
          <NavigateNext />
        </ListItemIcon>
        <ListItemText
          primary={
            <Typography>
              {t(`actionText.${action.type}`)} <b>{project?.name || ""}</b>
            </Typography>
          }
          secondary={
            <Typography variant="body2">
              {t("actionSubtitle", {
                replace: {
                  userName: user?.name,
                  latestModification: project?.latestModification
                    ? dateConvertToString(project?.latestModification)
                    : "",
                },
              })}
            </Typography>
          }
          style={{ cursor: "pointer" }}
        />
      </ListItemButton>
    </ListItem>
  );
};

export default ActionItem;
