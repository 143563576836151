import { FC } from "react";

export interface PictoProps {
  width: number;
  height: number;
  fill: string;
}

const GreenyPicto: FC<PictoProps> = ({ width, height, fill }): JSX.Element => {
  return (
    <svg
      version="1.1"
      id="Calque_1"
      width={width}
      height={height}
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 100.98 100.98">
      <path
        fill={fill}
        d="M93.16,36.36c-0.26,0.03-0.6,0.14-1.04,0.36c-4.15,2.07-8.52,3.62-12.98,4.9c-0.82,0.23-1.36,0.44-1.4,1.43
          c-0.09,1.76-0.29,3.51-0.46,5.26c-1.06,10.26-4.79,19.49-11.8,27.05c-9.83,10.6-22.83,14.23-36.73,14.8
          c-2.68,0.11-7.22-0.27-7.43-0.01c-1.15,1.42-3.08,3.3-4.95,4.78c-0.05,0.06-0.05,0.16-0.1,0.36c1.78-0.01,3.35-0.56,4.65-1.63
          c1.88-1.55,3.42-0.35,3.51-0.42c0,0,6.47,3.08,15.38,4.91c0.05,0.01,0.1,0.02,0.14,0.03c2.49,0.51,5.17,0.91,7.95,1.12
          c0.29,0.02,0.57,0.04,0.84,0.06c0.28,0.02,0.56,0.03,0.84,0.05c1.1,0.05,2.19,0.07,3.25,0.05c0.2,0,0.39-0.01,0.59-0.01
          c0.07,0,0.14,0,0.2-0.01c0.31-0.01,0.63-0.02,0.95-0.04c6.09-0.3,12.32-1.8,17.86-5.36l0.01-0.01c0.2-0.13,0.4-0.26,0.6-0.4
          C89.21,82.66,94.94,65.13,93.16,36.36z"
      />
      <path
        fill={fill}
        d="M75.64,40.71c-0.21-11.2-3.57-23.91-9.94-38.91c-0.03-0.09-0.07-0.18-0.11-0.27c0,0-2.75,3.88-7.88,8.67
          c-0.49,0.45-0.97,0.91-1.47,1.34c-0.12,0.1-0.22,0.2-0.34,0.3c-2.3,2.02-4.99,4.14-8.05,6.15c-11.4,7.52-53.39,25.4-36.28,61.57
          c0,0-0.37,0.61-0.89,1.4c-0.01,0.01-0.01,0.01-0.01,0.01c-0.41,0.61-0.91,1.33-1.39,1.96c0,0,0,0-0.01,0.01
          c-0.21,0.28-0.42,0.53-0.63,0.76c-0.18,0.2-0.35,0.37-0.51,0.51v12.92c0,0,0.01,0,0.02-0.01c0.18-0.07,1.26-0.5,2.73-1.24
          c2.96-1.49,7.49-4.22,9.35-7.64c0,0,33,2.92,47.19-18.66c5.43-8.26,8.14-17.1,8.24-27.14C75.65,41.86,75.65,41.29,75.64,40.71z"
      />
    </svg>
  );
};

export default GreenyPicto;
