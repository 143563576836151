import { List, Typography } from "@mui/material";
import { FC, useContext } from "react";
import { useTranslation } from "react-i18next";
import { ProjectsContext } from "../data/contexts/ProjectsContext";
import BaseCard from "../utils/BaseCard";
import ActionItem from "./ActionItem";

const Actions: FC = () => {
  const { actions } = useContext(ProjectsContext);
  const { t } = useTranslation("project");

  return (
    <BaseCard>
      {actions.length === 0 ? (
        <Typography>{t("noAction")}</Typography>
      ) : (
        <>
          <Typography variant="h6">{t("actionsToDo")}</Typography>
          <List>
            {actions.map((a) => (
              <ActionItem action={a} key={a.projectId} />
            ))}
          </List>
        </>
      )}
    </BaseCard>
  );
};

export default Actions;
