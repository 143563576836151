import { FC } from "react";
import { useTranslation } from "react-i18next";
import { CartesianGrid, Legend, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";
import { dateConvertToDataDate } from "../data/dataConvertors";
import { DailyUsers } from "../data/generated/graphql";
import Theme from "../Theme";

interface UsageChartProps {
  usage: DailyUsers[];
}

interface DailyCount {
  day: string;
  count: number;
}

const UsageChart: FC<UsageChartProps> = ({ usage }) => {
  const { t } = useTranslation("global");
  const finalUsage: DailyCount[] = [];
  const now = new Date().getTime();
  const aDay = 24 * 3600 * 1000;
  for (let i = 0; i < 7; i += 1) {
    const day = new Date(now - i * aDay);
    const count = usage.find((u) => u.day === dateConvertToDataDate(day))?.users.length || 0;
    finalUsage.push({ day: i < 2 ? t(i === 0 ? "today" : "yesterday") : t(`weekdays.${day.getDay()}`), count });
  }
  return (
    <ResponsiveContainer width="100%" height={300}>
      <LineChart width={750} height={300} data={finalUsage.reverse()}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="day" tickMargin={8} />
        <YAxis yAxisId="left" tickMargin={8} stroke={Theme.palette.primary.main} />
        <Tooltip />
        <Legend />
        <Line
          yAxisId="left"
          type="monotone"
          name={t("usersPerDay")}
          dataKey="count"
          stroke={Theme.palette.primary.main}
        />
      </LineChart>
    </ResponsiveContainer>
  );
};

export default UsageChart;
