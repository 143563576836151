import { Typography } from "@mui/material";
import { FC, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { SettingsContext } from "../data/contexts/SettingsContext";
import { GlobalRate } from "../data/generated/graphql";
import BaseCard from "../utils/BaseCard";
import CenteredContent from "../utils/CenteredContent";
import EmmyDataViz from "./EmmyData";
import Rates from "./Rates";

import "./Settings.css";

const Settings: FC = () => {
  const { t } = useTranslation("settings");
  const { getSettings, settings } = useContext(SettingsContext);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!settings.rates || settings.rates.length === 0) {
      setLoading(true);
      getSettings().then(() => setLoading(false));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (loading) return <CenteredContent loading />;

  const latest5Rates: GlobalRate[] = settings.rates?.slice(0, 5) || [];

  return (
    <BaseCard>
      <Typography variant="h6" className="margin-bottom">
        {t("emmy")}
      </Typography>
      <div style={{ margin: "0 96px" }}>
        <EmmyDataViz emmy={settings.emmy.slice(0, 6).reverse()} />
      </div>
      <Typography variant="h6" className="margin-bottom">
        {t("rates")}
      </Typography>
      <Rates rates={latest5Rates} />
    </BaseCard>
  );
};

export default Settings;
