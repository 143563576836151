import { FormControl, IconButton, InputLabel, MenuItem, Select, TextField } from "@mui/material";
import { FileDownload } from "@mui/icons-material";
import { FC, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { useSnackbar } from "notistack";
import { Project, ProjectStatus } from "../data/generated/graphql";
import ProjectStatuses, {
  checkIfProjectIsEmpty,
  checkIfProjectIsTest,
  TO_TRANSFORM,
  TRANSFORMED,
} from "../data/lists/ProjectStatuses";
import { TokenContext } from "../data/contexts/TokenContext";
import exportData from "../utils/exportData";
import { Lvl, log } from "../utils/log";
import CenteredContent from "../utils/CenteredContent";

const getInitStatus = (): ProjectStatus | undefined =>
  document.location.hash
    ? (ProjectStatuses[parseInt(document.location.hash.replace("#", ""), 10)] as ProjectStatus)
    : undefined;

export interface ProjectsFiltersProps {
  setFilteredProjectsIds: (ids: string[]) => void;
  projects: Project[];
}

const isProjectInFilter = (project: Project, filter?: string): boolean => {
  const projectIsTest = checkIfProjectIsTest(project);
  const projectIsEmpty = checkIfProjectIsEmpty(project);
  if (!filter) {
    // All active projects not Test / Démonstration
    return Boolean(project.active && !projectIsTest && !projectIsEmpty);
  }
  if (filter === TO_TRANSFORM)
    return (
      (project.flags?.isStandBy ||
        project.status === ProjectStatus.Simulation ||
        project.status === ProjectStatus.Launched) &&
      !projectIsTest &&
      !projectIsEmpty
    );
  if (filter === TRANSFORMED)
    return (
      (project.status === ProjectStatus.PreworkCompleted || project.status === ProjectStatus.PostworkCompleted) &&
      !projectIsTest &&
      !projectIsEmpty
    );
  return project.detailedStatus === filter;
};

const ProjectsFilters: FC<ProjectsFiltersProps> = ({ setFilteredProjectsIds, projects }) => {
  const [filter, setFilter] = useState("");
  const [status, setStatus] = useState<ProjectStatus | undefined>(getInitStatus());
  const [isExporting, setIsExporting] = useState(false);
  const { t } = useTranslation("project");
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const { getToken } = useContext(TokenContext);

  const textSearch = (p: Project): boolean =>
    p.name?.toLowerCase().includes(filter.toLowerCase()) ||
    (typeof p.reference === "string" && p.reference?.toLowerCase().includes(filter.toLowerCase())) ||
    (typeof p.details?.sector === "string" && p.details.sector.toLowerCase().includes(filter.toLowerCase())) ||
    (typeof p.forms === "string" && p.forms.toLowerCase().includes(filter.toLowerCase()));

  const filterProjectsIds = (): void => {
    const filteredProjects = projects.filter((p) => isProjectInFilter(p, status as string)).filter(textSearch);
    setFilteredProjectsIds(filteredProjects.map((p) => p.id));
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(filterProjectsIds, [filter, status]);

  const changeStatus = (newStatus?: string): void => {
    if (newStatus) {
      const statusIndex = ProjectStatuses.indexOf(newStatus);
      document.location.hash = statusIndex.toString();
    } else history.push(document.location.pathname);
    setStatus(newStatus as ProjectStatus);
  };

  const changeFilter = (newFilterText: string): void => {
    setFilter(newFilterText);
  };

  const exportProjectsData = async (): Promise<void> => {
    setIsExporting(true);
    const token = await getToken();
    if (token !== null)
      await exportData(
        "projectsList",
        token,
        (error) => {
          log("Error when exporting projects list", Lvl.ERROR, error);
          enqueueSnackbar({ message: t("exportError"), variant: "error" });
        },
        filter.length > 0 ? filter : undefined,
        status,
      );
    setIsExporting(false);
  };

  const projectStatuses = projects.map((p) => p.detailedStatus);
  const applicableStatuses = ProjectStatuses.filter((s) => projectStatuses.indexOf(s) !== -1);

  return (
    <div className="row">
      <FormControl style={{ width: 250, marginRight: 16 }}>
        <InputLabel id="status-select-label">{t("filterByProjectStatus")}</InputLabel>
        <Select
          labelId="status-select-label"
          label={t("filterByProjectStatus")}
          variant="outlined"
          value={status}
          onChange={(e) => {
            changeStatus(e.target.value);
          }}
          style={{ width: 250 }}>
          <MenuItem value={undefined}>
            <em>{t("activeFilter")}</em>
          </MenuItem>
          <MenuItem value={TO_TRANSFORM}>
            <strong>{t("toTransformFilter")}</strong>
          </MenuItem>
          <MenuItem value={TRANSFORMED}>
            <strong>{t("transformedFilter")}</strong>
          </MenuItem>
          {applicableStatuses.map((s) => (
            <MenuItem value={s}>{s}</MenuItem>
          ))}
        </Select>
      </FormControl>
      <TextField
        value={filter}
        onChange={(e) => changeFilter(e.target.value)}
        variant="outlined"
        label={t("filterProjects")}
        style={{ width: 300 }}
      />
      {isExporting ? (
        <CenteredContent className="small-margin-left text-center" width={40} loadingSize={24} loading />
      ) : (
        <IconButton className="small-margin-left" onClick={exportProjectsData}>
          <FileDownload />
        </IconButton>
      )}
    </div>
  );
};

export default ProjectsFilters;
