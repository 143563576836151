import { FC } from "react";
import ReactQuill from "react-quill";

interface RichTextEditorProps {
  placeholder: string;
  body: string;
  setBody: (newBody: string) => void;
}

const RichTextEditor: FC<RichTextEditorProps> = ({ placeholder, body, setBody }) => (
  <ReactQuill
    id="quill-editor"
    theme="snow"
    value={body}
    onChange={(b) => setBody(b)}
    style={{ minHeight: "300px" }}
    placeholder={placeholder}
    formats={[
      "header",
      "bold",
      "italic",
      "underline",
      "strike",
      "blockquote",
      "list",
      "bullet",
      "indent",
      "link",
      "align",
    ]}
    modules={{
      toolbar: [
        [{ header: [1, 2, false] }],
        ["bold", "italic", "underline", "strike"],
        [{ align: ["", "center", "right", "justify"] }],
        [{ list: "ordered" }, { list: "bullet" }, { indent: "-1" }, { indent: "+1" }],
        ["link"],
      ],
      clipboard: {
        matchVisual: false,
      },
    }}
  />
);

export default RichTextEditor;
