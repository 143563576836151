import { Close } from "@mui/icons-material";
import {
  Divider,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Modal,
  Paper,
  Select,
  Typography,
} from "@mui/material";
import { useSnackbar } from "notistack";
import { FC, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ProjectsContext } from "../../data/contexts/ProjectsContext";
import { UsersContext } from "../../data/contexts/UsersContext";
import { Project, User, UserLightFragment } from "../../data/generated/graphql";
import UserCard from "../../users/UserCard";
import CenteredContent from "../../utils/CenteredContent";
import GdButton from "../../utils/GdButton";

interface ProjectUsersProps {
  project: Project;
}

const ProjectUsers: FC<ProjectUsersProps> = ({ project }) => {
  const [changeUserOpen, setChangeUserOpen] = useState(false);
  const [addViewerOpen, setAddViewerOpen] = useState(false);
  const [changingUser, setChangingUser] = useState(false);
  const [removingAUser, setRemovingAUser] = useState(false);
  const [selectedUserId, setSelectedUserId] = useState("");
  const { changeUser, addViewer, removeViewer } = useContext(ProjectsContext);
  const { users } = useContext(UsersContext);
  const [user, setUser] = useState<User | undefined>(project ? users.find((u) => u.id === project.userId) : undefined);
  const { t } = useTranslation("project");
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    setUser(project ? users.find((u) => u.id === project.userId) : undefined);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [project]);

  const changeProjectUser = async (): Promise<void> => {
    setChangingUser(true);
    const result = await changeUser(project.id, selectedUserId);
    if (!result) {
      enqueueSnackbar(t("global:errorWhileUpdating"), { variant: "error" });
    } else {
      enqueueSnackbar(t("global:updateSuccess"), { variant: "success" });
    }
    setChangingUser(false);
    setChangeUserOpen(false);
  };

  const addViewerToProject = async (): Promise<void> => {
    setChangingUser(true);
    const result = await addViewer(project.id, selectedUserId);
    if (!result) {
      enqueueSnackbar(t("global:errorWhileUpdating"), { variant: "error" });
    } else {
      enqueueSnackbar(t("global:updateSuccess"), { variant: "success" });
    }
    setChangingUser(false);
    setAddViewerOpen(false);
  };

  const removeViewerFromProject = async (viewerId: string): Promise<void> => {
    setRemovingAUser(true);
    const result = await removeViewer(project.id, viewerId);
    if (!result) {
      enqueueSnackbar(t("global:errorWhileUpdating"), { variant: "error" });
    } else {
      enqueueSnackbar(t("global:updateSuccess"), { variant: "success" });
    }
    setRemovingAUser(false);
    setAddViewerOpen(false);
  };

  const usersSort = (u1: UserLightFragment, u2: UserLightFragment): number =>
    (u1?.name || "") > (u2?.name || "") ? 1 : -1;

  const transferableUsers = users.filter((u) => u.id !== project.userId && u.active);
  transferableUsers.sort(usersSort);

  const viewers = users.filter((u) => project.viewers && project.viewers?.findIndex((v) => v === u.id) !== -1);
  viewers.sort(usersSort);

  const nonViewers = transferableUsers.filter((u) => viewers.findIndex((v) => v.id === u.id) === -1);

  return user ? (
    <div style={{ maxWidth: "260px" }}>
      <Typography variant="h6" className="text-center margin-bottom">
        {t("projectCols.user")}
      </Typography>
      <UserCard user={user} setUser={setUser} linkToUser />
      <GdButton className="margin-top" label={t("changeUserButton")} onClick={() => setChangeUserOpen(true)} />
      <Divider className="margin-top" />
      {viewers.length > 0 ? (
        <>
          <Typography variant="h6" className="text-center margin-bottom margin-top">
            {t("viewers")}
          </Typography>
          {removingAUser ? (
            <CenteredContent loading loadingSize={24} />
          ) : (
            viewers.map((u) => (
              <div className="row space-between">
                <Typography key={u.id}>{u.name}</Typography>
                <IconButton onClick={() => removeViewerFromProject(u.id)}>
                  <Close />
                </IconButton>
              </div>
            ))
          )}
        </>
      ) : undefined}
      <GdButton className="margin-top" label={t("addViewer")} onClick={() => setAddViewerOpen(true)} />
      <Modal
        open={changeUserOpen || addViewerOpen}
        onClose={() => {
          setChangeUserOpen(false);
          setAddViewerOpen(false);
        }}
        className="project-modal-root">
        <Paper className="project-modal-paper" style={{ minHeight: "inherit" }}>
          <Typography className="margin-bottom">{t(addViewerOpen ? "addViewerTitle" : "changeUserTitle")}</Typography>
          <FormControl>
            <InputLabel id="user-select-label">{t(addViewerOpen ? "chooseNewViewer" : "chooseNewUser")}</InputLabel>
            <Select
              value={selectedUserId}
              onChange={(e) => setSelectedUserId(e.target.value)}
              labelId="user-select-label"
              label={t(addViewerOpen ? "chooseNewViewer" : "chooseNewUser")}
              variant="outlined"
              fullWidth>
              {(addViewerOpen ? nonViewers : transferableUsers).map((u) => (
                <MenuItem value={u.id}>{u.name}</MenuItem>
              ))}
            </Select>
          </FormControl>
          <GdButton
            label={t(addViewerOpen ? "addNewViewer" : "transfer")}
            onClick={addViewerOpen ? addViewerToProject : changeProjectUser}
            fullWidth
            className="margin-top"
            disabled={selectedUserId.length === 0}
            isLoading={changingUser}
          />
        </Paper>
      </Modal>
    </div>
  ) : (
    <Typography>{t("userNotFound")}</Typography>
  );
};

export default ProjectUsers;
