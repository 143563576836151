interface CompanyType {
  key: string;
  name: string;
}

const CompanyTypeList: CompanyType[] = [
  { key: "EI", name: "Entrepreneur individuel" },
  { key: "SA", name: "Société anonyme" },
  { key: "SARL", name: "Société à responsabilité limitée" },
  { key: "SAS", name: "Société par actions simplifiée" },
  { key: "SNC", name: "Société en nom collectif" },
  { key: "GIE", name: "Groupement d'intérêt économique" },
  { key: "Société en commandite", name: "Société en commandite" },
  {
    key: "OPCVM",
    name: "Organisme de placement collectif en valeurs mobilières sans personnalité morale",
  },
  { key: "Indivision", name: "Indivision" },
  { key: "Société créée de fait", name: "Société créée de fait" },
  { key: "Société en participation", name: "Société en participation" },
  { key: "Fiducie", name: "Fiducie" },
  { key: "Paroisse hors zone concordataire", name: "Paroisse hors zone concordataire" },
  {
    key: "Autre groupement de droit privé non doté de la personnalité morale",
    name: "Autre groupement de droit privé non doté de la personnalité morale",
  },
  { key: "Personne morale de droit étranger", name: "Personne morale de droit étranger" },
  {
    key: "EPIC",
    name: "Etablissement public ou régie à caractère industriel ou commercial",
  },
  { key: "Société coopérative commerciale particulière", name: "Société coopérative commerciale particulière" },
  { key: "Société européenne", name: "Société européenne" },
  { key: "Caisse d'épargne et de prévoyance", name: "Caisse d'épargne et de prévoyance" },
  { key: "Société coopérative agricole", name: "Société coopérative agricole" },
  { key: "Société d'assurance mutuelle", name: "Société d'assurance mutuelle" },
  { key: "Société civile", name: "Société civile" },
  {
    key: "Autre personne morale de droit privé inscrite au registre du commerce et des sociétés",
    name: "Autre personne morale de droit privé inscrite au registre du commerce et des sociétés",
  },
  { key: "Administration de l'état", name: "Administration de l'état" },
  { key: "Collectivité territoriale", name: "Collectivité territoriale" },
  { key: "Etablissement public administratif", name: "Etablissement public administratif" },
  {
    key: "Autre personne morale de droit public administratif",
    name: "Autre personne morale de droit public administratif",
  },
  {
    key: "Organisme gérant un régime de protection sociale à adhésion obligatoire",
    name: "Organisme gérant un régime de protection sociale à adhésion obligatoire",
  },
  { key: "Organisme mutualiste", name: "Organisme mutualiste" },
  { key: "Comité d'entreprise", name: "Comité d'entreprise" },
  { key: "Organisme professionnel", name: "Organisme professionnel" },
  { key: "Organisme de retraite à adhésion non obligatoire", name: "Organisme de retraite à adhésion non obligatoire" },
  { key: "Syndicat de propriétaires", name: "Syndicat de propriétaires" },
  { key: "Association loi 1901 ou assimilé", name: "Association loi 1901 ou assimilé" },
  { key: "Fondation", name: "Fondation" },
  { key: "Autre personne morale de droit privé", name: "Autre personne morale de droit privé" },
];

export default CompanyTypeList;
