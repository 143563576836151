import { Modal, Paper } from "@mui/material";
import { FC } from "react";

interface GdIframeModalProps {
  open: boolean;
  onClose: () => void;
  iframeUrl: string;
}

const GdIframeModal: FC<GdIframeModalProps> = ({ open, onClose, iframeUrl }) => (
  <Modal open={open} onClose={onClose} className="project-modal-root">
    <Paper className="project-modal-paper charter-modal">
      <iframe src={iframeUrl} width="1050" height="650" title="Document" />
    </Paper>
  </Modal>
);

export default GdIframeModal;
