import { Modal, Paper, TextField, Typography } from "@mui/material";
import { useSnackbar } from "notistack";
import { FC, useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { dateConvertToDataDate } from "../../data/dataConvertors";
import GdButton from "../../utils/GdButton";
import WarningCard from "../../utils/WarningCard";
import { ProjectsContext } from "../../data/contexts/ProjectsContext";
import { UserContext } from "../../data/contexts/UserContext";

interface ProjectCancelModalProps {
  projectId: string;
  open: boolean;
  onClose: () => void;
}

const ProjectCancelModal: FC<ProjectCancelModalProps> = ({ open, onClose, projectId }) => {
  const [details, setDetails] = useState("");
  const [updating, setUpdating] = useState(false);
  const { t } = useTranslation(["project", "global"]);
  const { enqueueSnackbar } = useSnackbar();
  const { updateProject } = useContext(ProjectsContext);
  const { userInfo } = useContext(UserContext);

  const close = (): void => {
    setDetails("");
    onClose();
  };
  const cancelProject = async (): Promise<void> => {
    setUpdating(true);
    const result = await updateProject({
      id: projectId,
      cancellation: {
        details,
        reason: `${userInfo?.name || "Un administrateur"} a annulé ce projet depuis le Back-Office`,
        date: dateConvertToDataDate(new Date()),
      },
    });
    if (!result) {
      enqueueSnackbar(t("global:errorWhileUpdating"), { variant: "error" });
    } else {
      enqueueSnackbar(t("global:updateSuccess"), { variant: "success" });
    }
    setUpdating(false);
    close();
  };
  return (
    <Modal open={open} onClose={close} className="project-modal-root">
      <Paper className="project-modal-paper cancel-modal">
        <Typography variant="h6" className="margin-bottom">
          {t("cancelTitle")}
        </Typography>
        <WarningCard projectLabelKey="cancelWarning" textInTitle className="margin-bottom" />
        <br />
        <TextField
          variant="outlined"
          label={t("cancelDetails")}
          multiline
          rows={5}
          value={details}
          onChange={(e) => setDetails(e.target.value)}
        />
        <br />
        <div className="row space-evenly">
          <GdButton color="secondary" label={t("cancelCancel")} onClick={close} />
          <GdButton label={t("cancelValidate")} onClick={cancelProject} isLoading={updating} />
        </div>
      </Paper>
    </Modal>
  );
};

export default ProjectCancelModal;
