/* eslint react/require-default-props: "off" */
import { CircularProgress, IconButton, Input } from "@mui/material";
import { useSnackbar } from "notistack";
import { FC, FormEvent, ReactNode, useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { ProjectsContext } from "../../data/contexts/ProjectsContext";
import { AttachmentType } from "../../data/generated/graphql";
import GdButton from "../../utils/GdButton";

export interface UploadFileButtonProps {
  label: string;
  icon?: ReactNode;
  projectId?: string;
  type?: AttachmentType;
  className?: string;
  color?: "inherit" | "primary" | "secondary" | "success" | "error" | "info" | "warning";
  fullWidth?: boolean;
  buttonWidth?: "large" | "normal";
  uploadProcess?: (file: File) => Promise<boolean>;
  buttonId?: string;
  operationId?: string;
  preCheck?: () => boolean;
  overrideMode?: boolean;
}

const UploadFileButton: FC<UploadFileButtonProps> = ({
  projectId,
  label,
  icon,
  type,
  className,
  color,
  fullWidth,
  buttonWidth,
  uploadProcess,
  buttonId,
  operationId,
  preCheck,
  overrideMode,
}) => {
  const { uploadFile } = useContext(ProjectsContext);
  const { t } = useTranslation("global");
  const { enqueueSnackbar } = useSnackbar();
  const [uploading, setUploading] = useState(false);

  const upload = async (e: FormEvent<HTMLInputElement | HTMLTextAreaElement>): Promise<void> => {
    const { files } = e.target as HTMLInputElement;
    if (!files || files.length === 0) return;
    setUploading(true);
    const file = files[0];
    const result = uploadProcess
      ? await uploadProcess(file)
      : await uploadFile(projectId as string, file, type as AttachmentType, undefined, operationId, overrideMode);
    if (!result) {
      enqueueSnackbar(t("errorWhileUpdating"), { variant: "error" });
    } else {
      enqueueSnackbar(t("updateSuccess"), { variant: "success" });
    }
    setUploading(false);
  };

  const btnId = buttonId || `upload-button-file-${type}${typeof operationId === "string" ? `-${operationId}` : ""}`;

  return (
    <>
      <Input
        id={btnId}
        type="file"
        style={{ display: "none" }}
        onChange={upload}
        inputProps={{
          accept:
            ".pdf,.doc,.docx,.png,.jpg,.jpeg,application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,image/png,image/jpeg",
        }}
      />
      <label htmlFor={btnId}>
        {icon ? (
          uploading ? (
            <CircularProgress size={24} />
          ) : (
            <IconButton
              onClick={(e?: React.SyntheticEvent<Element, Event>) => {
                if (preCheck && !preCheck()) e?.preventDefault();
              }}
              color={color}
              component="span">
              {icon}
            </IconButton>
          )
        ) : (
          <GdButton
            component="span"
            width={buttonWidth === "normal" ? undefined : "large"}
            isLoading={uploading}
            label={label}
            className={className}
            color={color || overrideMode ? "warning" : undefined}
            fullWidth={fullWidth}
            onClick={(e) => {
              if (preCheck && !preCheck()) e?.preventDefault();
            }}
          />
        )}
      </label>
    </>
  );
};

export default UploadFileButton;
