import { Card, Tab, Tabs } from "@mui/material";
import { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";
import AssistantLogs from "./tabs/AssistantLogs";
import AssistantUsers from "./tabs/AssistantUsers";

const logsMode = "logs";

const AiSettings: FC = () => {
  const { t } = useTranslation("aiAssistant");
  const { mode } = useParams() as { mode?: string };
  const initialTab = mode === logsMode ? 2 : 1;
  const [tab, setTab] = useState(initialTab);
  const history = useHistory();

  const handleTabChange = (_: React.SyntheticEvent, newValue: number): void => {
    if (tab !== newValue) {
      setTab(newValue);
      history.push(`/aiSettings${newValue === 2 ? "/logs" : ""}`);
    }
  };

  const tabToDisplay = (): JSX.Element => {
    switch (tab) {
      case 1:
        return <AssistantUsers />;
      case 2:
        return <AssistantLogs />;
      default:
        return <AssistantUsers />;
    }
  };

  return (
    <Card sx={{ width: "1200px", padding: "24px 8px" }}>
      <div
        className="row project-tabs-root"
        style={{ display: "flex", justifyContent: "space-evenly", alignItems: "stretch" }}>
        <Tabs
          orientation="vertical"
          indicatorColor="primary"
          textColor="primary"
          sx={{ borderRight: 1, borderColor: "divider", height: "unset", marginTop: "8px", width: "150px" }}
          onChange={handleTabChange}
          value={tab}>
          <Tab label={t("tabs.users")} value={1} />
          <Tab label={t("tabs.logs")} value={2} />
        </Tabs>
        {tabToDisplay()}
      </div>
    </Card>
  );
};

export default AiSettings;
