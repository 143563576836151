import { Project } from "../generated/graphql";

export enum ManualStatuses {
  TEST = "Test / Démonstration",
  STANDBY = "Stand-by",
  OPEN = "Affaire ouverte",
  SENT = "Dossier envoyé à l'obligé",
  INVSENT = "Factures envoyées à l'obligé",
  PAID = "Prime versée - Projet Clos",
}

export const checkIfProjectIsEmpty = (p: Project): boolean =>
  Boolean(!p.totalComputation?.valuation || p.totalComputation?.valuation === "0" || p.totalComputation?.tooLow);

export const checkIfProjectIsTest = (p: Project): boolean => p.detailedStatus === ManualStatuses.TEST;

export const TO_TRANSFORM = "toTransform";
export const TRANSFORMED = "transformed";

const ProjectStatuses = [
  TO_TRANSFORM,
  TRANSFORMED,
  "Annulé",
  "Vide / Non valorisable",
  ManualStatuses.TEST,
  "Simulation",
  ManualStatuses.STANDBY,
  "Convention à demander",
  "Convention demandée",
  "Convention créée",
  "Convention expirée",
  "Convention refusée",
  "Convention signée",
  "Devis déposé",
  "Devis validé",
  "Devis refusé",
  ManualStatuses.OPEN,
  "Dépôt des PJ en cours",
  "Validation des PJ en cours",
  "AH à initialiser",
  "AH à déposer",
  "AH déposée",
  "AH refusée",
  "Dossier complet",
  ManualStatuses.SENT,
  "Dossier validé par l'obligé",
  "Appel à facturation",
  "Facture prime déposée",
  "Facture prime refusée",
  "Facture prime validée",
  "Prime versée par l'obligé",
  ManualStatuses.INVSENT,
  ManualStatuses.PAID,
];

export default ProjectStatuses;
