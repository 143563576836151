import { Card, Typography, useTheme } from "@mui/material";
import { FC, ReactNode } from "react";

interface ValueCardProps {
  title: string;
  value: string;
  icon?: ReactNode;
  onClick?: () => void;
}

const ValueCard: FC<ValueCardProps> = ({ title, value, icon, onClick }) => {
  const theme = useTheme();
  return (
    <Card
      style={{
        padding: 32,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        width: 200,
        height: 200,
        whiteSpace: "pre-line",
        borderWidth: 3,
        borderRadius: 16,
        color: theme.palette.primary.main,
        borderColor: theme.palette.primary.light,
        textAlign: "center",
      }}
      color="primary"
      variant="outlined"
      className={onClick ? "clickable-opacity" : undefined}
      onClick={onClick}>
      {icon}
      <Typography variant="h4" style={{ fontWeight: "bold", margin: "8px 0" }}>
        {value}
      </Typography>
      <Typography variant="h5">{title}</Typography>
    </Card>
  );
};

ValueCard.defaultProps = { icon: undefined, onClick: undefined };

export default ValueCard;
