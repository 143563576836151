import { ApolloError } from "@apollo/client";
import { createContext, FC, PropsWithChildren, useState } from "react";
import { User, UserUpdate, useUserCreateMeMutation, useUserMeQuery, useUserUpdateMutation } from "../generated/graphql";

export interface UserContextProps {
  userInfo: User | null;
  getUser: () => Promise<boolean>;
  replaceUserInfo: (newUser: User | null) => void;
  createUser: () => Promise<boolean>;
  updateUser: (user: UserUpdate) => Promise<User | null>;
  deleteUserInfo: () => void;
  loading: boolean;
  error?: ApolloError;
  mutationError?: ApolloError;
  updateError?: ApolloError;
}

const initialContext: UserContextProps = {
  userInfo: null,
  getUser: () => Promise.resolve(false),
  replaceUserInfo: () => {},
  createUser: () => Promise.resolve(false),
  updateUser: () => Promise.resolve(null),
  deleteUserInfo: () => {},
  loading: false,
};

export const UserContext = createContext<UserContextProps>(initialContext);

export const UserProvider: FC<PropsWithChildren> = ({ children }) => {
  const [userInfo, setUserInfo] = useState<User | null>(null);
  const { loading, error, refetch } = useUserMeQuery({ skip: true });
  const [userCreateMe, { error: mutationError }] = useUserCreateMeMutation({
    errorPolicy: "all",
  });
  const [userUpdate, { error: updateError }] = useUserUpdateMutation({ errorPolicy: "all" });

  const getUser = async (): Promise<boolean> => {
    const result = await refetch();
    if (result && result.data) {
      setUserInfo(result.data.userMe?.user || null);
    }
    return result.data.userMe?.admin || false;
  };

  const createUser = async (): Promise<boolean> => {
    const result = await userCreateMe();
    if (result.data?.userCreateMe) {
      setUserInfo(result.data?.userCreateMe?.user || null);
      return result.data?.userCreateMe?.admin || false;
    }
    return false;
  };

  const updateUser = async (user: UserUpdate): Promise<User | null> => {
    const result = await userUpdate({ variables: { user } });
    if (result.data?.userUpdate) {
      setUserInfo(result.data?.userUpdate);
      return result.data?.userUpdate;
    }
    return null;
  };

  const deleteUserInfo = (): void => {
    setUserInfo(null);
  };

  const replaceUserInfo = (newUser: User | null): void => {
    setUserInfo(newUser);
  };

  return (
    <UserContext.Provider
      value={{
        userInfo,
        getUser,
        replaceUserInfo,
        createUser,
        deleteUserInfo,
        loading,
        error,
        mutationError,
        updateUser,
        updateError,
      }}>
      {children}
    </UserContext.Provider>
  );
};
