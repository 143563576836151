import { Tooltip, Typography } from "@mui/material";
import { FC } from "react";
import { Link } from "react-router-dom";
import { CustomProject } from "../../data/contexts/ProjectsContext";
import StatusLabel from "../../utils/StatusLabel";

interface ProjectsWithIndexesProps {
  project: CustomProject;
  indexStart?: number | null;
  indexEnd?: number | null;
  projectComment?: string | null;
  projectCommentDate?: string | null;
}
interface GanttProjectProps {
  project: ProjectsWithIndexesProps;
  rangeLength: number;
}

const GanttProject: FC<GanttProjectProps> = ({ project, rangeLength }) => {
  const projectGantt = project.project;

  if (project.indexStart && project.indexEnd) {
    const notStarted = project.indexStart === -2;
    const finishedBefore = project.indexEnd === -2;
    let columnIndexStart = project.indexStart + 1;
    let columnIndexEnd = project.indexEnd + 2;
    if (columnIndexStart === 0) {
      columnIndexStart = 1;
    }
    if (columnIndexEnd === 1) {
      columnIndexEnd = rangeLength + 1;
    }
    const grid = {
      display: "grid",
      grid: `1fr / repeat(${rangeLength}, 1fr)`,
      borderBottom: "solid grey 1px",
      backgroundColor: "rgba(0, 0, 0, 0.08)",
      paddingTop: "4px",
      paddingBottom: "4px",
    };
    const projectStyle = {
      backgroundColor: "#FFF",
      borderRadius: "4px",
      gridColumn: `${columnIndexStart} / ${columnIndexEnd}`,
      display: `flex`,
      justifyContent: "flex-start",
      padding: "8px",
      boxShadow: "0px 2px 2px -1px rgb(0 0 0 / 20%), 0px 2px 3px 0px rgb(0 0 0 / 14%)",
    };
    const outscopeProjectStyle = {
      display: `flex`,
      justifyContent: notStarted ? "flex-end" : "flex-start",
      gridColumn: `1 / ${rangeLength + 1}`,
      padding: "8px",
    };

    const projectLabel = (
      <Typography style={{ color: "black", textAlign: "center" }} noWrap>
        {finishedBefore || project.indexStart === -1 ? "< " : ""}
        {projectGantt.name} - <StatusLabel status={projectGantt.detailedStatus} />{" "}
        {project.projectComment ? (
          <Tooltip
            title={
              <Typography>
                {/* eslint-disable-next-line react/no-danger */}
                <span dangerouslySetInnerHTML={{ __html: project.projectComment }} />
              </Typography>
            }
            arrow>
            <Typography style={{ color: "black", display: "inline" }}>
              {project.projectCommentDate ? ` - ${project.projectCommentDate}` : ""}
            </Typography>
          </Tooltip>
        ) : undefined}
        {notStarted || project.indexEnd === -1 ? " >" : ""}
      </Typography>
    );

    return (
      <Link to={`/projects/${projectGantt.id}`}>
        <div style={grid}>
          <div style={notStarted || finishedBefore ? outscopeProjectStyle : projectStyle}>{projectLabel}</div>
        </div>
      </Link>
    );
  }
  return null;
};

export default GanttProject;
