import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { useSnackbar } from "notistack";
import { ChangeEvent, FC, useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import PhoneTextField from "../../common/PhoneTextField";
import SiretInput from "../../common/SiretInput";
import { ProjectsContext } from "../../data/contexts/ProjectsContext";
import { companyToCompanyInput } from "../../data/dataConvertors";
import { Gender, SignerInput } from "../../data/generated/graphql";
import CompanyTypeList from "../../data/lists/CompanyTypeList";
import RCSList from "../../data/lists/RCSList";
import GdButton from "../../utils/GdButton";
import { TextMaskNumber, TextMaskZipCode } from "../../utils/TextMasks";
import WarningCard from "../../utils/WarningCard";

interface SignerDetailsProps {
  projectId: string;
  signer: SignerInput;
  onClose: () => void;
  mySigner?: boolean;
}

const SignerDetails: FC<SignerDetailsProps> = ({ projectId, signer: inSigner, onClose, mySigner }) => {
  const { t } = useTranslation(["user", "project", "global"]);
  const { enqueueSnackbar } = useSnackbar();
  const [signer, setSigner] = useState({ ...inSigner, role: mySigner ? t("project:manager") : inSigner.role });
  const [isUpdating, setIsUpdating] = useState(false);
  const { updateProject } = useContext(ProjectsContext);

  const validate = async (): Promise<void> => {
    setIsUpdating(true);
    const result = await updateProject(
      mySigner ? { id: projectId, userSigner: signer } : { id: projectId, clientSigner: signer },
    );
    setIsUpdating(false);
    if (!result) {
      enqueueSnackbar(t("project:errorWhileUpdating"), { variant: "error" });
    } else {
      onClose();
    }
  };

  const changeSignerField = (field: string) => (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | string) =>
    setSigner({ ...signer, [field]: typeof e === "string" ? e : e.target.value });
  const changeSignerFieldFromSelect = (field: string) => (e: SelectChangeEvent<string | null>) =>
    setSigner({ ...signer, [field]: e.target.value });
  const changeCompanyField = (field: string) => (e: ChangeEvent<HTMLInputElement>) =>
    setSigner({ ...signer, company: { ...signer.company, [field]: e.target.value } });
  const changeCompanyFieldFromSelect = (field: string) => (e: SelectChangeEvent<string | null>) =>
    setSigner({ ...signer, company: { ...signer.company, [field]: e.target.value } });

  return (
    <>
      <Typography variant="h6" className="margin-bottom">
        {t(mySigner ? "project:mySigner" : "project:clientSigner")}
      </Typography>
      <WarningCard
        projectLabelKey={mySigner ? "mySignerChief" : "clientSignerHabilitation"}
        className="big-margin-bottom"
      />
      <div className="signer-form-row">
        <FormControl style={{ width: 100 }}>
          <InputLabel id="civ-select-label">{t("civility")}</InputLabel>
          <Select
            labelId="civ-select-label"
            label={t("civility")}
            variant="outlined"
            value={signer.gender}
            onChange={changeSignerFieldFromSelect("gender")}
            style={{ width: 100 }}>
            <MenuItem value={undefined}>
              <em>{t("civ.select")}</em>
            </MenuItem>
            <MenuItem value={Gender.M}>{t("civ.mr")}</MenuItem>
            <MenuItem value={Gender.F}>{t("civ.mrs")}</MenuItem>
          </Select>
        </FormControl>
        <TextField
          label={t("lastName")}
          variant="outlined"
          value={signer.lastName}
          onChange={changeSignerField("lastName")}
          className="not-only-textfield"
          autoComplete="off"
        />
        <TextField
          label={t("firstName")}
          variant="outlined"
          value={signer.firstName}
          onChange={changeSignerField("firstName")}
          className="not-only-textfield"
          autoComplete="off"
        />
      </div>
      <div className="signer-form-row">
        <PhoneTextField
          style={{ width: 180 }}
          label={t("phone")}
          value={signer.phone || ""}
          onChange={changeSignerField("phone")}
        />
        <TextField
          label={t("email")}
          variant="outlined"
          value={signer.email}
          onChange={changeSignerField("email")}
          className="not-only-textfield"
          autoComplete="off"
        />
        {mySigner ? (
          <Tooltip title={<span>{t("project:managerTooltip")}</span>} arrow placement="top">
            <TextField
              label={t("role")}
              variant="outlined"
              value={signer.role}
              className="not-only-textfield"
              disabled
              autoComplete="off"
            />
          </Tooltip>
        ) : (
          <TextField
            label={t("role")}
            variant="outlined"
            value={signer.role}
            onChange={changeSignerField("role")}
            className="not-only-textfield"
            autoComplete="off"
          />
        )}
      </div>
      <div className="signer-form-row">
        <TextField
          style={{ width: 180 }}
          label={t("project:company.name")}
          variant="outlined"
          value={signer.company.name}
          onChange={changeCompanyField("name")}
          autoComplete="off"
          InputLabelProps={{ shrink: (signer.company?.name && signer.company.name.length > 0) || false }}
        />
        <FormControl className="not-only-textfield">
          <InputLabel id="company-type-label">{t("project:company.companyType")}</InputLabel>
          <Select
            labelId="company-type-label"
            label={t("project:company.companyType")}
            variant="outlined"
            value={signer.company.companyType}
            onChange={changeCompanyFieldFromSelect("companyType")}>
            <MenuItem value={undefined}>
              <em>{t("civ.select")}</em>
            </MenuItem>
            {CompanyTypeList.map((ct) => (
              <MenuItem value={ct.key} key={ct.key}>
                {ct.key === ct.name ? ct.key : `${ct.name} (${ct.key})`}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <TextField
          label={t("project:company.shareCapital")}
          variant="outlined"
          value={signer.company.shareCapital}
          onChange={changeCompanyField("shareCapital")}
          className="not-only-textfield"
          InputProps={{
            inputComponent: TextMaskNumber as any,
          }}
          autoComplete="off"
        />
      </div>
      <div className="signer-form-row">
        <SiretInput
          siret={signer.company?.siret || ""}
          setSiret={(siret) => setSigner({ ...signer, company: { ...signer.company, siret } })}
          onCompanyFetched={(company) =>
            setSigner({
              ...signer,
              company: companyToCompanyInput({ ...signer.company, ...company }),
            })
          }
          inputStyle={{ width: 300 }}
        />
        <FormControl className="not-only-textfield">
          <InputLabel id="company-rcs-label">{t("project:company.rcs")}</InputLabel>
          <Select
            labelId="company-rcs-label"
            label={t("project:company.rcs")}
            variant="outlined"
            value={signer.company.rcs}
            onChange={changeCompanyFieldFromSelect("rcs")}>
            <MenuItem value={undefined}>
              <em>{t("civ.select")}</em>
            </MenuItem>
            {RCSList.map((r) => (
              <MenuItem value={r} key={r}>
                {r}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
      <div className="signer-form-row">
        <TextField
          style={{ width: 300 }}
          label={t("project:company.address")}
          variant="outlined"
          value={signer.company.address}
          onChange={changeCompanyField("address")}
          autoComplete="off"
          InputLabelProps={{ shrink: (signer.company?.address && signer.company.address.length > 0) || false }}
        />
        <TextField
          style={{ width: 100 }}
          label={t("project:company.zipCode")}
          variant="outlined"
          value={signer.company.zipCode}
          onChange={changeCompanyField("zipCode")}
          InputProps={{
            inputComponent: TextMaskZipCode as any,
          }}
          className="not-only-textfield"
          autoComplete="off"
          InputLabelProps={{ shrink: (signer.company?.zipCode && signer.company.zipCode.length > 0) || false }}
        />
        <TextField
          label={t("project:company.city")}
          variant="outlined"
          value={signer.company.city}
          onChange={changeCompanyField("city")}
          className="not-only-textfield"
          autoComplete="off"
          InputLabelProps={{ shrink: (signer.company?.city && signer.company.city.length > 0) || false }}
        />
      </div>
      <GdButton label={t("global:validate")} onClick={validate} isLoading={isUpdating} />
    </>
  );
};

SignerDetails.defaultProps = { mySigner: false };

export default SignerDetails;
