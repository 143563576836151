import { useAccount, useMsal } from "@azure/msal-react";
import { enqueueSnackbar } from "notistack";
import { FC, PropsWithChildren, createContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { MSAL_DEFAULT_SCOPES } from "../../auth/authConfig";
import { Lvl, log } from "../../utils/log";

export interface TokenContextProps {
  token: string;
  expires: Date | null;
  setAuthToken: (token: string, expires: Date | null) => void;
  getToken: () => Promise<string | null>;
}

const initialContext: TokenContextProps = {
  token: "",
  expires: new Date(0),
  setAuthToken: () => {},
  getToken: () => Promise.resolve(null),
};

export const TokenContext = createContext<TokenContextProps>(initialContext);

interface AuthInfo {
  token: string;
  expires: Date | null;
}

export const TokenProvider: FC<PropsWithChildren> = ({ children }) => {
  const [authInfo, setAuthInfo] = useState<AuthInfo>({ token: "", expires: null });
  const { instance, accounts } = useMsal();
  const account = useAccount(accounts[0] || {});
  const { t } = useTranslation("global");

  const setAuthToken = (inToken: string, inExpires: Date | null): void => {
    log(inToken, Lvl.INFO);
    if (inExpires) log(inExpires.toLocaleString(), Lvl.INFO);
    setAuthInfo({ token: inToken, expires: inExpires });
  };

  const showError = (err: unknown, network?: boolean): void => {
    log("Apollo Error", Lvl.ERROR, err);
    if (network) enqueueSnackbar(t("networkError"), { variant: "error" });
  };

  const getToken = async (): Promise<string> => {
    const now = new Date();
    if (authInfo.expires === null || authInfo.expires > now) {
      log("Token is valid until", Lvl.DEBUG, authInfo.expires?.toLocaleString());
      // Token is still valid
      return Promise.resolve(authInfo.token);
    }
    if (account) {
      try {
        log("Renewing token...");
        const response = await instance.acquireTokenSilent({
          ...MSAL_DEFAULT_SCOPES,
          account: account || undefined,
        });
        if (response && response.accessToken) {
          setAuthToken(response.accessToken, response.expiresOn);
          return response.accessToken;
        }
        return "";
      } catch (err) {
        showError(err);
        // Token renewing failed, ask user to sign in again
        instance.loginRedirect({
          ...MSAL_DEFAULT_SCOPES,
        });
        return Promise.resolve("");
      }
    }
    return Promise.resolve("");
  };

  return (
    <TokenContext.Provider
      value={{
        token: authInfo.token,
        expires: authInfo.expires,
        setAuthToken,
        getToken,
      }}>
      {children}
    </TokenContext.Provider>
  );
};
