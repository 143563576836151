import { useSnackbar } from "notistack";
import { FC, useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { ProjectsContext } from "../../data/contexts/ProjectsContext";
import { dataDateConvertToDate, dateConvertToDataDate, detailsToDetailsInput } from "../../data/dataConvertors";
import { Project } from "../../data/generated/graphql";
import GdAlert from "../../utils/GdAlert";
import GdDatePicker from "../../utils/GdDatePicker";
import { log, Lvl } from "../../utils/log";

interface ProjectDateModalProps {
  open: boolean;
  onClose: () => void;
  project: Project;
}

export const defaultDetails: any = {
  isMain: true,
  startDate: "",
  endDate: "",
};

const ProjectDateModal: FC<ProjectDateModalProps> = ({ open, onClose, project }) => {
  const { t } = useTranslation("project");
  const { endDateNum, startDateNum, ...cleanDetails } = project.details || defaultDetails;
  const [details, setDetails] = useState(detailsToDetailsInput(cleanDetails));
  const [modified, setModified] = useState(false);
  const [datesIsLoading, setDatesIsLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const { updateProject } = useContext(ProjectsContext);

  const validate = async (): Promise<void> => {
    setDatesIsLoading(true);
    const result = await updateProject({
      id: project.id,
      details,
    });

    if (!result) {
      log("Error while updating project", Lvl.ERROR);
      enqueueSnackbar(t("global:errorWhileUpdating"), { variant: "error" });
    } else {
      enqueueSnackbar(t("global:updateSuccess"), { variant: "success" });
      setModified(false);
    }
    setDatesIsLoading(false);
    onClose();
  };

  return (
    <GdAlert
      open={open}
      onClose={onClose}
      title={t("projectDateModalTitle")}
      body={
        <div style={{ display: "flex", flexDirection: "column", marginTop: "16px", gap: "24px" }}>
          <GdDatePicker
            disabled={false}
            label={t("startDate")}
            className="left-field"
            value={dataDateConvertToDate(details.startDate)}
            onChange={(d: Date | null) => {
              if (Number.isNaN(d?.getTime())) return;
              const date = dateConvertToDataDate(d);
              if (date < "1975-01-01") return;
              let { endDate } = details;
              if (date && (!endDate || endDate < date)) {
                const oneWeekAfter = new Date((d?.getTime() || 0) + 7 * 24 * 3600 * 1000);
                endDate = dateConvertToDataDate(oneWeekAfter);
              }
              if (details.startDate !== date || details.endDate !== endDate) {
                setDetails({ ...details, startDate: date, endDate });
                setModified(true);
              }
            }}
          />
          <GdDatePicker
            label={t("endDate")}
            className="right-field"
            disabled={false}
            minDate={dataDateConvertToDate(details?.startDate) || new Date()}
            value={dataDateConvertToDate(details?.endDate)}
            onChange={(d) => {
              const endDate = dateConvertToDataDate(d);
              if (details.endDate !== endDate) {
                setDetails({ ...details, endDate });
                setModified(true);
              }
            }}
          />
        </div>
      }
      okButtonClick={() => validate()}
      okButtonLoading={datesIsLoading}
      cancelButton
      okButtonDisabled={!modified}
    />
  );
};

export default ProjectDateModal;
