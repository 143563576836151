import { FC, useContext } from "react";
import { useTranslation } from "react-i18next";
import { ProjectsContext } from "../data/contexts/ProjectsContext";
import { dateConvertToString } from "../data/dataConvertors";
import { UserLightFragment } from "../data/generated/graphql";
import GdDataGrid, { GdColumn, GdDataExtractor } from "../utils/GdDataGrid";
import { checkIfProjectIsEmpty, checkIfProjectIsTest } from "../data/lists/ProjectStatuses";

interface UsersSummaryProps {
  users: UserLightFragment[];
}

const UsersSummary: FC<UsersSummaryProps> = ({ users }) => {
  const { projects } = useContext(ProjectsContext);
  const { t } = useTranslation("user");

  const createCol = (key: string, dataExtractor?: GdDataExtractor): GdColumn => ({
    key,
    label: t(`usersCols.${key}`),
    dataExtractor,
  });

  const userColumns: GdColumn[] = [
    createCol("name"),
    createCol("email"),
    createCol("role"),
    createCol("joined", (d) => dateConvertToString((d.joined as string) || "")),
    createCol("nbProjects", (d) =>
      projects
        .filter((p) => p.userId === d.id && p.active && !checkIfProjectIsEmpty(p) && !checkIfProjectIsTest(p))
        .length.toString(),
    ),
    createCol("status", (d) => t(`userStatus.${d.active}`)),
  ];

  return <GdDataGrid columns={userColumns} data={users} idExtractor={(u) => u.id as string} link="/users/" hover />;
};

export default UsersSummary;
