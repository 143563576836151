const RCSList: string[] = [
  "AGEN",
  "AIX-EN-PROVENCE",
  "AJACCIO",
  "ALBI",
  "ALENCON",
  "AMIENS",
  "ANGERS",
  "ANGOULEME",
  "ANNECY",
  "ANTIBES",
  "ARRAS",
  "AUBENAS",
  "AUCH",
  "AURILLAC",
  "AUXERRE",
  "AVIGNON",
  "BAR-LE-DUC",
  "BASSE-TERRE",
  "BASTIA",
  "BAYONNE",
  "BEAUVAIS",
  "BELFORT",
  "BERGERAC",
  "BERNAY",
  "BESANCON",
  "BEZIERS",
  "BLOIS",
  "BOBIGNY",
  "BORDEAUX",
  "BOULOGNE-SUR-MER",
  "BOURG-EN-BRESSE",
  "BOURGES",
  "BREST",
  "BRIEY",
  "BRIVE",
  "CAEN",
  "CAHORS",
  "CANNES",
  "CARCASSONNE",
  "CASTRES",
  "CAYENNE",
  "CHALON-SUR-SAONE",
  "CHALONS-EN-CHAMPAGNE",
  "CHAMBERY",
  "CHARTRES",
  "CHATEAUROUX",
  "CHAUMONT",
  "CHERBOURG",
  "CLERMONT-FERRAND",
  "COLMAR",
  "COMPIEGNE",
  "COUTANCES",
  "CRETEIL",
  "CUSSET",
  "DAX",
  "DIEPPE",
  "DIJON",
  "DOUAI",
  "DRAGUIGNAN",
  "DUNKERQUE",
  "EPINAL",
  "EVREUX",
  "EVRY",
  "FOIX",
  "FORT-DE-FRANCE",
  "FREJUS",
  "GAP",
  "GRASSE",
  "GRENOBLE",
  "GUERET",
  "LA ROCHE-SUR-YON",
  "LA ROCHELLE",
  "LAVAL",
  "LE HAVRE",
  "LE MANS",
  "LE PUY-EN-VELAY",
  "LIBOURNE",
  "LILLE METROPOLE",
  "LIMOGES",
  "LISIEUX",
  "LONS-LE-SAUNIER",
  "LORIENT",
  "LYON",
  "MACON",
  "MAMOUDZOU",
  "MANOSQUE",
  "MARSEILLE",
  "MEAUX",
  "MELUN",
  "MENDE",
  "METZ",
  "MONT-DE-MARSAN",
  "MONTAUBAN",
  "MONTLUCON",
  "MONTPELLIER",
  "MULHOUSE",
  "NANCY",
  "NANTERRE",
  "NANTES",
  "NARBONNE",
  "NEVERS",
  "NICE",
  "NIMES",
  "NIORT",
  "ORLEANS",
  "PARIS",
  "PAU",
  "PERIGUEUX",
  "PERPIGNAN",
  "POINTE-A-PITRE",
  "POITIERS",
  "PONTOISE",
  "QUIMPER",
  "REIMS",
  "RENNES",
  "ROANNE",
  "RODEZ",
  "ROMANS",
  "ROUEN",
  "SAINT MALO",
  "SAINT-BRIEUC",
  "SAINT-DENIS-DE-LA-REUNION",
  "SAINT-ETIENNE",
  "SAINT-NAZAIRE",
  "SAINT-PIERRE-DE-LA-REUNION",
  "SAINT-QUENTIN",
  "SAINTES",
  "SALON-DE-PROVENCE",
  "SARREGUEMINES",
  "SAVERNE",
  "SEDAN",
  "SENS",
  "SOISSONS",
  "STRASBOURG",
  "TARASCON",
  "TARBES",
  "THIONVILLE",
  "THONON-LES-BAINS",
  "TOULON",
  "TOULOUSE",
  "TOURS",
  "TROYES",
  "VALENCIENNES",
  "VANNES",
  "VERSAILLES",
  "VESOUL",
  "VIENNE",
  "VILLEFRANCHE-TARARE",
];

export default RCSList;
