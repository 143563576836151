import { BusinessCenter, Factory, FormatListBulleted, People } from "@mui/icons-material";
import { Typography } from "@mui/material";
import { FC, useContext } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { ProjectsContext } from "../data/contexts/ProjectsContext";
import { SettingsContext } from "../data/contexts/SettingsContext";
import { UsersContext } from "../data/contexts/UsersContext";
import { formatNumber } from "../data/dataConvertors";
import BaseCard from "../utils/BaseCard";
import ValueCard from "../utils/ValueCard";

import "./Dashboard.css";
import UsageChart from "./UsageChart";

const Dashboard: FC = () => {
  const { projects, actions } = useContext(ProjectsContext);
  const { settings } = useContext(SettingsContext);
  const { users } = useContext(UsersContext);
  const { t } = useTranslation("global");
  const history = useHistory();
  const activeProjects = projects.filter((p) => p.active);
  const activeUsers = users.filter((u) => u.active);

  return (
    <>
      <div style={{ maxWidth: 1100, maxHeight: 700 }}>
        <iframe
          src="https://app.powerbi.com/view?r=eyJrIjoiODM2NjE2ZGUtZjcyNy00NTdhLTg2Y2MtYmMxZGNhMjM1YmYwIiwidCI6ImNhZTdkMDYxLTA4ZjMtNDBkZC04MGMzLTNjMGI4ODg5MjI0YSIsImMiOjh9"
          width="1100"
          height="700"
          title="Greendeed KPI"
        />
      </div>
      <div className="dashboard-figures">
        <ValueCard
          title={t("nbActions", { count: actions.length })}
          value={formatNumber(actions.length)}
          icon={<FormatListBulleted fontSize="large" />}
          onClick={() => history.push("/actions")}
        />
        <ValueCard
          title={t("nbProjects", { count: activeProjects.length })}
          value={formatNumber(activeProjects.length)}
          icon={<BusinessCenter fontSize="large" />}
          onClick={() => history.push("/projects")}
        />
        <ValueCard
          title={t("nbUsers", { count: activeUsers.length })}
          value={formatNumber(activeUsers.length)}
          icon={<People fontSize="large" />}
          onClick={() => history.push("/users")}
        />
        <ValueCard
          title={t("nbObliged", { count: settings.obliged.length })}
          value={formatNumber(settings.obliged.length)}
          icon={<Factory fontSize="large" />}
          onClick={() => history.push("/obliged")}
        />
      </div>
      <BaseCard>
        <Typography variant="h6" className="margin-bottom">
          {t("globalUsage")}
        </Typography>
        <div style={{ margin: "0 96px" }}>
          <UsageChart usage={settings.usage} />
        </div>
      </BaseCard>
    </>
  );
};

export default Dashboard;
