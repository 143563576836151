import { Paper, Table, TableBody, TableCell, TableRow, Typography } from "@mui/material";
import { FC, PropsWithChildren, ReactNode } from "react";

export interface DataTableLine {
  label: ReactNode;
  data: ReactNode;
}

export interface DataTableProps {
  data: DataTableLine[];
}

const DataTableCell: FC<PropsWithChildren> = ({ children }) => (
  <TableCell>{typeof children === "string" ? <Typography>{children}</Typography> : children}</TableCell>
);

const GdDataTable: FC<DataTableProps> = ({ data }) => (
  <Table component={Paper}>
    <TableBody>
      {data.map((d) => (
        <TableRow key={JSON.stringify(d.label)}>
          <DataTableCell>{d.label}</DataTableCell>
          <DataTableCell>{d.data}</DataTableCell>
        </TableRow>
      ))}
    </TableBody>
  </Table>
);

export default GdDataTable;
