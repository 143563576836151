import { FormControl, InputLabel, MenuItem, Select, TextField, Typography } from "@mui/material";
import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { BonusDetailsInput } from "../../data/generated/graphql";
import GdButton from "../../utils/GdButton";
import GdModal from "../../utils/GdModal";
import { TextMaskNumber } from "../../utils/TextMasks";
import WarningCard from "../../utils/WarningCard";

interface BonusModalProps {
  open: boolean;
  onClose: () => void;
  addBonus: (newBonus: BonusDetailsInput) => void;
  isLoading: boolean;
  bonus?: BonusDetailsInput;
}
export enum JustificationType {
  zni = "zni",
  cdp = "cdp",
  other = "other",
}

const BonusModal: FC<BonusModalProps> = ({ open, onClose, addBonus, isLoading, bonus }) => {
  const { t } = useTranslation("project");
  const isNewBonus = typeof bonus === "undefined";
  const [multiplicator, setMultiplicator] = useState("");
  const [justification, setJustification] = useState<JustificationType | "">("");
  const [otherJustification, setOtherJustification] = useState<string>("");

  const isDisabled =
    multiplicator.length === 0 ||
    justification.length === 0 ||
    (justification === JustificationType.other && otherJustification.length === 0);

  useEffect(() => {
    if (isNewBonus) {
      // New bonus
      setMultiplicator("");
      setJustification("");
      setOtherJustification("");
    } else if (Object.values(JustificationType).includes(bonus?.justification as JustificationType)) {
      // Value is among standard justifications
      setMultiplicator(bonus?.multiplicator || "");
      setJustification(bonus?.justification as JustificationType);
    } else {
      setMultiplicator(bonus?.multiplicator || "");
      setJustification(JustificationType.other);
      setOtherJustification(bonus?.justification || "");
    }
  }, [bonus, isNewBonus, open]);

  const handleAddBonus = (): void => {
    const tempBonus = {
      justification: justification === JustificationType.other ? otherJustification : justification,
      multiplicator,
    };
    addBonus(tempBonus);
  };

  return (
    <GdModal
      onClose={onClose}
      open={open}
      // eslint-disable-next-line react/no-children-prop
      children={
        <>
          <Typography variant="h5">{t(`bonus.${isNewBonus ? "add" : "edit"}`)}</Typography>
          <FormControl className="margin-top">
            <InputLabel id="input-label-justification">{t("bonus.justification.label")}</InputLabel>
            <Select
              value={justification}
              labelId="input-label-justification"
              id="select-justification"
              label={t("bonus.justification.label")}
              onChange={(e) => setJustification(e.target.value as JustificationType)}>
              {Object.keys(JustificationType).map((j, i) => (
                // eslint-disable-next-line react/no-array-index-key
                <MenuItem key={i} value={j}>
                  {t(`bonus.justification.${j}`)}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          {justification === JustificationType.other ? (
            <TextField
              className="margin-top"
              value={otherJustification}
              defaultValue={otherJustification}
              label={t("bonus.justification.otherLabel")}
              onChange={(e) => setOtherJustification(e.target.value)}
            />
          ) : undefined}
          <TextField
            className="margin-top big-margin-bottom"
            value={multiplicator}
            defaultValue={multiplicator}
            label={t("bonus.multiplicator")}
            onChange={(e) => setMultiplicator(e.target.value)}
            InputProps={{
              inputComponent: TextMaskNumber as any,
            }}
          />
          <WarningCard projectLabelKey="bonusMultiplicatorExplanation" className="big-margin-bottom small-modal" info />
          <GdButton
            label={isNewBonus ? t("add") : t("edit")}
            onClick={handleAddBonus}
            isLoading={isLoading}
            disabled={isDisabled}
          />
        </>
      }
    />
  );
};

BonusModal.defaultProps = {
  bonus: undefined,
};

export default BonusModal;
