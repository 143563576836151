import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { Close } from "@mui/icons-material";
import { CssBaseline, IconButton, ThemeProvider } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { fr } from "date-fns/locale";
import { SnackbarKey, SnackbarProvider } from "notistack";
import { FC, PropsWithChildren, createRef } from "react";
import Theme from "./Theme";
import authConfig from "./auth/authConfig";
import GdApolloProvider from "./data/GdApolloProvider";
import { ArticlesProvider } from "./data/contexts/ArticlesContext";
import { ProjectsProvider } from "./data/contexts/ProjectsContext";
import { SettingsProvider } from "./data/contexts/SettingsContext";
import { TokenProvider } from "./data/contexts/TokenContext";
import { UserProvider } from "./data/contexts/UserContext";
import { UsersProvider } from "./data/contexts/UsersContext";
import "./translation";
import { AssistantProvider } from "./data/contexts/AssistantContext";

const notistackRef = createRef<SnackbarProvider>();
const onClickDismiss = (key: SnackbarKey) => () => {
  notistackRef.current?.closeSnackbar(key);
};

const pca = new PublicClientApplication(authConfig);

const Wrapper: FC<PropsWithChildren> = ({ children }) => {
  return (
    <MsalProvider instance={pca}>
      <ThemeProvider theme={Theme}>
        <CssBaseline />
        <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={fr}>
          <TokenProvider>
            <SnackbarProvider
              ref={notistackRef}
              maxSnack={3}
              anchorOrigin={{ horizontal: "center", vertical: "top" }}
              preventDuplicate
              autoHideDuration={10000}
              action={(key: SnackbarKey) => (
                <IconButton sx={{ color: "#fff" }} onClick={onClickDismiss(key)}>
                  <Close />
                </IconButton>
              )}>
              <GdApolloProvider>
                <ProjectsProvider>
                  <UsersProvider>
                    <UserProvider>
                      <ArticlesProvider>
                        <SettingsProvider>
                          <AssistantProvider>{children}</AssistantProvider>
                        </SettingsProvider>
                      </ArticlesProvider>
                    </UserProvider>
                  </UsersProvider>
                </ProjectsProvider>
              </GdApolloProvider>
            </SnackbarProvider>
          </TokenProvider>
        </LocalizationProvider>
      </ThemeProvider>
    </MsalProvider>
  );
};

export default Wrapper;
