import { Close, Delete, ExpandMore, InsertDriveFile } from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Avatar,
  CircularProgress,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Modal,
  OutlinedInput,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import "react-quill/dist/quill.snow.css";
import { SnackbarKey, useSnackbar } from "notistack";
import { FC, useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { ArticlesContext } from "../data/contexts/ArticlesContext";
import { articleToArticleInput } from "../data/dataConvertors";
import { Article, ArticleInput, ArticleType } from "../data/generated/graphql";
import BaseCard from "../utils/BaseCard";
import GdButton from "../utils/GdButton";
import UploadFileButton from "../projects/documents/UploadFileButton";
import RichTextEditor from "../common/RichTextEditor";

interface ArticleItemProps {
  article: Article;
  onDelete: () => void;
  onModify: () => void;
  isDeleting: boolean;
}

const ArticleItem: FC<ArticleItemProps> = ({ article, onDelete, onModify, isDeleting }) => {
  const { t } = useTranslation("global");
  const { uploadAttachment, removeAttachment } = useContext(ArticlesContext);
  const [removing, setRemoving] = useState<string | null>(null);
  const deleteAttachment = async (aId: string): Promise<void> => {
    setRemoving(aId);
    await removeAttachment(article.id, aId);
    setRemoving(null);
  };
  return (
    <Accordion>
      <AccordionSummary expandIcon={<ExpandMore />}>
        <Typography variant="h6">{article.title}</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Typography className="ql-editor">
          {/* eslint-disable-next-line react/no-danger */}
          <span dangerouslySetInnerHTML={{ __html: article.body }} />
        </Typography>
        <div className="big-margin-top">
          <Typography style={{ fontWeight: "bold" }}>
            {article.attachments && article.attachments.length > 0 ? t("attachmentsTwo") : t("attachmentsEmpty")}
          </Typography>
          {article.attachments && article.attachments.length > 0 ? (
            <List>
              {article.attachments.map((a) => (
                <ListItem
                  key={a.id}
                  secondaryAction={
                    removing === a.id ? (
                      <CircularProgress size={24} />
                    ) : (
                      <IconButton edge="end" onClick={() => deleteAttachment(a.id)} disabled={Boolean(removing)}>
                        <Delete />
                      </IconButton>
                    )
                  }>
                  <ListItemAvatar>
                    <Avatar>
                      <InsertDriveFile />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary={
                      <a href={a.url as string} target="_blank" rel="noreferrer" className="attachment">
                        {a.name}
                      </a>
                    }
                  />
                </ListItem>
              ))}
            </List>
          ) : undefined}
        </div>
        <div className="row space-evenly margin-top">
          <UploadFileButton
            label={t("attachToArticle")}
            color="secondary"
            uploadProcess={(file: File) => uploadAttachment(article.id, file)}
            buttonId={`add-attachment-${article.id}`}
            buttonWidth="normal"
          />
          <GdButton label={t("deleteArticle")} color="inherit" onClick={onDelete} isLoading={isDeleting} />
          <GdButton label={t("modifyArticle")} onClick={onModify} />
        </div>
      </AccordionDetails>
    </Accordion>
  );
};

interface ArticlesProps {
  type: ArticleType;
}

const Articles: FC<ArticlesProps> = ({ type }) => {
  const [updating, setUpdating] = useState(false);
  const [title, setTitle] = useState("");
  const [body, setBody] = useState("");
  const [articleId, setArticleId] = useState("");
  const [filter, setFilter] = useState("");
  const [deletingArticleId, setDeletingArticleId] = useState("");
  const [open, setOpen] = useState(false);
  const { t } = useTranslation("global");
  const { articles, updateArticle } = useContext(ArticlesContext);
  const { enqueueSnackbar } = useSnackbar();

  const finalArticles = articles
    .filter((a) => a.type === type && a.active)
    .filter(
      (a) =>
        filter.length === 0 ||
        a.title.toLowerCase().indexOf(filter.toLowerCase()) !== -1 ||
        a.body.toLowerCase().indexOf(filter.toLowerCase()) !== -1,
    );
  const openArticleModal = (article?: Article): void => {
    setTitle(article?.title || "");
    setBody(article?.body || "");
    setArticleId(article?.id || "");
    setOpen(true);
  };
  const closeArticleModal = (): void => {
    setTitle("");
    setBody("");
    setArticleId("");
    setOpen(false);
  };
  const showError = (): SnackbarKey => enqueueSnackbar(t("errorWhileUpdating"), { variant: "error" });
  const updateOnServer = async (article: ArticleInput): Promise<void> => {
    setUpdating(true);
    try {
      const result = await updateArticle(article);
      if (!result) showError();
      else {
        enqueueSnackbar(t("updateSuccess"), { variant: "success" });
        closeArticleModal();
      }
    } catch (err) {
      showError();
    }
    setUpdating(false);
  };
  const saveArticle = async (): Promise<void> => updateOnServer({ id: articleId, title, body, type, active: true });
  const deleteArticle = (article: Article) => async (): Promise<void> => {
    setDeletingArticleId(article.id);
    await updateOnServer({ ...articleToArticleInput(article), active: false });
    setDeletingArticleId("");
  };

  return (
    <BaseCard>
      <div className="row space-between margin-bottom">
        <Typography variant="h6">{t(`${type}.title`)}</Typography>
        <div className="row">
          <FormControl style={{ width: 300 }} variant="outlined">
            <InputLabel htmlFor="article-filter">{t("filterArticle")}</InputLabel>
            <OutlinedInput
              id="article-filter"
              value={filter}
              onChange={(e) => setFilter(e.target.value)}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton onClick={() => setFilter("")} edge="end">
                    <Close />
                  </IconButton>
                </InputAdornment>
              }
              label={t("filterArticle")}
            />
          </FormControl>
          <div style={{ width: 32 }} />
          <GdButton label={t("addArticle")} onClick={() => openArticleModal()} />
        </div>
      </div>
      {finalArticles.length > 0 ? (
        finalArticles.map((a) => (
          <ArticleItem
            article={a}
            key={a.id}
            onDelete={deleteArticle(a)}
            onModify={() => openArticleModal(a)}
            isDeleting={deletingArticleId === a.id}
          />
        ))
      ) : (
        <Typography className="text-center margin-top">{t("noArticle")}</Typography>
      )}
      <Modal open={open} onClose={() => closeArticleModal()} className="project-modal-root">
        <Paper className="project-modal-paper">
          <Typography variant="h6" className="margin-bottom" style={{ width: 650 }}>
            {t(articleId.length > 0 ? "modify" : "add")}
          </Typography>
          <TextField
            label={t("title")}
            className="margin-bottom"
            variant="outlined"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            autoComplete="off"
          />
          <div className="margin-bottom">
            <RichTextEditor placeholder={t("body")} body={body} setBody={setBody} />
          </div>
          <GdButton
            label={t("saveArticle")}
            onClick={saveArticle}
            isLoading={updating}
            disabled={title.length === 0 || body.length === 0}
          />
        </Paper>
      </Modal>
    </BaseCard>
  );
};

export default Articles;
