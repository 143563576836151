import { AccountCircle, AddComment, PlaylistAdd, PlaylistAddCheck, ThumbDown, ThumbUp } from "@mui/icons-material";
import { Button, Card, CircularProgress, Tooltip, Typography } from "@mui/material";
import { FC, useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import Markdown from "react-markdown";
import Theme from "../../Theme";
import { AssistantContext } from "../../data/contexts/AssistantContext";
import { ConversationQa, FeedbackConversationQa } from "../../data/generated/graphql";

interface AiHistoryQAProps extends ConversationQa {
  conversationId: string;
  modifyHumanAnswer: () => void;
}

const AiHistoryQA: FC<AiHistoryQAProps> = ({
  date,
  question,
  answer,
  feedback,
  humanAnswer,
  id,
  conversationId,
  userFeedback,
  longAnswer,
  modifyHumanAnswer,
}) => {
  const { t } = useTranslation("aiAssistant");
  const { addFeedback } = useContext(AssistantContext);
  const [isLoading, setIsLoading] = useState(false);

  const UserFeedbackIcon = (): JSX.Element => {
    switch (userFeedback) {
      case FeedbackConversationQa.Good:
        return <ThumbUp color="primary" />;
      case FeedbackConversationQa.Bad:
        return <ThumbDown color="error" />;
      default:
        return <div />;
    }
  };

  const handleChangeFeedback = async (answerFeedback?: FeedbackConversationQa): Promise<void> => {
    setIsLoading(true);
    if (typeof answerFeedback !== "undefined") {
      await addFeedback(conversationId, id, answerFeedback, undefined);
    }
    setIsLoading(false);
  };

  return (
    <Card
      key={date}
      style={{
        margin: "8px",
        whiteSpace: "pre-line",
        padding: "16px",
        marginBottom: "16px",
        display: "flex",
        flexDirection: "column",
      }}>
      <div style={{ display: "flex", justifyContent: "space-between", alignItems: "flex-start" }}>
        <div
          style={{
            display: "flex",
            gap: "8px",
            alignSelf: "flex-start",
            marginBottom: "16px",
            whiteSpace: "pre-line",
          }}>
          <AccountCircle color="disabled" />
          <Typography>{question}</Typography>
        </div>
        {isLoading ? (
          <div style={{ width: "124px", display: "flex", justifyContent: "center" }}>
            <CircularProgress size={25} />
          </div>
        ) : (
          <div style={{ display: "flex", gap: "8px", alignItems: "center" }}>
            <Tooltip title={t("feedback.like")} arrow placement="top" sx={{ zIndex: 42 }}>
              <Button
                sx={{ minWidth: "unset", paddingTop: 0, paddingBottom: 0 }}
                onClick={() => handleChangeFeedback(FeedbackConversationQa.Good)}>
                <ThumbUp
                  fontSize="small"
                  color="primary"
                  sx={{ opacity: feedback === FeedbackConversationQa.Good ? "1" : ".5" }}
                />
              </Button>
            </Tooltip>
            <Tooltip title={t("feedback.unlike")} arrow placement="top" sx={{ zIndex: 42 }}>
              <Button
                sx={{ minWidth: "unset", paddingTop: 0, paddingBottom: 0 }}
                onClick={() => handleChangeFeedback(FeedbackConversationQa.Bad)}>
                <ThumbDown
                  fontSize="small"
                  color="error"
                  sx={{ opacity: feedback === FeedbackConversationQa.Bad ? "1" : ".5" }}
                />
              </Button>
            </Tooltip>
            <Tooltip title={t("feedback.comment")} arrow placement="top" sx={{ zIndex: 42 }}>
              <Button sx={{ minWidth: "unset", paddingTop: 0, paddingBottom: 0 }} onClick={modifyHumanAnswer}>
                <AddComment
                  fontSize="small"
                  color="info"
                  sx={{
                    opacity: (humanAnswer?.length || 0) > 0 ? "1" : ".5",
                  }}
                />
              </Button>
            </Tooltip>
          </div>
        )}
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "8px",
          alignSelf: "flex-end",
          fontStyle: "italic",
          textAlign: "justify",
        }}>
        <Typography color={Theme.palette.primary.main} component="div" sx={{ whiteSpace: "normal" }}>
          <Markdown>{answer}</Markdown>
          {typeof longAnswer === "string" ? (
            <>
              <div className="row margin-top margin-bottom" style={{ fontStyle: "normal", color: "#555" }}>
                <PlaylistAdd />
                <Typography sx={{ fontWeight: "bold" }}>{t("longAnswer")}</Typography>
              </div>
              <Markdown>{longAnswer}</Markdown>
            </>
          ) : undefined}
          {typeof humanAnswer === "string" && humanAnswer.length > 0 ? (
            <>
              <div className="row margin-top margin-bottom" style={{ fontStyle: "normal", color: "#555" }}>
                <PlaylistAddCheck />
                <Typography sx={{ fontWeight: "bold" }}>{t("humanAnswer")}</Typography>
              </div>
              <Markdown>{humanAnswer}</Markdown>
            </>
          ) : undefined}
        </Typography>
        {userFeedback === FeedbackConversationQa.Bad || userFeedback === FeedbackConversationQa.Good ? (
          <div style={{ alignSelf: "flex-end", display: "flex", gap: "8px", alignItems: "center" }}>
            <Typography>{t("assistantHistory.userFeedback")}</Typography> <UserFeedbackIcon />
          </div>
        ) : undefined}
      </div>
    </Card>
  );
};

export default AiHistoryQA;
