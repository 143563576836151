import {
  CircularProgress,
  IconButton,
  Paper,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { FileDownload } from "@mui/icons-material";
import { FC, useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import { UsersContext } from "../../data/contexts/UsersContext";
import { Claims } from "../../data/generated/graphql";
import { Lvl, log } from "../../utils/log";
import { TokenContext } from "../../data/contexts/TokenContext";
import exportData from "../../utils/exportData";
import CenteredContent from "../../utils/CenteredContent";

const AssistantUsers: FC = () => {
  const { t } = useTranslation(["aiAssistant", "user"]);
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState("");
  const [filter, setFilter] = useState("");
  const [isExporting, setIsExporting] = useState(false);
  const { users, changeUserClaim } = useContext(UsersContext);
  const { getToken } = useContext(TokenContext);
  const handleClaimChange = async (userId: string, claim: Claims, granted: boolean): Promise<void> => {
    setIsLoading(userId);
    await changeUserClaim(userId, claim, granted);
    setIsLoading("");
  };
  const filteredUsers = users.filter(
    (u) =>
      (u.name && u.name?.toLowerCase().indexOf(filter.toLowerCase()) !== -1) ||
      (u.email && u.email?.toLowerCase().indexOf(filter.toLowerCase()) !== -1) ||
      (u.role && u.role?.toLowerCase().indexOf(filter.toLowerCase()) !== -1),
  );
  const listMaxHeight = window.innerHeight - (64 + 2 * 24 + 2 * 24 + 56 + 16);

  const exportProjectsData = async (): Promise<void> => {
    setIsExporting(true);
    const token = await getToken();
    if (token !== null)
      await exportData("assistantSubscriptions", token, (error) => {
        log("Error when exporting assistant subscriptions list", Lvl.ERROR, error);
        enqueueSnackbar({ message: t("exportError"), variant: "error" });
      });
    setIsExporting(false);
  };

  return (
    <div style={{ width: "1050px", padding: "8px" }}>
      <div className="row space-between margin-bottom">
        <Typography variant="h6">
          {`${t("user:users")} ${t("activeUsers", {
            count: users.filter((u) => u.claims?.find((c) => c.name === Claims.AiAssistantChat)?.granted === true)
              .length,
          })}`}
        </Typography>
        <div className="row">
          {isExporting ? (
            <CenteredContent className="text-center" width={40} loadingSize={24} loading />
          ) : (
            <Tooltip arrow title={t("exportSubscriptions")}>
              <IconButton onClick={exportProjectsData}>
                <FileDownload />
              </IconButton>
            </Tooltip>
          )}
          <TextField
            className="margin-left"
            value={filter}
            onChange={(e) => setFilter(e.target.value)}
            variant="outlined"
            label={t("user:filterUsers")}
            style={{ width: 300 }}
          />
        </div>
      </div>
      <TableContainer component={Paper} variant="outlined" style={{ height: listMaxHeight, overflowY: "auto" }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell align="left" key="header_name">
                {t("cols.name")}
              </TableCell>
              <TableCell align="left" key="header_email">
                {t("cols.email")}
              </TableCell>
              <TableCell align="left" key="header_role">
                {t("cols.role")}
              </TableCell>
              <TableCell align="center" key="header_aiAssistant">
                {t("cols.aiAssistant")}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredUsers.map((user) => (
              <TableRow key={user.id}>
                <TableCell align="left" key={`name_${user.id}`}>
                  {user.name}
                </TableCell>
                <TableCell align="left" key={`email_${user.id}`}>
                  {user.email}
                </TableCell>
                <TableCell align="left" key={`role_${user.id}`}>
                  {user.role}
                </TableCell>
                <TableCell align="center" key={`aiAssistant_${user.id}`}>
                  {isLoading === user.id ? (
                    <CircularProgress size={24} />
                  ) : (
                    <Switch
                      color="primary"
                      checked={user.claims?.find((c) => c.name === Claims.AiAssistantChat)?.granted === true}
                      onChange={(e) => handleClaimChange(user.id, Claims.AiAssistantChat, e.target.checked)}
                      inputProps={{ "aria-label": "controlled" }}
                    />
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default AssistantUsers;
