import { Typography } from "@mui/material";
import { enqueueSnackbar } from "notistack";
import { FC, useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { ProjectsContext } from "../../data/contexts/ProjectsContext";
import { UserContext } from "../../data/contexts/UserContext";
import { attachmentToAttachmentInput, dateConvertToString } from "../../data/dataConvertors";
import { Attachment, AttachmentType, Project, Validation, ValidationStatus } from "../../data/generated/graphql";
import GdButton from "../../utils/GdButton";
import DocumentValidationModal from "../components/DocumentValidationModal";
import Validator from "./Validator";

interface QuoteValidationProps {
  project: Project;
}

const QuoteValidation: FC<QuoteValidationProps> = ({ project }) => {
  const { validateQuote } = useContext(ProjectsContext);
  const { t } = useTranslation("project");
  const [openValidationModal, setOpenValidationModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { userInfo } = useContext(UserContext);

  const openQuote = (): void => {
    const url = project.quote?.url;
    if (url) window.open(`${url}?t=${new Date().getTime()}`);
  };

  const validateTheQuote = async (date: string, amount: string): Promise<void> => {
    setIsLoading(true);
    const result = await validateQuote({
      id: project.id,
      quote: {
        ...attachmentToAttachmentInput(project.quote as Attachment),
        validation: {
          status: ValidationStatus.Accepted,
          userId: userInfo?.id || "",
          date: new Date().toISOString(),
        },
        date,
        amount,
      },
    });
    if (!result) {
      enqueueSnackbar(t("global:errorWhileUpdating"), { variant: "error" });
    } else enqueueSnackbar(t("global:updateSuccess"), { variant: "success" });
    setIsLoading(false);
    setOpenValidationModal(false);
  };

  const tryValidateQuote = async (): Promise<boolean> => {
    setOpenValidationModal(true);
    return Promise.resolve(true);
  };

  const refuseQuote = (validation: Validation): Promise<boolean> =>
    validateQuote({
      id: project.id,
      quote: {
        ...attachmentToAttachmentInput(project.quote as Attachment),
        validation,
      },
    });

  return (
    <>
      <Validator
        validation={project.quote?.validation}
        validateOnServer={tryValidateQuote}
        refuseOnServer={refuseQuote}
        leftText={
          <div>
            <Typography variant="h6" className="margin-bottom">
              {t("quoteTwo")}
            </Typography>
            <Typography>
              {!project.quote?.validation
                ? t("checkNota")
                : t(project.quote.validation.status === ValidationStatus.Accepted ? "quoteValidated" : "quoteRefused", {
                    replace: {
                      date: dateConvertToString(project.quote.validation.date),
                      reason: project.quote.validation.comment,
                    },
                  })}
            </Typography>
          </div>
        }
        topButton={<GdButton label={t("seeQuote")} color="secondary" onClick={openQuote} />}
        projectId={project.id}
        type={AttachmentType.Quote}
      />
      <DocumentValidationModal
        open={openValidationModal}
        onClose={() => setOpenValidationModal(false)}
        documentValidation={validateTheQuote}
        url={project.quote?.url}
        type="quote"
        isLoading={isLoading}
      />
    </>
  );
};

export default QuoteValidation;
