import { HighlightOff } from "@mui/icons-material";
import { Tooltip, Typography } from "@mui/material";
import { FC, useContext } from "react";
import { useTranslation } from "react-i18next";
import { UsersContext } from "../../data/contexts/UsersContext";
import { dateConvertToString } from "../../data/dataConvertors";
import { Conversation } from "../../data/generated/graphql";

const AiHistoryItem: FC<Conversation> = ({ qas, latestModification, userId, active }) => {
  const { t } = useTranslation("aiAssistant");
  const { users } = useContext(UsersContext);

  const latestQuestion = qas?.[qas?.length - 1 || 0]?.question || "";

  return (
    <div className="flex1 row space-between">
      <Typography sx={{ display: "flex", flexDirection: "column" }}>
        <Tooltip title={latestQuestion} arrow placement="top" sx={{ zIndex: 42 }}>
          <p style={{ fontWeight: "bold", margin: "4px 0 8px 0" }}>
            {latestQuestion.length <= 50 ? latestQuestion : `${latestQuestion.substring(0, 30)}...`}
          </p>
        </Tooltip>
        <i style={{ fontSize: "14px" }}>{`${t("assistantHistory.accordionDetails")}${dateConvertToString(
          latestModification || "",
        )}`}</i>
        <div className="row space-between" style={{ width: "280px" }}>
          <i style={{ fontSize: "14px" }}>{`${t("assistantHistory.user")}${
            users.find((u) => u.id === userId)?.name
          }`}</i>
          {!active ? (
            <Tooltip arrow title={t("assistantHistory.isDeleted")}>
              <HighlightOff color="error" sx={{ opacity: "0.5" }} />
            </Tooltip>
          ) : undefined}
        </div>
      </Typography>
    </div>
  );
};

export default AiHistoryItem;
