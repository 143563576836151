import { Card, styled } from "@mui/material";
import { FC, PropsWithChildren } from "react";

const Container = styled("div")({
  flex: 1,
  display: "flex",
  flexDirection: "column",
});

const OurCard = styled(Card)({
  width: 1100,
  padding: 32,
  boxSizing: "border-box",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
});

interface BaseCardProps extends PropsWithChildren {
  className?: string;
}

const BaseCard: FC<BaseCardProps> = ({ children, className }) => (
  <Container className={className}>
    <OurCard elevation={3}>{children}</OurCard>
  </Container>
);

BaseCard.defaultProps = { className: undefined };

export default BaseCard;
